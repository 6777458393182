import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const InactivityLogoutTimer = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const checkForInactivity = () => {
        const expireTimeString = localStorage.getItem('expireTime');
        const expireTime = expireTimeString ? parseInt(expireTimeString) : 0;

        // console.log('expires time ' + localStorage.getItem('expireTime'));

        // console.log('current time ' +  Date.now());
        

        if (expireTime < Date.now() && location.pathname !== '/') {
            localStorage.removeItem('authUser');
            navigate('/');
        }
    };

    const updateExpiryTime = () => {
        const expireTime = Date.now() + 15 * 60 * 1000; // 15 minutes in milliseconds
        localStorage.setItem('expireTime', expireTime.toString());
    };

    useEffect(() => {
        const interval = setInterval(() => {
            checkForInactivity();
            console.log('Check for inactivity every 5 seconds');
        }, 5000); // Check for inactivity every 5 seconds

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        updateExpiryTime();
        window.addEventListener('click', updateExpiryTime);
        window.addEventListener('keypress', updateExpiryTime);
        window.addEventListener('scroll', updateExpiryTime);
        window.addEventListener('mousemove', updateExpiryTime);
console.log('clicked');
        return () => {
            window.removeEventListener('click', updateExpiryTime);
            window.removeEventListener('keypress', updateExpiryTime);
            window.removeEventListener('scroll', updateExpiryTime);
            window.removeEventListener('mousemove', updateExpiryTime);
        };
    }, []);

    return null; 
};

export default InactivityLogoutTimer;