import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map, values } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';
import { ACTION_URL, APP_URL, GET_ALERTS_PAGE } from "helpers/url_helper"
import { toast } from "react-toastify";
import Select from "react-select";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";


import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  ModalFooter
} from "reactstrap";

import { Name, Status } from "components/Common/TableColumns"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"

function CareNotesReview () {
  //meta title
  document.title="Care Noets Review | Tacveiha Panel";
  
  
    const loggedUser = JSON.parse(localStorage.getItem("authUser"));

    const verified_options = [
      {'label' : 'Select Status', value:'0'},
      {'label' : 'Verified', value:'1'},
      {'label' : 'Escalate due Admin Work', value:'5'},
      {'label' : 'Escalate due to Care Management', value:'7'},
      {'label' : 'Service Manager', value:'9'}
    ]

    const dispatch = useDispatch();
    const [roster, setRoster] = useState();
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [ModalLoading, setModalLoading] = useState(false);
    const [axiosLoading, setAxiosLoading] = useState(false);
    const [adminAlertRosters, setAdminAlertRosters] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [payCat, setPayCat] = useState('');
    const [supportType, setSupportType] = useState('');
    const [verifiedStatus, setVerifiedStatus] = useState([]);
    const [RosterNotesList, setRosterNotesList] = useState([]);

    const [payCategoryDropdown, setPayCategoryDropdown] = useState([]);
    const [supportTypeDropdown, setSupportTypeDropdown] = useState([]);

    //roster notes section
    const [RosterNotes, setRosterNotes] = useState('');

    const [sendReportChecked, setSendReportChecked ] = useState(false);
    const [adminVerifiedStatus, setAdminVerifiedStatus] = useState(verified_options);



    const validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,

    initialValues: {
      ros_id: ( roster && roster.ros_id) || "",
      start_date: ( roster && roster.start_date) || "",
      start_time: ( roster && roster.start_time) || "",
      finish_time: ( roster && roster.finish_time) || "",
      actual_start_time: ( roster && roster.actual_start_time) || "",
      actual_finish_time: ( roster && roster.actual_finish_time) || "",
      odometer_start: ( roster && roster.odometer_start) || 0,
      odometer_end: ( roster && roster.odometer_end) || 0,
      roster_kms: ( roster && roster.roster_kms) || "",
      roster_kms_for: ( roster && roster.roster_kms_for) || "",
      roster_parking_fee: ( roster && roster.roster_parking_fee) || "",
      roster_extra_details: ( roster && roster.roster_extra_details) || "",
      client_emergency_contact_email: ( roster && roster.client_emergency_contact_email ) || "",
      // roster_staff_notes_verified: ( roster && roster.roster_staff_notes_verified) || "",
    },
    validationSchema: Yup.object({
        start_time: Yup.string().required("Please enter start time"),
        finish_time: Yup.string().required("Please enter finish time"),
        actual_start_time: Yup.string().required("Please enter actual start time"),
        actual_finish_time: Yup.string().required("Please enter actual finish time"),
    }),
    onSubmit: values => {
      if (isEdit) {

        console.log(verifiedStatus)

        if( verifiedStatus == '' || verifiedStatus == 0 ) {
          toast.error("Please select roster verified status", { autoClose: 5000 });
          return false;
        }

        const updateUser = {
            ros_id: values.ros_id,
            roster_start_time: values.start_time,
            roster_finish_time: values.finish_time,
            actual_start_time: values.actual_start_time,
            actual_finish_time: values.actual_finish_time,
            roster_kms_for: values.roster_kms_for,
            odometer_start: values.odometer_start,
            odometer_end: values.odometer_end,
            roster_staff_notes_verified: values.roster_extra_details,
            ros_support_type: supportType,
            pay_category: payCat,
            roster_parking_fee: values.roster_parking_fee,
            roster_sub_admin_verified_status: verifiedStatus ? verifiedStatus : 0,
            send_roster_report_admin: sendReportChecked ? 1 : 0
        };

        // update data
        // dispatch(onUpdateAlertRosterDetails({'data_for':'update_alert_roster_details','data': updateUser,'user': loggedUser}));

        setAxiosLoading(true);
        axios.post(APP_URL+ACTION_URL,{'data_for':'admin_staff_edit_rosters','formData':updateUser, 'user': loggedUser})
          .then(res => {
            setAxiosLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
              toast.success(fetchedData.html, { autoClose: 5000 });
              setRosterNotesList(RosterNotesList => [fetchedData.rosterActivity,...RosterNotesList])
              setIsEdit(false);
              location.reload();
            }
            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
              return false;
            }

          }
        )
     
       
      } else {

      }

    },
  });


    const columns = useMemo(
      () => [
        {
          Header: "#",
          accessor: "ros_id",
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Client Name",
          accessor: "client_name",
          //disableFilters: true, 
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Staff Name",
          accessor: "staff_name",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Roster Date",
          accessor: "start_date",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Start Time",
          accessor: "start_time",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Finish Time",
          accessor: "finish_time",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Status",
          accessor: "ros_status",
          filterable: true,
          Cell: cellProps => {
            return <Status {...cellProps} />;
          },
        },
        
        {
          Header: "Action",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    handleUserClick(userData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </div>
            );
          },
        },
      ],
      []
    );


  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    // console.log(user.ros_id);
    toggle();
    const formData = {
      ros_id: user.ros_id
    }

    setAxiosLoading(true);
    axios.post(APP_URL+'/alert_rosters',{'data_for':'get_care_notes_roster_details_','formData': formData,'user': loggedUser})
      .then(res => {
        setAxiosLoading(false);
        const fetchedData = res.data;

        setPayCategoryDropdown(fetchedData.pay_category_dropdown)
        setSupportTypeDropdown(fetchedData.support_type_dropdown)
        // setVerifiedStatus({label:'Select Verified Status',value: 0});
      
        setPayCat({label:fetchedData.rosterDetails.pay_category_label,value:fetchedData.rosterDetails.pay_category_value})
        setSupportType({label:fetchedData.rosterDetails.ros_support_type_label,value:fetchedData.rosterDetails.ros_support_type_value});
        setRosterNotesList(fetchedData.rosterActivity);
        setRoster({
              ros_id: fetchedData.rosterDetails.ros_id,
              start_date: fetchedData.rosterDetails.start_date,
              start_time: fetchedData.rosterDetails.start_time,
              finish_time: fetchedData.rosterDetails.finish_time,
              actual_start_time: fetchedData.rosterDetails.actual_start_time,
              actual_finish_time: fetchedData.rosterDetails.actual_finish_time,
              odometer_start: fetchedData.rosterDetails.odometer_start,
              odometer_end: fetchedData.rosterDetails.odometer_end,
              roster_kms: fetchedData.rosterDetails.roster_kms,
              roster_kms_for: fetchedData.rosterDetails.roster_kms_for,
              roster_parking_fee: fetchedData.rosterDetails.roster_parking_fee,
              roster_extra_details: fetchedData.rosterDetails.roster_extra_details,
              client_emergency_contact_email: fetchedData.rosterDetails.client_emergency_contact_email,
          });
          setIsEdit(true);
    })

  }

  const handleSearchBtn = () => {

    setModalLoading(true);

    const formData = {
        fromdate: fromDate,
        todate: toDate
    }
    axios.post(APP_URL+GET_ALERTS_PAGE,{'data_for': 'load_admin_alert_rosters','formData': formData,'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
            if( fetchedData.status == 'success' ) {
              toast.success(fetchedData.html, { autoClose: 5000 });
              setAdminAlertRosters(fetchedData.adminAlertRosters)
            }
            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })

  };


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleUserClicks = () => {
    setRostersList("");
    setIsEdit(false);
    toggle();
  };

  const handleFromDate = value => {
    setFromDate(value);
  }

  const handleToDate = value => {
    setToDate(value);
  }

  const handleVerifiedStatus = (e) => {
    setVerifiedStatus(e.value);
    // console.log(e)
  }

  const handleSendSms = (arg) => {
    setSendSms(arg);
    if( arg === true ) {
      setRosterNotesStaffsDisabled(false);
    } else {
      setRosterNotesStaffsDisabled(true);
    }
  }

  const addRosterNotes = () => {

    // console.log(RosterNotesStaff);

    if( RosterNotes == '' ) {
      toast.error("Please add notes", { autoClose: 5000 });
      return false;
    }

    
    const RosterNotesForm = {
        ros_id: roster.ros_id,
        rosterNotes: RosterNotes,
    };

    setAxiosLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'add_roster_notes','formData': RosterNotesForm,'user': loggedUser})
      .then(res => {
        setAxiosLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
          setRosterNotes('');
          setRosterNotesList(RosterNotesList => [fetchedData.data,...RosterNotesList]);
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
    })
  }
  
  

  const handleEditRoster = () => {
    validation.handleSubmit();
  }

  return (
    <React.Fragment>
      { ModalLoading ? <TopProgressBar loading/> : '' }
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Rosters" breadcrumbItem="All Rosters" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="flex align-items-center">
                    <Row className="mb-2">
                        <Col sm={2}>
                        <Input
                        type="date"
                        value={fromDate}
                        onChange={e => {
                            handleFromDate(e.target.value);
                        }}
                        />
                        
                        </Col>

                        <Col sm={2}>
                        <Input
                        type="date"
                        value={toDate}
                        onChange={e => {
                            handleToDate(e.target.value);
                        }}
                        />
                        
                        </Col>

                        <Col sm="1">
                            <div className="text-sm-end">
                                <Button
                                type="button"
                                color="primary"
                                className=" mb-2 me-2"
                                onClick={handleSearchBtn}
                                >
                                <i className="mdi mdi-search me-1" />
                                Search
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          { ModalLoading ? <Spinners setLoading={setLoading} />
            :
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row className="mb-2">

                                <TableContainer
                                    columns={columns}
                                    data={adminAlertRosters}
                                    isGlobalFilter={true}
                                    isDateSelecteOptions={true}
                                    showSearchBtn={true}
                                    handleUserClick={handleUserClicks}
                                    customPageSize={25}
                                    className="custom-header-css"
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
              </Row>
          }


      <Modal isOpen={modal} toggle={toggle} size="xl" backdrop="static" scrollable>
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "Edit Details" : "Add Details"} { axiosLoading ? <Spinners setLoading={setLoading} /> : '' }
        </ModalHeader>
        <ModalBody>

          <Row>
            <Col xs={8}>

              <Card>
                <CardBody>
                  <Form
                      // onSubmit={e => {
                      //   e.preventDefault();
                      //   validation.handleSubmit();
                      //   return false;
                      // }}
                    >

                      <Row>
                        <Col xs={4}>
                          <div className="mb-3">
                            <Label className="form-label">Date</Label>
                            <Input
                              name="start_date"
                              type="date"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.start_date}
                              options={{
                                // altInput: true,
                                // altFormat: "F j, Y",
                                dateFormat: "Y-m-d"
                              }}
                              // readOnly={true}
                            />
                          
                          </div>
                          
                        </Col>

                        <Col xs={4}>
                          <div className="mb-3">
                            <Label className="form-label">Start Time</Label>
                            <Input
                              name="start_time"
                              type="time"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.start_time}
                              readOnly={true}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i"
                              }}
                            />
                          </div>
                          
                        </Col>

                        <Col xs={4}>
                          <div className="mb-3">
                            <Label className="form-label">Finish Time</Label>
                            <Input
                              name="finish_time"
                              type="time"
                              value={validation.values.finish_time}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i"
                              }}
                            />
                            
                          </div>
                          
                        </Col>

                      </Row>

                      <Row>
                    <Col xs={4}>
                      {/* <div className="mb-3">
                        <Label className="form-label">Date</Label>
                        <Input
                          name="service_name"
                          type="date"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.service_name || ""}
                          invalid={
                            validation.touched.service_name &&
                              validation.errors.service_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.service_name &&
                          validation.errors.service_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.service_name}
                          </FormFeedback>
                        ) : null}
                      </div> */}
                      
                    </Col>

                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Actual Start Time</Label>
                        <Input
                          name="actual_start_time"
                          type="time"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.actual_start_time || ""}
                          invalid={
                            validation.touched.actual_start_time &&
                              validation.errors.actual_start_time
                              ? true
                              : false
                          }
                        />
                        {validation.touched.actual_start_time &&
                          validation.errors.actual_start_time ? (
                          <FormFeedback type="invalid">
                            {validation.errors.actual_start_time}
                          </FormFeedback>
                        ) : null}
                      </div>
                      
                    </Col>

                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Actual Finish Time</Label>
                        <Input
                          name="actual_finish_time"
                          type="time"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.actual_finish_time || ""}
                          invalid={
                            validation.touched.actual_finish_time &&
                              validation.errors.actual_finish_time
                              ? true
                              : false
                          }
                        />
                        {validation.touched.actual_finish_time &&
                          validation.errors.actual_finish_time ? (
                          <FormFeedback type="invalid">
                            {validation.errors.actual_finish_time}
                          </FormFeedback>
                        ) : null}
                      </div>
                      
                    </Col>

                  </Row>

                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Odometer Start Reading</Label>
                        <Input
                          name="odometer_start"
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.odometer_start}
                        />
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Odometer End Reading</Label>
                        <Input
                          name="odometer_end"
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.odometer_end}
                        />
                      </div>
                      
                    </Col>

                  </Row>

                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Kms</Label>
                        <Input
                          name="roster_kms"
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={(validation.values.odometer_end - validation.values.odometer_start).toFixed(2)}
                          readOnly
                        />

                      </div>
                      
                    </Col>

                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Kms Description</Label>
                        <Input
                          name="roster_kms_for"
                          type="textarea"
                          value={validation.values.roster_kms_for || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                      </div>
                      
                    </Col>

                  </Row>

                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Support Type</Label>
                        <Select
                          value={supportType}
                          name="ros_support_type"
                          options={supportTypeDropdown}
                          className="select2-selection"
                          defaultValue={supportType}
                          onChange={(e) => setSupportType(e)}
                          onBlur={validation.handleBlur}
                        />
                      
                      </div>
                      
                    </Col>

                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Parking Fee</Label>
                        <Input
                          name="roster_parking_fee"
                          type="number"
                          value={validation.values.roster_parking_fee || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                      </div>
                      
                    </Col>

                  </Row>


                  <Row>

                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Staff Notes</Label>
                        <Input
                          name="roster_extra_details"
                          type="textarea"
                          rows="5"
                          value={validation.values.roster_extra_details || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          
                        />
                      </div>
                      
                      
                    </Col>

                  </Row>

                  

                  {/* <Row>

                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Different Location Text</Label>
                        <Input
                          name="different_location_text"
                          type="textarea"
                          value={validation.values.different_location_text || ""}
                          readOnly={true}
                          placeholder="Different Location Text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                      </div>
                      
                    </Col>

                  </Row> */}


                  <Row>

                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Provider Email</Label>
                        <Input
                          name="client_emergency_contact_email"
                          type="text"
                          value={validation.values.client_emergency_contact_email}
                          readOnly={true}
                          
                        />
                      </div>
                      
                    </Col>

                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Pay Category External ID</Label>

                        <Select
                          value={payCat}
                          name="pay_category"
                          options={payCategoryDropdown}
                          className="select2-selection"
                          defaultValue={payCat}
                          onChange={(e) => setPayCat(e)}
                          onBlur={validation.handleBlur}
                        />
                        
                      </div>
                      
                    </Col>

                  </Row>

                  <Row>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Choose Status</Label>

                        <Select
                          value={adminVerifiedStatus.verifiedStatus}
                          name="roster_sub_admin_verified_status"
                          options={adminVerifiedStatus}
                          className="select2-selection"
                          onChange={(e) => {
                            handleVerifiedStatus(e);
                          }}
                          onBlur={validation.handleBlur}
                        />
                        
                      </div>
                      
                      { verifiedStatus == '1' && (
                      <div className="mb-3">
                      <Input
                          name="send_roster_report"
                          type="checkbox"
                          value="1"                          
                          onChange={() =>
                            setSendReportChecked(!sendReportChecked)
                          }
                        /> Send Roster Report
                      </div>
                      )}
                    </Col>

                  
                  </Row>

                  </Form>
                </CardBody>
              </Card>
              
            </Col>

            <Col xs={4}>

              <Row>

                <Col xs={12}>

                  <div className="mb-3">
                    <Label className="form-label">Add Notes</Label>
                    <Input
                      name="roster_notes"
                      type="textarea"
                      onChange={e => {
                        
                        setRosterNotes(e.target.value);
                      }}
                      value={RosterNotes}
                    />
                    
                  </div>
                
                </Col>
              </Row>
            
              <Row>
                <Col>
                  <div className="text-end">
                    <button onClick={addRosterNotes}
                      type="submit"
                      className="btn btn-success save-user"
                      disabled={axiosLoading}
                    >
                      Add
                    </button>
                  </div>
                </Col>
              </Row>

              <Row>
                <div className="order_notes_div">
                  {
                  axiosLoading ? <Spinners setLoading={setLoading} />
                  :
                  <ul className="order_notes">
                    {RosterNotesList.map(activity => (
                      <li key={activity.id} rel={activity.id} className="note">
                        <div dangerouslySetInnerHTML={{__html: activity.activity_for }} className="note_content"></div>
                        <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                        <strong>
                          {activity.activity_by}
                        </strong>
                        </abbr></p>
                      </li>
                    ))}
                  </ul>
                  }
                </div>
              </Row>

            </Col>

          </Row>
                
        </ModalBody>
        <ModalFooter>

          <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="btn btn-success save-user"
                      disabled={axiosLoading}
                      onClick={handleEditRoster}
                    >
                      Update
                    </button>
                  </div>
                </Col>
            </Row>
          
        </ModalFooter>
      </Modal>

        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default CareNotesReview