import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import axios from 'axios';
import withRouter from "components/Common/withRouter"
import { toast } from "react-toastify";
import { isEmpty, map, values } from "lodash"
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik} from "formik";
import { ACTION_URL, APP_URL, ADMIN_URL } from "helpers/url_helper"
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";


import Dropzone from "react-dropzone";


import {Name  } from "../columns";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";


import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle,
  } from "reactstrap";


//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"
import ClientAssementCarePlanModal from "components/Forms/ClientAssesmentCarePlan";
import PackageLevelModal from "components/Forms/PackageLevelModal";
import ClientPackageArchivedModal from "components/Forms/ClientPackageArchivedModal";
import ClientCarePlan from "components/Forms/ClientCarePlan";
import ClientDischargePlan from "components/Forms/ClientDischargePlan";


const libraries =['places'];


const ClientsProfile = props =>  {
  //meta title
    document.title="Clients Profile | Tacveiha Panel";
    const inputRef = useRef();
  
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries
    });

    const dispatch = useDispatch();
  
//   const selectGroupRostersState = (state) => state.NewsFeedReducer;
//   const groupRostersProperties = createSelector(
//     selectGroupRostersState,
//       (rosterReducer) => ({
//         newsFeedsViewed: rosterReducer.newsFeedsViewed,
//         loading: rosterReducer.loading
//       })
//   );
//   const {
//     newsFeedsViewed, loading
//   } = useSelector(groupRostersProperties);

//   const [isLoading, setLoading] = useState(loading);

    const params = props.router.params;
    const loggedUser = JSON.parse(localStorage.getItem("authUser"));

    // const [toggle, setToggle] = useState(false);

    const [ModalLoading, setModalLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    //modals
    const [ViewPackageModal, setViewPackageModal] = useState(false);
    const [ArchivedPackageModal, setArchivedPackageModal] = useState(false);
    const [AddPackageModal, setAddPackageModal] = useState(false);
    const [DocumentsModal, setDocumentsModal] = useState(false);
    

    const [rosterNotesLoading, setRosterNotesLoading] = useState(false);
    const [clientsDocsLoading, setClientsDocsLoading] = useState(false);

    const [AllRosterModal, setAllRosterModal] = useState(false);
    const [AssestmentEmergencyPlanModal, setAssestmentEmergencyPlanModal] = useState(false);
    const [CarePlan, setCarePlan] = useState(false);
    const [DischargeForm, setDischargeForm] = useState(false);
    const [AllDocModal, setAllDocModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [Items, setItems ] = useState([]);
    const [roster, setRoster] = useState([]);
    const [TopLoading, setTopLoading] = useState(false);
    
    
    const [ServiceTypes, setServiceTypes] = useState([]);
    const [VaccinationFlag, setVaccinationFlag] = useState(false);
    const [AnnualFluFlag, setAnnualFluFlag] = useState(false);
    const [ClientDob, setClientDob] = useState('');
    const [CareInstructions, setCareInstructions] = useState('');
    const [ClientAddress, setClientAddress] = useState('');
    
    const [ClientLatLng, setClientLatLng] = useState('');
    const [ClientTimezone, setClientTimezone] = useState([]);
    const [ClientType, setClientType] = useState('');
    const [ClientDepartment, setClientDepartment] = useState([]);
    const [ClientCasemanager, setClientCasemanager] = useState([]);
    const [StaffsExecluded, setStaffsExecluded] = useState([]);

    const [staffs_dropdown, setStaffs_dropdown] = useState([]);
    const [client_timezone_dropdown, setClient_timezone_dropdown] = useState([]);
    const [department_dropdown, setDepartment_dropdown] = useState([]);
    const [casemanager_dropdown, setCasemanager_dropdown] = useState([]);
    
    
    const [FirstDoseDate, setFirstDoseDate] = useState('');
    const [SecondDoseDate, setSecondDoseDate] = useState('');
    const [AnnualFluDate, setAnnualFluDate] = useState('');

    // const [package_types_dropdown, setPackage_types_dropdown] = useState('');

    const [ServiceTypesArray, setServiceTypesArray] = useState([]);
    const [ClientsServiceTypes, setClientsServiceTypes] = useState([]);
    const [PackageTypeDropdown, setPackageTypeDropdown] = useState([]);
    const [PackageType, setPackageType] = useState('');
    const [ClientSupportType, setClientSupportType ] = useState([]);
    const [ClientPackageStartDate, setClientPackageStartDate] = useState('');
    const [ClientPackageEndDate, setClientPackageEndDate] = useState('');
    const [PackageLevelDropdown, setPackageLevelDropdown] = useState([]);
    // const [PackageLevel, setPackageLevel] = useState('');

    const [DailySubsidy, setDailySubsidy] = useState('');
    const [WeeklySubsidy, setWeeklySubsidy] = useState('');
    const [MonthlySubsidy, setMonthlySubsidy] = useState('');
    const [YearlySubsidy, setYearlySubsidy] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(false);


    //client notes
    const [ClientNotes, setClientNotes] = useState('');
    const [SendSms, setSendSms] = useState(false);
    const [SmsSubject, setSmsSubject ] = useState('');
    const [ClientNotesList, setClientNotesList ] = useState([]);
    const [SmsMobileNo, setSmsMobileNo ] = useState('');


    //client package types
    const [ClientPackageID, setClientPackageID] = useState('');
    const [ClientPackageType, setClientPackageType] = useState([]);
    const [ClientPackageLevel, setClientPackageLevel] = useState([]);
    const [PackageAmount, setPackageAmount] = useState('');
    const [PackageHourlyRate, setPackageHourlyRate] = useState('');
    const [ArchivePlan, setArchivePlan] = useState(0);
    const [AddPlanBtn, setAddPlanBtn] = useState(true);

    // clients documents
    const [DocumentsActivity, setDocumentsActivity] = useState([]);
    const [ClientsActiveDocuments, setClientsActiveDocuments] = useState([]);
    const [ClientsDeletedDocuments, setClientsDeletedDocuments] = useState([]);
    const [ClientsDocuments, setClientsDocuments] = useState([]);
    const [ColumnsHide, setColumnsHide ] = useState([]);
    const [showDocuments, setShowDocuments] = useState('active');

    //dropzone
    const [selectedFiles, setselectedFiles] = useState([]);
    const [selectedDocFiles, setselectedDocFiles] = useState([]);

    //isolation
    const[IsolationDropdown, setIsolationDropdown] = useState([]);
    // const[clientIsolationEndDate, setClientIsolationEndDate] = ('');
    const[ClientIsolationFor, setClientIsolationFor]= useState([]);
    const[IsolationEndDate, setIsolationEndDate] = useState('');
    const[IsolationModal, setIsolationModal] = useState(false);
    const[InIsolation, setInIsolation]= useState(0);


    const [clientAssesmentPlan, setClientAssesmentPlan] = useState(false);
    const [clientPlanModal, setClientPlanModal] = useState(false);
    const [clientDischargeForm, setClientDischargeForm] = useState(false);

    const[ EmergencyContactEmail, setEmergencyContactEmail] = useState('')
    const[ EmergencyContactMobile, setEmergencyContactMobile] = useState('')
    const[ EmergencyContactName, setEmergencyContactName] = useState('')
    const[ EmergencyContactRelation, setEmergencyContactRelation] = useState('')


    const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
        user_status : roster.user_status || "",
        // service_type: ( roster && roster.service_type ) || "",
        vaccination_flag: roster.vaccination_flag || "",
        annual_flu_flag : roster.annual_flu_flag || "",
        user_title : roster.user_title || "",
        client_fname: ( roster && roster.client_fname) || "",
        client_lname: ( roster && roster.client_lname) || "",
        client_email: ( roster && roster.client_email) || "",
        // client_dob: ( roster && roster.client_dob) || "",
        client_gender: ( roster && roster.client_gender) || "",
        client_mobile_no: ( roster && roster.client_mobile_no) || "",
        client_home_no : ( roster && roster.client_home_no) || "",
        client_referral_source : ( roster && roster.client_referral_source) || "",
        client_address : ( roster && roster.client_address) || "",
        care_instruction : ( roster && roster.care_instruction) || "",
        client_latlng : ( roster && roster.client_latlng) || "",
        client_timezone: ( roster && roster.client_timezone) || "",
        client_type: ( roster && roster.client_type) || "",
        department_id: ( roster && roster.department_id) || "",
        case_manager_id: ( roster && roster.case_manager_id) || "",
        client_emergency_contact_name: ( roster && roster.client_emergency_contact_name) || "",
        client_emergency_contact_relation: ( roster && roster.client_emergency_contact_relation) || "",
        client_emergency_contact_mobile: ( roster && roster.client_emergency_contact_mobile) || "",
        client_emergency_contact_email: ( roster && roster.client_emergency_contact_email) || "",
        client_postcode: ( roster && roster.client_postcode) || "",
        first_dose: ( roster && roster.first_dose) || "",
        first_dose_date: ( roster && roster.first_dose_date) || "",
        second_dose: ( roster && roster.second_dose) || "",
        second_dose_date: ( roster && roster.second_dose_date) || "",
        annual_flu_dose: ( roster && roster.annual_flu_dose) || "",
        annual_flu_dose_date: ( roster && roster.annual_flu_dose_date) || "",
        access_status:  ( roster && roster.access_status) || "",
        access_email:  ( roster && roster.access_email) || "",
        access_contact_no:  ( roster && roster.access_contact_no) || "",
        // ctid: ( roster && roster.ctid) || "",
        // cplid: ( roster && roster.cplid) || "",
        // support_type: ( roster && roster.support_type) || "",
        // amount: ( roster && roster.amount) || "",

        // hourly_rate: ( roster && roster.hourly_rate) || "",
        // daily_subsidy: ( roster && roster.daily_subsidy) || "",
        // weekly_subsidy: ( roster && roster.weekly_subsidy) || "",
        // monthly_subsidy: ( roster && roster.monthly_subsidy) || "",
        // yearly_subsidy: ( roster && roster.yearly_subsidy) || "",

        // start_date: ( roster && roster.start_date) || "",
        // end_date: ( roster && roster.end_date) || "",
        // yearly_subsidy: ( roster && roster.yearly_subsidy) || "",
    },
    validationSchema: Yup.object({
        user_title: Yup.string().required("Required"),
        client_fname: Yup.string().required("Required"),
        client_lname: Yup.string().required("Required"),
        client_email: Yup.string().required("Required"),
        client_gender: Yup.string().required("Required"),
        client_mobile_no: Yup.string().required("Required"),
        client_home_no: Yup.string().required("Required"),
        client_referral_source: Yup.string().required("Required"),
        client_address: Yup.string().required("Required"),
        client_postcode: Yup.string().required("Required"),
        // client_emergency_contact_name : Yup.string().required("Required"),
        // client_emergency_contact_relation: Yup.string().required("Required"),
        // client_emergency_contact_mobile : Yup.string().required("Required"),
        // client_emergency_contact_email : Yup.string().required("Required"),
        // access_status : Yup.boolean().default(0),
        // access_email: Yup.string().when('report_weekly', {
        //     is: true,
        //     then: schema => schema.required("Please provide access email"),
        //     otherwise: schema => schema.optional(),
        //  }),
    }),
    onSubmit: values => {

    //   console.log( values );


        if( ClientDob == "" ) {
          toast.error("Please provide client date of birth", { autoClose: 5000 });
          return false;
        }

        if( CareInstructions == "" ) {
          toast.error("Please provide care instructions", { autoClose: 5000 });
          return false;
        }

        if( ClientTimezone == '' ) {
            toast.error("Please select client timezone", { autoClose: 5000 });
          return false;
        }

        if( ClientType == '' ) {
            toast.error("Please select for option", { autoClose: 5000 });
          return false;
        }

        if( ClientDepartment == '' ) {
            toast.error("Please select client department", { autoClose: 5000 });
          return false;
        }


        if( ClientCasemanager == '' ) {
            toast.error("Please select client casemanager", { autoClose: 5000 });
          return false;
        }

        if( validation.values.first_dose == 1 &&  FirstDoseDate == '' ) {
            toast.error("Please provide first dose date", { autoClose: 5000 });
            return false;
        }

        if( validation.values.second_dose == 1 &&  SecondDoseDate == '' ) {
            toast.error("Please provide second dose date", { autoClose: 5000 });
            return false;
        }

        if( validation.values.annual_flu_dose == 1 &&  AnnualFluDate == '' ) {
            toast.error("Please provide annual flu dose date", { autoClose: 5000 });
            return false;
        }

        if( validation.values.access_status == 1) {
            if( validation.values.access_email == '' ) {
                toast.error("Please provide access email", { autoClose: 5000 });
              return false;
            }

            if( validation.values.access_contact_no == '' ) {
                toast.error("Please provide access contact no", { autoClose: 5000 });
              return false;
            }
        }
        

        const formData = {
            client_id: roster.client_id,
            user_status : values.user_status,
            service_type: ClientsServiceTypes,
            vaccination_flag: values.vaccination_flag,
            annual_flu_flag : values.annual_flu_flag,
            user_title : values.user_title,
            client_fname: values.client_fname,
            client_lname: values.client_lname,
            client_email: values.client_email,
            client_dob: ClientDob,
            client_gender: values.client_gender,
            client_mobile_no: values.client_mobile_no,
            client_home_no: values.client_home_no,

            client_referral_source: values.client_referral_source,
            client_address: values.client_address,
            care_instruction: CareInstructions,
            client_latlng: ClientLatLng,
            
            client_timezone: ClientTimezone,
            client_type: ClientType,//UserType,
            department_id: ClientDepartment,
            case_manager_id: ClientCasemanager,
            client_emergency_contact_name: values.client_emergency_contact_name,
            client_emergency_contact_relation: values.client_emergency_contact_relation,
            client_emergency_contact_mobile: values.client_emergency_contact_mobile,
            client_emergency_contact_email: values.client_emergency_contact_email,
            client_postcode: values.client_postcode,
            staffs_excluded : StaffsExecluded,
            first_dose: values.first_dose,
            first_dose_date: FirstDoseDate,
            second_dose: values.second_dose,
            second_dose_date: SecondDoseDate,
            annual_flu_dose: values.annual_flu_dose,
            annual_flu_dose_date: AnnualFluDate,
            access_status: values.access_status,
            access_email: values.access_email,
            access_contact_no: values.access_contact_no
        };

        // console.log( formData);
        // return false;

        // add data
      setTopLoading(true);
      axios.post(APP_URL+ACTION_URL,{'data_for':'update_client','formData': formData,'user': loggedUser})
      .then(res => {
        setTopLoading(false);
        
        const fetchedData = res.data;
        if( fetchedData.status == 'success' ) {
          toast.success(fetchedData.html, { autoClose: 5000 });
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.error, { autoClose: 5000 });
        }

      });

    },
  });


  useEffect(() => {
    // if (params && params.id) {

        setTopLoading(true);
        axios.post(APP_URL+ACTION_URL,{'data_for':'add_clients_data_load', 'user_id': params.id})
        .then(res => {
            setTopLoading(false);
            const fetchedData = res.data;
            setServiceTypesArray(fetchedData.ServiceTypesDropdown);
            if( fetchedData.ClientServiceTypes ) {
                setClientsServiceTypes(fetchedData.ClientServiceTypes);
                // setClientsServiceTypes(JSON.parse(fetchedData.ClientServiceTypes));
            }
            setStaffs_dropdown(fetchedData.StaffsDropdown);
            setPackageTypeDropdown(fetchedData.ClientTypes);
            setClient_timezone_dropdown(fetchedData.TimeZones);
            setPackageLevelDropdown(fetchedData.PackageLevels);
            updateRoster(fetchedData.userRow);
            setClientTimezone(fetchedData.ClientTimeZones);

            setClientDepartment({value:fetchedData.userRow.department_id, label: fetchedData.ClientDepartmentName});
            setClientCasemanager({value:fetchedData.userRow.case_manager_id, label: fetchedData.ClientCaseManagerName});
            setStaffsExecluded(fetchedData.staffs_excluded);
            setClientNotesList(fetchedData.client_notes);
            setClientDob(fetchedData.userRow.client_dob);
            setCareInstructions(fetchedData.userRow.care_instruction);
            setClientType(fetchedData.userRow.user_type);
            setClientLatLng(fetchedData.userRow.client_latlng);  
            setIsolationDropdown(fetchedData.isolationOptionDropdown);
            setClientIsolationFor({value:fetchedData.isolatingforName.id, label: fetchedData.isolatingforName.isolation_name});
            setIsolationEndDate(fetchedData.userRow.isolation_end_date)
            setInIsolation(fetchedData.userRow.is_isolation);
            if( fetchedData.cpld.cplid ) {
                setAddPlanBtn(false);
            }

            setEmergencyContactEmail(fetchedData.userRow.client_emergency_contact_email);
            setEmergencyContactMobile(fetchedData.userRow.client_emergency_contact_mobile);
            setEmergencyContactName(fetchedData.userRow.client_emergency_contact_name);
            setEmergencyContactRelation(fetchedData.userRow.client_emergency_contact_relation);

            setDepartment_dropdown(fetchedData.userDepartmentDropdown);
            setCasemanager_dropdown(fetchedData.userCaseManagerDropdown);
            

        })
    // }
  }, []);

  const updateRoster = (userRow) => {
    setRoster({
        user_status: userRow.user_status,
        client_id: userRow.user_id,
        vaccination_flag: userRow.vaccination_flag,
        annual_flu_flag : userRow.annual_flu_flag,
        user_title :  userRow.user_title,
        client_fname: userRow.client_fname,
        client_lname: userRow.client_lname,
        client_email: userRow.user_email,
        client_dob: userRow.client_dob,
        client_gender: userRow.client_gender,
        client_mobile_no: userRow.client_mobile_no,
        client_home_no : userRow.client_home_no,
        client_referral_source : userRow.client_referral_source,
        client_address : userRow.client_address,
        care_instruction : userRow.care_instruction,
        client_latlng : userRow.client_latlng,
        client_timezone: userRow.client_timezone,
        client_type: userRow.user_type,
        department_id: userRow.department_id,
        case_manager_id: userRow.case_manager_id,
        // client_emergency_contact_name: userRow.client_emergency_contact_name,
        // client_emergency_contact_relation: userRow.client_emergency_contact_relation,
        // client_emergency_contact_mobile: userRow.client_emergency_contact_mobile,
        // client_emergency_contact_email: userRow.client_emergency_contact_email,
        client_postcode: userRow.client_postcode,
        first_dose: userRow.first_dose_data.status,
        first_dose_date: userRow.first_dose_data.vaccination_date,
        second_dose: userRow.second_dose_data.status,
        second_dose_date: userRow.second_dose_data.second_dose_date,
        annual_flu_dose: userRow.annual_dose_data.status,
        annual_flu_dose_date: userRow.annual_dose_data.annual_flu_dose_date,
        access_status: userRow.access_status,
        access_email: userRow.access_email,
        access_contact_no: userRow.access_contact_no,
        is_isolation: userRow.is_isolation,
        isolating_for: userRow.isolating_for,
        isolation_end_date: userRow.isolation_end_date
    });
    

  }

  useEffect(() => {
    // console.log(roster);
  },[roster]);


const handlePlaceChanged = () => { 
    const [ place ] = inputRef.current.getPlaces();
    if(place) { 
        // console.log(place);
        validation.values.client_address = place.formatted_address;
        console.log(place.address_components);
        setClientAddress(place.formatted_address);
        // const latlng = 
        setClientLatLng(place.geometry.location.lat()+','+place.geometry.location.lng());
        // console.log(place.formatted_address)
        // console.log(place.geometry.location.lat())
        // console.log(place.geometry.location.lng())

        var postcode = place.address_components;
        var searchPostalCode = '';
        postcode.map(code => {
                if(code.types[0]=="postal_code"){
                    searchPostalCode=code.short_name;
                }
        });
        validation.values.client_postcode = searchPostalCode;
        
    }
}


  const handleClientType = (type) => {

    const formData = {
        type: type
    }
    setClientType(type);
    setTopLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'load_department','formData':formData})
    .then(res => {
      setTopLoading(false);
      const fetchedData = res;
        setDepartment_dropdown(res.data.departments);
    })
  }

  const handleDepartmentChange = (e) => {
    setClientDepartment(e);
    setClientCasemanager([]);
    const formData = {
        dep_id: e.value
    }
    setTopLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'loadCaseManager','formData':formData})
    .then(res => {
      setTopLoading(false);
      const fetchedData = res;
        setCasemanager_dropdown(res.data.casemanagers);
    })
  }

  const handleCaseMangerChange = (e) => {
    
    if( e.value  == 0 ) {
        setClientCasemanager([]);
        return false;
    }

    setClientCasemanager(e);

    const formData = {
        cm_id: e.value
    }
    setTopLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'loadCaseManagerDetials','formData':formData})
    .then(res => {
      setTopLoading(false);
      const fetchedData = res.data;
        setEmergencyContactEmail(fetchedData.client_emergency_contact_email);
        setEmergencyContactMobile(fetchedData.client_emergency_contact_mobile);
        setEmergencyContactName(fetchedData.client_emergency_contact_name);
        setEmergencyContactRelation(fetchedData.client_emergency_contact_relation);

        // validation.values.client_emergency_contact_email = fetchedData.client_emergency_contact_email;
        // validation.values.client_emergency_contact_mobile = fetchedData.client_emergency_contact_mobile;
        // validation.values.client_emergency_contact_name = fetchedData.client_emergency_contact_name;
        // validation.values.client_emergency_contact_relation = fetchedData.client_emergency_contact_relation;
    })
  }

//   const handlePackageTypes = (e) => {

//     validation.values.ctid = e;
//     setPackageType(e.value);

//     const formData = {
//         client_type: e.value
//     }
//     // if( e.value == 1 ) {

//     //     setClientSupportType([]);
//     //     setClientPackageStartDate('');
//     //     setClientPackageEndDate('');
//     //     setDailySubsidy(fetchedData.daily_subsidy);
//     //     setWeeklySubsidy(fetchedData.weekly_subsidy);
//     //     setMonthlySubsidy(fetchedData.monthly_subsidy);
//     //     setYearlySubsidy(fetchedData.yearly_subsidy);
//     // }
//     setTopLoading(true);
//     axios.post(APP_URL+ACTION_URL,{'data_for':'load_client_package_types','formData':formData})
//     .then(res => {
//       setTopLoading(false);
//       const fetchedData = res;
//         // setDepartment_dropdown(res.data);
//     })

//   }

  const handlePackageLevel = (e, type) => {

    if( type == 'firstLoad' ) {
        // setPackageLevel(e.value);
        setClientPackageLevel(e.value);
    } else {

        const formData = {
            client_type: PackageType,
            client_package_level: e.value
        }
        setTopLoading(true);
        axios.post(APP_URL+ACTION_URL,{'data_for':'load_client_package_leves_rate','formData':formData})
        .then(res => {
        setTopLoading(false);
        const fetchedData = res.data;
        // console.log(fetchedData);
        setDailySubsidy(fetchedData.daily_subsidy);
        setWeeklySubsidy(fetchedData.weekly_subsidy);
        setMonthlySubsidy(fetchedData.monthly_subsidy);
        setYearlySubsidy(fetchedData.yearly_subsidy);

        })

    }

  }

  const handleCheckboxChange = (event) => {

    // const checkedId = parseInt(event.target.value);

    // if( event.target.checked ) {
    //     setClientsServiceTypes([...ClientsServiceTypes, checkedId]);
    // } else{
    //     setClientsServiceTypes( (ClientsServiceTypes) => {
    //         return ClientsServiceTypes.filter((id) =>{
    //             id !== checkedId
    //         })
    //     });
    // }

    const checkedId = parseInt(event.target.value);
    // console.log( checkedId);
    if( event.target.checked ) {
        setClientsServiceTypes([...ClientsServiceTypes, checkedId]);
    } else{
        setClientsServiceTypes( ClientsServiceTypes.filter(id =>id != checkedId));
    }
    
    // setItems(ServiceTypesArray.map(item => 
    //   item.value === id ? { ...item, checked: !item.checked } : item
    // ));
  }

  useEffect(() => {
    // console.log( ClientsServiceTypes);
  },[ClientsServiceTypes]);

  const handleSendSms = (event) => {
    const sendSms = event.target.value;
    // console.log( sendSms );
    if( event.target.checked ) {
        setSendSms(true);
    } else{
        setSendSms(false);
    }
  };

  
  const addClientNotes = () => {

    if( SendSms ) {
        
        if( SmsSubject == '' ) {
            toast.error("Please provide sms subject", { autoClose: 5000 });
            return false;
        }

        if( SmsMobileNo == '' ) {
            toast.error("Please provide client mobile no", { autoClose: 5000 });
            return false;
        }

        var pattern = /^(?!(0))\d{9}$/;
        if( SmsMobileNo.match(pattern)) {

        } else {
            toast.error("Please provide valid mobile no", { autoClose: 5000 });
            return;
        }

    }

    if( ClientNotes == '' ) {
        toast.error("Please add notes", { autoClose: 5000 });
        return false;
      }
  
    const RosterNotesForm = {
        ros_id: params.id,
        notes: ClientNotes,
        activity_for: 'client',
        sendSms: SendSms,
        sms_subject: SmsSubject,
        sms_mobile_no: SmsMobileNo,
        smsType: 'client',
    };

    setRosterNotesLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'add_notes_client','formData': RosterNotesForm,'user': loggedUser})
      .then(res => {
        setRosterNotesLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
            setClientNotes('');
            setSmsSubject('');
            setSendSms(false);
            setSmsMobileNo('');
            setClientNotesList(ClientNotesList => [fetchedData.data,...ClientNotesList]);
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
    })
  }

  const toggleIsolationModal = () => {

    if (IsolationModal) {
      setIsolationModal(false);
    } else {
        setIsolationModal(true);
    }
  }

  const togglePackageModal = () => {

    if (ViewPackageModal) {
        setArchivePlan(0)
         setViewPackageModal(false);
    } else {
        setViewPackageModal(true);
    }
  };

  const toggleArchivedPackageModal = () => {

    if (ArchivedPackageModal) {
        
      setArchivedPackageModal(false);
    } else {
        setArchivedPackageModal(true);
    }
  }

  const toggleDocumentsModal = () => {
 
    if (DocumentsModal) {
      setDocumentsModal(false);
    //   setRoster(null);
    } else {
        setDocumentsModal(true);
    }
  }

  const toggleClientPlanModal = () => {
 
    if (clientPlanModal) {
        setClientPlanModal(false);
    } else {
        setClientPlanModal(true);
    }
  };

  


  const viewClientPackages = () => {
    
    togglePackageModal();
    setModalLoading(true);
    const formData = {
        user_id: params.id,
    };
    axios.post(APP_URL+ACTION_URL,{'data_for':'get_client_package_details','formData': formData,'user': loggedUser})
      .then(res => {
        setModalLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {

            setClientPackageID(fetchedData.cpld.id)
            setPackageType(fetchedData.cpld.ctid);
            setClientPackageType({label:fetchedData.ClientTypes.name,value:fetchedData.ClientTypes.id});

            if( fetchedData.cpld.ctid == 1 ) {
                setClientSupportType(fetchedData.support_types);
                setPackageAmount(fetchedData.cpld.amount);
                setClientPackageStartDate(fetchedData.cpld.start_date);
                setClientPackageEndDate(fetchedData.cpld.end_date);

            }

            if( fetchedData.cpld.ctid == 2 ) {

                setClientPackageLevel({label:fetchedData.PackageLevels.name,value:fetchedData.PackageLevels.id});
                setClientPackageStartDate(fetchedData.cpld.start_date);
                
                setDailySubsidy(fetchedData.cpld.daily_subsidy);
                setWeeklySubsidy(fetchedData.cpld.weekly_subsidy);
                setMonthlySubsidy(fetchedData.cpld.monthly_subsidy);
                setYearlySubsidy(fetchedData.cpld.yearly_subsidy);

            }

            if( fetchedData.cpld.ctid == 3 ) {
                setClientSupportType(fetchedData.support_types);
                setPackageHourlyRate(fetchedData.cpld.hourly_rate);
                setPackageAmount(fetchedData.cpld.amount);
                setClientPackageStartDate(fetchedData.cpld.start_date);
                setClientPackageEndDate(fetchedData.cpld.end_date);

            }
            
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
    })
    
  }

  const updateClientPackages = () => {

    if( PackageType == 1 ) {

        if( ClientSupportType == '' ) {
            toast.error("Please add support types", { autoClose: 5000 });
            return false;
        }

        if( PackageAmount == '' ) {
            toast.error("Please select package amount", { autoClose: 5000 });
            return false;
        }

        if( ClientPackageStartDate == '' ) {
            toast.error("Please select start date", { autoClose: 5000 });
            return false;
        }

        if( ClientPackageEndDate == '' ) {
            toast.error("Please select end date", { autoClose: 5000 });
            return false;
        }

    }

    if( PackageType == 2 ) {

        if( ClientPackageLevel == '' ) {
            toast.error("Please select package level", { autoClose: 5000 });
            return false;
        }

        if( ClientPackageStartDate == '' ) {
            toast.error("Please select start date", { autoClose: 5000 });
            return false;
        }

        if( WeeklySubsidy == '' && MonthlySubsidy == '' && YearlySubsidy == '') {
            toast.error("Please provide subsidy amount", { autoClose: 5000 });
            return false;
        }

    }

    if( PackageType == 3 ) {

        if( ClientSupportType == '' ) {
            toast.error("Please add support types", { autoClose: 5000 });
            return false;
        }

        if( PackageHourlyRate == '' ) {
            toast.error("Please provide hourly rate", { autoClose: 5000 });
            return false;
        }

        if( PackageAmount == '' ) {
            toast.error("Please add total amount", { autoClose: 5000 });
            return false;
        }

        if( ClientPackageStartDate == '' ) {
            toast.error("Please select start date", { autoClose: 5000 });
            return false;
        }

        if( ClientPackageEndDate == '' ) {
            toast.error("Please select end date", { autoClose: 5000 });
            return false;
        }

    }

    if( ArchivePlan == 1 ) {

        if( ClientPackageEndDate == '' ) {
            toast.error("Please select end date", { autoClose: 5000 });
            return false;
        }
        
    }


    const formData = {
        cpid: ClientPackageID,
        ctid: PackageType,
        cplid: ClientPackageLevel,
        user_id: params.id,
        support_type: ClientSupportType,
        amount: PackageAmount,
        hourly_rate: PackageHourlyRate,
        daily_subsidy: DailySubsidy,
        weekly_subsidy: WeeklySubsidy,
        monthly_subsidy: MonthlySubsidy,
        yearly_subsidy: YearlySubsidy,
        start_date : ClientPackageStartDate,
        end_date : ClientPackageEndDate,
        is_archived: ArchivePlan
    }

    // console.log( formData);

    setModalLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'update_client_package_details','formData': formData,'user': loggedUser})
      .then(res => {
        setModalLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
            toast.success(fetchedData.html, { autoClose: 5000 });
            togglePackageModal();
            if( ArchivePlan ) {
                setAddPlanBtn(true);
                setArchivePlan(0);
            }
            setPackageFormFields();
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
    })

  }

  const setPackageFormFields = () => {
    setClientPackageID('');
    setPackageType('');
    setClientPackageLevel('');
    setClientPackageType('');
    setPackageAmount('');
    setPackageHourlyRate('');

}


  const admin_columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "iid",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Document Name",
        accessor: "doc_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Type",
        accessor: "doc_type",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Added",
        accessor: "doc_added",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-info"
                        onClick={() => {
                            const userData = cellProps.row.original;
                            if( userData.doc_status != 5 ) {
                                handleArchiveDoc(userData);
                            }
                        }}
                        id={`viewprofiletooltip-${cellProps.row.original.id}`}
                    >
                        <i className="dripicons-archive" />
                        <UncontrolledTooltip placement="top" target={`viewprofiletooltip-${cellProps.row.original.id}`} >
                            Archive
                        </UncontrolledTooltip>
                    </Link>
                </li>

                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-info"
                        onClick={() => {
                            const userData = cellProps.row.original;
                            handleDownloadDoc(userData);
                        }}
                        id={`downloadtooltip-${cellProps.row.original.id}`}
                    >
                        <i className="dripicons-download" />
                        <UncontrolledTooltip placement="top" target={`downloadtooltip-${cellProps.row.original.id}`} >
                            Download
                        </UncontrolledTooltip>
                    </Link>
                </li>

                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-info"
                        onClick={() => {
                            const userData = cellProps.row.original;
                            handleRemoveDoc(userData);
                        }}
                        id={`removetooltip-${cellProps.row.original.id}`}
                    >
                        <i className="dripicons-trash" />
                        <UncontrolledTooltip placement="top" target={`removetooltip-${cellProps.row.original.id}`} >
                            Remove
                        </UncontrolledTooltip>
                    </Link>
                </li>

            </ul>
          );
        },
      },
    ],
    []
  );

  const hr_columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "iid",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Document Name",
        accessor: "doc_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Type",
        accessor: "doc_type",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Expiry Date",
        accessor: "expiry_date",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Added",
        accessor: "doc_added",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-info"
                        onClick={() => {
                            const userData = cellProps.row.original;
                            if( userData.doc_status != 5 ) {
                                handleArchiveDoc(userData);
                            }
                        }}
                        id={`viewprofiletooltip-${cellProps.row.original.id}`}
                    >
                        <i className="dripicons-archive" />
                        <UncontrolledTooltip placement="top" target={`viewprofiletooltip-${cellProps.row.original.id}`} >
                            Archive
                        </UncontrolledTooltip>
                    </Link>
                </li>

                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-info"
                        onClick={() => {
                            const userData = cellProps.row.original;
                            handleDownloadDoc(userData);
                        }}
                        id={`downloadtooltip-${cellProps.row.original.id}`}
                    >
                        <i className="dripicons-download" />
                        <UncontrolledTooltip placement="top" target={`downloadtooltip-${cellProps.row.original.id}`} >
                            Download
                        </UncontrolledTooltip>
                    </Link>
                </li>

                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-info"
                        onClick={() => {
                            const userData = cellProps.row.original;
                            handleRemoveDoc(userData);
                        }}
                        id={`removetooltip-${cellProps.row.original.id}`}
                    >
                        <i className="dripicons-trash"/>
                        <UncontrolledTooltip placement="top" target={`removetooltip-${cellProps.row.original.id}`} >
                            Remove
                        </UncontrolledTooltip>
                    </Link>
                </li>

            </ul>
          );
        },
      },
    ],
    []
  );

  const hr_columns_deleted = useMemo(
    () => [
      {
        Header: "#",
        accessor: "iid",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Document Name",
        accessor: "doc_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Type",
        accessor: "doc_type",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Expiry Date",
        accessor: "expiry_date",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Added",
        accessor: "doc_added",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
    ],
    []
  );

  const columns = loggedUser.role === 'admin' ? admin_columns : hr_columns;

  const viewClientDocuments = () => {
    

        // if( loggedUser.role == 'admin' ) {
        //     setColumnsHide(['Expiry Date']);
        // }

        // if( loggedUser.role == 'human_resource' ) {
        //     setColumnsHide([]);
        // }

        toggleDocumentsModal();
        setModalLoading(true);
        const formData = {
            user_id: params.id,
            type: 'client_doc'
        };
        axios.post(APP_URL+ACTION_URL,{'data_for':'get_clients_documents','formData': formData,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
    
            if( fetchedData.status == 'success' ) {
                setClientsActiveDocuments(fetchedData.documents_active);
                setClientsDeletedDocuments(fetchedData.documents_deleted);
                setClientsDocuments(fetchedData.documents_active);
                setDocumentsActivity(fetchedData.docs_activity);
            }
    
            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }
    
          })

  }

  const handleShowDocuments = (type) => {
    // console.log(type);
    if( type == 'active' ) {
        setClientsDocuments(ClientsActiveDocuments);
        // if( loggedUser.role == 'admin' ) {
        //     setColumnsHide(['Expiry Date']);
        // }

        // if ( loggedUser.role == 'human_resource' ){
        //     setColumnsHide([]);
        // }
    }
    if( type == 'deleted') {
        // columns = hr_columns_deleted;
        setClientsDocuments(ClientsDeletedDocuments);
        // if( loggedUser.role == 'admin' ) {
        //     setColumnsHide(['Expiry Date','Action']);
        // }

        // if ( loggedUser.role == 'human_resource' ){
        //     setColumnsHide(['Action'])
        // }
        
    }
  }

  const handleArchiveDoc = (docs) => {
        setModalLoading(true);
        const formData = {
            doc_id: docs.id,
            type: 'client_doc'
        };
        axios.post(APP_URL+ACTION_URL,{'data_for':'archive_doc','formData': formData,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
    
            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                setClientsDocuments((ClientsDocuments) => ClientsDocuments.filter(data => data.id.toString() !==  docs.id.toString()));
            }
    
            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }
    
          })
  }

  const handleRemoveDoc = (docs) => {
   

    setModalLoading(true);
        const formData = {
            doc_id: docs.id,
            type: 'client_doc'
        };
        axios.post(APP_URL+ACTION_URL,{'data_for':'remove_doc','formData': formData,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
    
            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                setClientsDocuments((ClientsDocuments) => ClientsDocuments.filter(data => data.id.toString() !==  docs.id.toString()));
                
            }
    
            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }
    
          })
  }


  const handleDownloadDoc = (docs) => {
    window.open(ADMIN_URL+'download_file.php?name='+docs.doc_name+'&user_id='+params.id, '_blank', 'noopener,noreferrer');
  }

//   const toggleClientAssesmentCarePlanModal = () => {
//     if (clientAssesmentCarePlan) {
//         setclientAssesmentCarePlan(false);
//       } else {
//         setclientAssesmentCarePlan(true);
//       }
//   }

  const handleUploadDoc = (files) => {

    files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
    )
    
    setselectedDocFiles(files);

    const formData = new FormData();

    files.forEach((file) => {
        formData.append('file', file);
    });

    // files.forEach((file) => {
    //     console.log(file);
    //     formData.append('files[]', file);
    // });

    formData.append('data_for', 'client_doc');
    formData.append('user_id', params.id);
    formData.append('user_type', 'client_doc');
    formData.append('is_expiry', '');
    formData.append('expiry_date', '');
    formData.append('doc_list_id', '');
    formData.append('doc_list_name', '');
    formData.append('added_by', loggedUser.uid);
    formData.append('role', loggedUser.role);

    setTopLoading(true);

    axios.post(APP_URL+'/upload_files.php',formData,{
        headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
        setTopLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
            toast.success(fetchedData.html, { autoClose: 5000 });
            setselectedDocFiles([]);
        }

        if( fetchedData.status == 'error' ) {
            toast.error(fetchedData.html, { autoClose: 5000 });
        }

    })
}


/**
 * Formats the size
 */
function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}


//isolate client

const changeIsolation = () => {

    if( confirm('Are you sure you want to remove client from Isolation?') ) {

        setModalLoading(true);
        const formData = {
            client_id: params.id,
            status: 0
        }
        axios.post(APP_URL+ACTION_URL,{'data_for':'remove_isolation_client','formData': formData,'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
    
            if( fetchedData.status == 'success' ) {
                setInIsolation(0)
                setClientIsolationFor([])
                setIsolationEndDate('')
                toast.success(fetchedData.html, { autoClose: 5000 });
            }
    
            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }
    
        })
    }

}

const isolateClient = () => {
    if( confirm('Are you sure you want to mark client as Isolated?') ) {
        toggleIsolationModal();
    
    }

}

const handleIsolate = () => {

    //if( confirm('Are you sure you want to mark client as Isolated?') ) {
    setModalLoading(true);

    const formData = {
        client_id: params.id,
        isolation_for: ClientIsolationFor,
        isolation_end_date: IsolationEndDate

    };
    axios.post(APP_URL+ACTION_URL,{'data_for':'isolate_client','formData': formData,'user': loggedUser})
      .then(res => {
        setModalLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
            setInIsolation(1);
            toast.success(fetchedData.html, { autoClose: 5000 });
            toggleIsolationModal();
        }

        if( fetchedData.status == 'error' ) {
            toast.error(fetchedData.html, { autoClose: 5000 });
        }

      })

    //}
}

const handleArchivePackage = () => {
    setArchivePlan(1);
}

const toggleAddPacakeModal  = () => {
    if (AddPackageModal) {
        setAddPackageModal(false);
    } else {
        setAddPackageModal(true);
    }
}

const toggleClientAssesmentCarePlan  = () => {
    if (clientAssesmentPlan) {
        setClientAssesmentPlan(false);
    } else {
        setClientAssesmentPlan(true);
    }
}

const toggleClientDischargeForm = () => {
    if ( clientDischargeForm ) {
        setClientDischargeForm(false)
    } else {
        setClientDischargeForm(true);
    }
}

const AddPackageModalClose = ()=> {
    setAddPackageModal(false);
    setAddPlanBtn(false);
}


const laodArchivedPackages = () => {


}
  
  return (
    <React.Fragment>
    { TopLoading ? <TopProgressBar loading/> : '' }

    { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }

    <Modal isOpen={IsolationModal} toggle={toggleIsolationModal} size="xl" backdrop="static" scrollable>
        <ModalHeader toggle={toggleIsolationModal} tag="h4">
            Isolation Details
        </ModalHeader>
        <ModalBody>
            <Row>
                <Col md={12}>
                    <label htmlFor="">Isolation For</label>
                    <Select
                        value={ClientIsolationFor}
                        name="isolation_forr"
                        options={IsolationDropdown}
                        onChange={(e) => setClientIsolationFor(e)}
                        className="select2-selection"
                        defaultValue={IsolationDropdown}
                        styles={{
                            menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                    />
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <label htmlFor="">Isolation End Date</label>
                    <InputGroup>
                    <Flatpickr
                        name="isolation_end_date"
                        type="text"
                        className="form-control d-block"
                        placeholder="dd-mm-YYYY"
                        onChange={(selectedDates, dateStr, instance) => {
                            setIsolationEndDate(dateStr);
                        }}
                        onBlur={validation.handleBlur}
                        value={IsolationEndDate}
                        options={{
                        altInput: true,
                        altFormat: "d-m-Y",
                        dateFormat: "Y-m-d",
                        "locale": {
                                "firstDayOfWeek": 1 // start week on Monday
                            }
                        }}
                    />
                    </InputGroup>
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                <div className="text-end">
                    <Button
                    type="button"
                    className="btn btn-success save-user"
                    disabled={ModalLoading}
                    onClick={handleIsolate}
                    >
                    Add
                    </Button>
                </div>
                </Col>
            </Row>
            
        </ModalBody>
    </Modal>

    {/* <Modal isOpen={CarePlanModal} toggle={toggleCarePlanModal} size="xl" backdrop="static" scrollable>
        <ModalHeader toggle={toggleCarePlanModal} tag="h4">
            {!!isEdit ? "Client Care Assestment Plan" : "Client Care Assestment Plan Details"}
        </ModalHeader>
        <ModalBody>
            <ClientAssementCarePlanModal clienId={params.id} />
        </ModalBody>
    </Modal> */}

    <ClientDischargePlan
        clientID={params.id}
        show={clientDischargeForm}
        onCloseModal={() => setClientDischargeForm(false)}
    />

    <ClientCarePlan
        clientID={params.id}
        clientFname={roster.client_fname}
        clientLname={roster.client_lname}
        show={clientPlanModal}
        onCloseModal={() => setClientPlanModal(false)}
    />


    <ClientAssementCarePlanModal
        clientID={params.id}
        clientFname={roster.client_fname}
        clientLname={roster.client_lname}
        show={clientAssesmentPlan}
        onCloseModal={() => setClientAssesmentPlan(false)}
    />

    {/* Add Package Level Modal*/}

    <PackageLevelModal 
        clientID={params.id}
        show={AddPackageModal}
        onCloseModal={() => setAddPackageModal(false) }
        showPackageBtn={() => setAddPlanBtn(false)}
    />

    {/* Add Package Level Modal*/}

    <ClientPackageArchivedModal 
        clientID={params.id}
        show={ArchivedPackageModal}
        onCloseModal={() =>  setArchivedPackageModal(false)}
    />
    

      <div className="page-content">
        <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="#" breadcrumbItem="Clients Profile" />
            <Row className="mb-6">
                <Col sm="12">
                    <div className="text-sm-end">

                        { !AddPlanBtn  && 
                        <Button
                        type="button"
                        color="primary"
                        className="mb-2 me-2"
                        onClick={viewClientPackages}
                        disabled={TopLoading}
                        >
                        
                        View Client Package
                        </Button>
                        }

                        <Button
                        type="button"
                        color="primary"
                        className="mb-2 me-2"
                        onClick={toggleArchivedPackageModal}
                        disabled={TopLoading}
                        >
                        
                        Archived Package
                        </Button>

                        <Button
                        type="button"
                        color="primary"
                        className="mb-2 me-2"
                        onClick={viewClientDocuments}
                        disabled={TopLoading}
                        >
                        All Documents
                        </Button>
                    </div>
                </Col>
            </Row>
            {/* Package modals */}
            <Modal isOpen={ViewPackageModal} toggle={togglePackageModal} size="xl" backdrop="static" scrollable>
                <ModalHeader toggle={togglePackageModal} tag="h4">
                    {!!isEdit ? "Client Package Details" : "Add Details"}
                </ModalHeader>
                <ModalBody>
                    {
                    ModalLoading ? <Spinners setLoading={setLoading} />
                    :
                    <Row>
                        <Col xs={12}>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Package Type
                            </Label>
                            <Col sm={9}>
                            
                                <Select
                                   value={PackageTypeDropdown.value}
                                   name="ctid"
                                   options={PackageTypeDropdown}
                                   onChange={(e) => handlePackageTypes(e)}
                                   className="select2-selection"
                                   defaultValue={ClientPackageType}
                                   isDisabled={true}
                                />
                            </Col>
                        
                        </Row>
                    { PackageTypeDropdown.value != '' &&
                    <>
                        { !ArchivePlan && 
                        <Button
                        type="button"
                        onClick={handleArchivePackage}
                        className="btn btn-info"
                        
                        >
                            Archive
                        </Button>
                        }
                        </>
                    }
                    { PackageType == '1' && (

                        <>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Support Type
                            </Label>
                            <Col sm={9}>

                            <CreatableSelect 
                            isMulti
                            name="support_type"
                            value={ClientSupportType}
                            onChange={(e) => {
                                setClientSupportType('');
                                setClientSupportType(e);
                            }}
                             />

                                {/* <Input
                                    name="support_type"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.support_type} /> */}
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Amount
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="amount"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={PackageAmount} />
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Start Date
                            </Label>
                            <Col sm={9}>
                            <InputGroup>
                                <Flatpickr
                                    name="start_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setClientPackageStartDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={ClientPackageStartDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    // maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                            </Col>
                        </Row>


                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                End Date
                            </Label>
                            <Col sm={9}>
                            <InputGroup>
                                <Flatpickr
                                    name="end_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setClientPackageEndDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={ClientPackageEndDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    minDate: ClientPackageStartDate,
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                            </Col>
                        </Row>
                        
                        </>
                        )}
                        
                        { PackageType == 2 && (

                            <>
                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Package Level
                                </Label>
                                <Col sm={9}>

                                    <Select
                                    value={ClientPackageLevel}
                                    name="cplid"
                                    options={PackageLevelDropdown}
                                    onChange={(e) => handlePackageLevel(e, 'firstLoad')}
                                    className="select2-selection"
                                    defaultValue={ClientPackageLevel}
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Start Date
                                </Label>
                                <Col sm={9}>
                                    <InputGroup>
                                        <Flatpickr
                                            name="start_date"
                                            type="text"
                                            className="form-control d-block"
                                            placeholder="dd-mm-YYYY"
                                            onChange={(selectedDates, dateStr, instance) => {
                                                setClientPackageStartDate(dateStr);
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={ClientPackageStartDate}
                                            options={{
                                            altInput: true,
                                            altFormat: "d-m-Y",
                                            dateFormat: "Y-m-d",
                                            // maxDate: 'today',
                                            //   defaultDate: 'today',
                                            "locale": {
                                                    "firstDayOfWeek": 1 // start week on Monday
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Daily Subsidy
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="daily_subsidy"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={DailySubsidy}
                                        readOnly={true} />
                                        
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Weekly Subsidy
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="weekly_subsidy"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={WeeklySubsidy} />
                                        
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Monthly Subsidy
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="monthly_subsidy"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={MonthlySubsidy} />
                                        
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Yearly Subsidy
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="yearly_subsidy"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={YearlySubsidy} />
                                        
                                </Col>
                            </Row>

                            </>
                        )}
                    

                        { PackageType == '3' && (

                        <>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Support Type
                            </Label>
                            <Col sm={9}>

                            <CreatableSelect 
                            isMulti
                            name="support_type"
                            value={ClientSupportType}
                            placeholder="Add Support Types"
                            onChange={(e) => {
                                setClientSupportType('');
                                setClientSupportType(e);
                            }}
                            />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Hourly Rate
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="hourly_rate"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={PackageHourlyRate} />
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Total Amount
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="amount"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={PackageAmount} />
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Start Date
                            </Label>
                            <Col sm={9}>
                            <InputGroup>
                                <Flatpickr
                                    name="start_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setClientPackageStartDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={ClientPackageStartDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    // maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                            </Col>
                        </Row>


                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                End Date
                            </Label>
                            <Col sm={9}>
                            <InputGroup>
                                <Flatpickr
                                    name="end_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setClientPackageEndDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={ClientPackageEndDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    minDate: ClientPackageStartDate,
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                            </Col>
                        </Row>

                        </>
                        )}

                        
                        </Col>

                        { ArchivePlan == 1 && PackageType != 1 && PackageType != 3 &&
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                End Date
                            </Label>
                            <Col sm={9}>
                            <InputGroup>
                                <Flatpickr
                                    name="end_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setClientPackageEndDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={ClientPackageEndDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    minDate: ClientPackageStartDate,
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                            </Col>
                        </Row>
                        
                        }

                        <Col>
                    
                            <div className="text-end">
                                <Button onClick={updateClientPackages}
                                type="submit"
                                className={`btn ${ArchivePlan ? 'btn-danger' : 'btn-success'}  save-user`}
                                >
                                {!! ArchivePlan ? 'Archive' : 'Update' }
                                </Button>

                            </div>

                            
                        </Col>
                    </Row>

                }

                </ModalBody>
            </Modal>

            {/* Documents modals */}

            <Modal isOpen={DocumentsModal} toggle={toggleDocumentsModal} size="xl" backdrop="static" scrollable>
                <ModalHeader toggle={toggleDocumentsModal} tag="h4">
                    {!!isEdit ? "Client Documents Details" : "Client Documents Details"}
                </ModalHeader>
                <ModalBody>
                    {
                    ModalLoading ? <Spinners setLoading={setLoading} />
                    :
                    <Row>
                        <Col xs={8}>
                            { loggedUser.role == 'human_resource' }
                            <div className="d-inline-flex">
                                <Button
                                type="button"
                                color="primary"
                                className="mb-2 me-2"
                                onClick={() => handleShowDocuments('active')}
                                >
                                
                                Active
                                </Button>
                            </div>
                            <div className="d-inline-flex">
                                <Button
                                type="button"
                                color="primary"
                                className="mb-2 me-2"
                                onClick={() =>handleShowDocuments('deleted')}
                                >
                                
                                Deleted
                                </Button>
                            </div>

                            <TableContainer
                                columns={columns}
                                data={ClientsDocuments}
                                isGlobalFilter={true}
                                showSearchBtn={true}
                                customPageSize={50}
                                className="custom-header-css"
                                columnsHide={ColumnsHide}
                            />

                        </Col>

                        <Col xs={4}>

                        <Row>
                            <div className="order_notes_div">
                                <CardTitle>Activity Log</CardTitle>
                                <ul className="order_notes">
                                    { DocumentsActivity.map(activity => (
                                    <li key={activity.id} rel={activity.id} className="note">
                                        <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                        <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                        <strong>
                                        {activity.activity_by}
                                        </strong>
                                        </abbr></p>
                                    </li>
                                    ))}
                                </ul>
                            
                            </div>
                        </Row>
                        </Col>
                    </Row>
                    }
                    <br/>
                    <br/>
                    <br/>
                </ModalBody>
            </Modal>

          <Row>
            <Col lg="8">
              <Card>
                <CardBody>
                    <Form
                        onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                        }}
                    >

                    <Row>
                        <Col md={12}>

                            { InIsolation == 1 &&
                            <>
                                <div className="hstack gap-3">

                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    disabled={ModalLoading}
                                    onClick={changeIsolation}
                                >
                                In Isolation
                                </button>

                                    <Button type="submit" className="btn btn-info" > {ClientIsolationFor.label} </Button>
                                
                                    <Button type="button" className="btn btn-info">{roster.isolation_end_date || IsolationEndDate} </Button>
                                </div>
                                <br/><br/>
                            </>
                            }
                            
                            { InIsolation == 0 && 
                            <>
                                <button
                                    type="button"
                                    className="btn btn-success save-user"
                                    disabled={ModalLoading}
                                    onClick={isolateClient}
                                >
                                Isolate
                                </button>
                                <br/><br/>

                            </>
                            }

                    </Col>
                </Row>




                    <Row>

                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                        Account Status
                    </Label>
                    <Col sm={9}>

                        <Input
                            name="user_status"
                            type="radio"
                            onClick={validation.handleChange}
                            value={1}
                            checked={validation.values.user_status == 1 ? true : false}
                        /> &nbsp;&nbsp;Active
                        &nbsp;&nbsp;<Input
                            name="user_status"
                            type="radio"
                            onClick={validation.handleChange}
                            value={0}
                            checked={validation.values.user_status == 0 ? true : false}
                        />&nbsp;&nbsp;InActive
                        
                    </Col>

                    </Row>

                        <Row className="mb-4">

                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Service Types
                        </Label>
                        <Col sm={9}>

                            {ServiceTypesArray.map(item => (
                                <div key={item.id} className="d-inline-flex">
                                <label>
                                    <Input
                                    type="checkbox"
                                    value={item.id}
                                    defaultChecked={ClientsServiceTypes.includes(item.id)}
                                    onChange={(event) => handleCheckboxChange(event)}
                                    />
                                    &nbsp;&nbsp;
                                    {item.service_name}
                                </label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                
                            ))}

                        </Col>
                        
                        </Row>

                        <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Require Covid-19 Vaccinated Staff
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="vaccination_flag"
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value='1'
                                    checked={ validation.values.vaccination_flag == 1 ? true : false}
                                /> &nbsp;&nbsp;Yes
                                &nbsp;&nbsp;<Input
                                    name="vaccination_flag"
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value='0'
                                    checked={validation.values.vaccination_flag == 0 ? true : false}
                                />&nbsp;&nbsp;No
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Require Annual Flu Vaccinated Staff
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="annual_flu_flag"
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value='1'
                                    checked={validation.values.annual_flu_flag == 1 ? true: false}
                                /> &nbsp;&nbsp;Yes
                                &nbsp;&nbsp;<Input
                                    name="annual_flu_flag"
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value='0'
                                    checked={validation.values.annual_flu_flag == 0 ? true: false}
                                />&nbsp;&nbsp;No
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Title
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="user_title"
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value='Mr'
                                    checked={validation.values.user_title == 'Mr' ? true : false}
                                /> &nbsp;&nbsp;Mr
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value='Mrs'
                                    checked={validation.values.user_title == 'Mrs' ? true : false}
                                />&nbsp;&nbsp;Mrs
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value='Miss'
                                    checked={validation.values.user_title == 'Miss' ? true : false}
                                />&nbsp;&nbsp;Miss
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value='Ms'
                                    checked={validation.values.user_title == 'Ms' ? true: false}
                                />&nbsp;&nbsp;Ms
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                First Name
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_fname"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_fname}
                                invalid={
                                validation.touched.client_fname &&
                                    validation.errors.client_fname
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_fname &&
                                validation.errors.client_fname ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_fname}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Last Name
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_lname"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_lname}
                                invalid={
                                validation.touched.client_lname &&
                                    validation.errors.client_lname
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_lname &&
                                validation.errors.client_lname ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_lname}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Client Email
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_email"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_email}
                                invalid={
                                    validation.touched.client_email &&
                                    validation.errors.client_email
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_email &&
                                validation.errors.client_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_email}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_password"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_password}
                                invalid={
                                    validation.touched.client_password &&
                                    validation.errors.client_password
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_password &&
                                validation.errors.client_password ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_password}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                               Re-type Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_confirm_password"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_confirm_password}
                                invalid={
                                    validation.touched.client_confirm_password &&
                                    validation.errors.client_confirm_password
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_confirm_password &&
                                validation.errors.client_confirm_password ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_confirm_password}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>


                        <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Client Gender
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="client_gender"
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value='Male'
                                    checked={validation.values.client_gender == 'Male' ? true : false }
                                /> &nbsp;&nbsp;Male
                                &nbsp;&nbsp;<Input
                                    name="client_gender"
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value='Female'
                                    checked={validation.values.client_gender == 'Female' ? true : false }
                                />&nbsp;&nbsp;Female
                                &nbsp;&nbsp;<Input
                                    name="client_gender"
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value='Neutral'
                                    checked={validation.values.client_gender == 'Neutral' ? true : false }
                                />&nbsp;&nbsp;Neutral
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Client DOB
                            </Label>
                            <Col sm={9}>
                                <InputGroup>
                                <Flatpickr
                                    name="client_dob"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setClientDob(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.client_dob || ClientDob}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                                
                            </Col>

                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Primary Contact no & Security Code
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_mobile_no"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_mobile_no}
                                invalid={
                                validation.touched.client_mobile_no &&
                                    validation.errors.client_mobile_no
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_mobile_no &&
                                validation.errors.client_mobile_no ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_mobile_no}
                                </FormFeedback>
                            ) : null}
                            </Col>
                            
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Emergency Contact No
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_home_no"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_home_no}
                                invalid={
                                validation.touched.client_home_no &&
                                    validation.errors.client_home_no
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_home_no &&
                                validation.errors.client_home_no ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_home_no}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Referral Source
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_referral_source"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_referral_source}
                                invalid={
                                validation.touched.client_referral_source &&
                                    validation.errors.client_referral_source
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_referral_source &&
                                validation.errors.client_referral_source ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_referral_source}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Care Instruction

                            </Label>
                            <Col sm={9}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={CareInstructions}
                                    name="task"
                                    onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    // console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        validation.values.care_instruction = data;
                                        setCareInstructions(data);
                                    }}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        validation.values.care_instruction = data;
                                        // setCareInstructions(data);
                                    }}
                                    
                                />
                            
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Address
                            </Label>
                            <Col sm={9}>

                            <StandaloneSearchBox
                                onLoad={ref => inputRef.current = ref}
                                onPlacesChanged={handlePlaceChanged}
                            >
                                <input
                                    name="client_address"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Location"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={ validation.values.client_address }
                                />
                            </StandaloneSearchBox>

                                {/* <Input
                                
                                    name="client_address"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.client_address}
                                    invalid={
                                        validation.touched.client_address &&
                                        validation.errors.client_address
                                        ? true
                                        : false
                                    }
                                /> */}

                            </Col>
                            {validation.touched.client_address &&
                                validation.errors.client_address ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_address}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Client Postcode
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_postcode"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_postcode}
                                invalid={
                                    validation.touched.client_postcode &&
                                    validation.errors.client_postcode
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_postcode &&
                                validation.errors.client_postcode ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_postcode}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Timezone
                            </Label>
                            <Col sm={9}>
                                <Select
                                    value={ClientTimezone }
                                    name="clinet_timezone"
                                    options={client_timezone_dropdown}
                                    onChange={(e) => setClientTimezone(e)}
                                    className="select2-selection"
                                    defaultValue={client_timezone_dropdown}
                                />
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                For
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="client_type"
                                    type="radio"
                                    onClick={(e) => {
                                        handleClientType('provider');
                                    }}
                                    value='provider'
                                    checked={ClientType == 'provider' ? true : false }
                                /> &nbsp;&nbsp;Provider
                                &nbsp;&nbsp;<Input
                                    name="client_type"
                                    type="radio"
                                    onClick={(e) => {
                                        handleClientType('contractors');
                                    }}
                                    value='contractors'
                                    checked={ClientType == 'contractors' ? true : false }
                                
                                />&nbsp;&nbsp;3rd Party Contractors
                                
                            </Col>
                            
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Client Department
                            </Label>
                            <Col sm={9}>
                            <Select
                                value={ClientDepartment}
                                name="client_department"
                                options={department_dropdown}
                                onChange={(e) => handleDepartmentChange(e)}
                                className="select2-selection"
                                defaultValue={department_dropdown}
                            />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Case Manager
                            </Label>
                            <Col sm={9}>
                            <Select
                                value={ClientCasemanager}
                                name="client_department"
                                options={casemanager_dropdown}
                                // onChange={(e) => setClientCasemanager(e)}
                                onChange={(e) => handleCaseMangerChange(e)}
                                
                                className="select2-selection"
                                defaultValue={casemanager_dropdown}
                            />
                            </Col>
                        </Row>

                        <CardTitle className="mb-4">Provider Contact</CardTitle>
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Name
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="client_emergency_contact_name"
                                    type="text"
                                    // onChange={validation.handleChange}
                                    // onBlur={validation.handleBlur}
                                    defaultValue={EmergencyContactName}
                                    // invalid={
                                    //     validation.touched.client_emergency_contact_name &&
                                    //     validation.errors.client_emergency_contact_name
                                    //     ? true
                                    //     : false
                                    // }
                                />
                            </Col>
                            {/* {validation.touched.client_emergency_contact_name &&
                                validation.errors.client_emergency_contact_name ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_emergency_contact_name}
                                </FormFeedback>
                            ) : null} */}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Relation
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="client_emergency_contact_relation"
                                    type="text"
                                    // onChange={validation.handleChange}
                                    // onBlur={validation.handleBlur}
                                    defaultValue={EmergencyContactRelation}
                                    // invalid={
                                    //     validation.touched.client_emergency_contact_relation &&
                                    //     validation.errors.client_emergency_contact_relation
                                    //     ? true
                                    //     : false
                                    // }
                                />
                            </Col>
                            {/* {validation.touched.client_emergency_contact_relation &&
                                validation.errors.client_emergency_contact_relation ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_emergency_contact_relation}
                                </FormFeedback>
                            ) : null} */}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Mobile No
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="client_emergency_contact_mobile"
                                    type="text"
                                    // onChange={validation.handleChange}
                                    // onBlur={validation.handleBlur}
                                    defaultValue={EmergencyContactMobile}
                                    // invalid={
                                    //     validation.touched.client_emergency_contact_mobile &&
                                    //     validation.errors.client_emergency_contact_mobile
                                    //     ? true
                                    //     : false
                                    // }
                                />
                            </Col>
                            {/* {validation.touched.client_emergency_contact_mobile &&
                                validation.errors.client_emergency_contact_mobile ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_emergency_contact_mobile}
                                </FormFeedback>
                            ) : null} */}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Email
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="client_emergency_contact_email"
                                    type="text"
                                    // onChange={validation.handleChange}
                                    // onBlur={validation.handleBlur}
                                    defaultValue={EmergencyContactEmail}
                                    // invalid={
                                    //     validation.touched.client_emergency_contact_email &&
                                    //     validation.errors.client_emergency_contact_email
                                    //     ? true
                                    //     : false
                                    // }
                                />
                            </Col>
                            {/* {validation.touched.client_emergency_contact_email &&
                                validation.errors.client_emergency_contact_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_emergency_contact_email}
                                </FormFeedback>
                            ) : null} */}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Staffs Excluded from rosters
                            </Label>
                            <Col sm={9}>
                                <Select
                                    isMulti={true}
                                    value={StaffsExecluded}
                                    name="client_department"
                                    options={staffs_dropdown}
                                    onChange={(e) => setStaffsExecluded(e)}
                                    className="select2-selection"
                                    defaultValue={staffs_dropdown}
                                />
                            </Col>
                            
                        </Row>

                        <CardTitle className="mb-4">Client Rosters Access Details</CardTitle>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Access Enable/Disable
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="access_status"
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value='1'
                                    checked={validation.values.access_status == 1 ? true : false }
                                /> Enable
                                
                                &nbsp;&nbsp;<Input
                                    name="access_status"
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value='0'
                                    checked={validation.values.access_status == 0 ? true : false }
                                /> Disable
                            </Col>
                            
                        </Row>

                        { validation.values.access_status == 1 && 
                        <>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Access Email
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="access_email"
                                    type="email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.access_email}
                                />
                                
                            </Col>

                        </Row>

                        <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Contact no
                        </Label>
                        <Col sm={9}>
                            <Input
                                name="access_contact_no"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.access_contact_no}
                            />
                            
                        </Col>

                        </Row>
                        </>
                        
                        }



                        <CardTitle className="mb-4">Covid-19 Vaccination</CardTitle>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                First Dose
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="first_dose"
                                    type="checkbox"
                                    onChange={validation.handleChange}
                                    value='1'
                                    checked={validation.values.first_dose == 1 ? true : false }
                                />
                            </Col>
                            
                        </Row>

                        {  validation.values.first_dose == 1  && (
                            <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                First Dose Date
                            </Label>
                            <Col sm={9}>
                                <InputGroup>
                                <Flatpickr
                                    name="first_dose_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setFirstDoseDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.first_dose_date || FirstDoseDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                                
                            </Col>

                        </Row>
                        )}
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Second Dose
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="second_dose"
                                    type="checkbox"
                                    onChange={validation.handleChange}
                                    value='1'
                                    checked={validation.values.second_dose == 1 ? true : false}

                                />
                            </Col>
                            
                        </Row>

                        {  validation.values.second_dose == 1  && (
                            <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Second Dose Date
                            </Label>
                            <Col sm={9}>
                                <InputGroup>
                                <Flatpickr
                                    name="second_dose_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setSecondDoseDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.second_dose_date || SecondDoseDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                                
                            </Col>

                        </Row>
                        )}
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Annual Flu Vaccination
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="annual_flu_dose"
                                    type="checkbox"
                                    onChange={validation.handleChange}
                                    value='1'
                                    checked={validation.values.annual_flu_dose == 1 ? true : false}
                                />
                            </Col>
                            
                        </Row>

                        {  validation.values.annual_flu_dose == 1  && (
                            <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Annual Flu Vaccination Date
                            </Label>
                            <Col sm={9}>
                                <InputGroup>
                                <Flatpickr
                                    name="annual_flu_dose_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setAnnualFluDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.annual_flu_dose_date || AnnualFluDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                                
                            </Col>

                        </Row>
                        )}
                        

                        {/* { AddPlanBtn &&  */}
                        {/*  } */}
                        

                        <Row className="mb-4">
                            <Col>
                            <div className="hstack gap-3">
                                <Button type="submit" className="btn btn-success save-user" disabled={submitDisabled} >Update</Button>
                                <div className="vr"></div>
                                { AddPlanBtn &&
                                    <Button type="button" onClick={toggleAddPacakeModal} className="btn btn-info">Add Package</Button> 
                                }

                                <Button type="button" onClick={toggleClientAssesmentCarePlan} className="btn btn-defaul">Assesment Emergency Plan</Button>

                                <Button type="button" onClick={toggleClientPlanModal} className="btn btn-defaul">Care Plan</Button>

                                <Button type="button" onClick={toggleClientDischargeForm} className="btn btn-defaul">Discharge Form</Button>
                            </div>
                            </Col>
                        </Row>

                    </Form>
                </CardBody>
              </Card>

              <Card>
                    <CardBody>
                        <CardTitle>Upload Documents</CardTitle>
                        <Dropzone 
                            onDrop={acceptedFiles => {
                                handleUploadDoc(acceptedFiles)
                            }}
                        >
                        {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                            <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here</h4>
                            </div>
                            </div>
                        )}
                        </Dropzone>

                        <div className="dropzone-previews mt-3" id="file-previews">
                        {selectedDocFiles.map((f, i) => {
                            // console.log(f);
                            return (
                            <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                            >
                                <div className="p-2">
                                <Row className="align-items-center">
                                    <Col className="col-auto">
                                    {f.type != 'application/pdf' ?
                                    <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                    />
                                    : null }
                                    </Col>
                                    <Col>
                                    <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                    >
                                        {f.name}
                                    </Link>
                                    <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                    </p>
                                    </Col>
                                </Row>
                                </div>
                            </Card>
                            )
                        })}
                        </div>

                    </CardBody>
                </Card>
            
            </Col>
            <Col lg="4">

                { SendSms  &&  (
                    <>
                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                            <Label className="form-label">Sms Subject</Label>
                            <Input
                                name="sms_subject"
                                type="text"
                                onChange={e => {
                                setSmsSubject(e.target.value);
                                }}
                                value={SmsSubject}
                            />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                            <Label className="form-label">Client Mobile Number</Label>
                            <Input
                                name="sms_mobile_no"
                                type="text"
                                onChange={e => {
                                    setSmsMobileNo(e.target.value);
                                }}
                                value={SmsMobileNo}
                            />
                            </div>
                        </Col>
                    </Row>
                    </>

                    )}
                    
                <Row>
                    <Col xs={12}>

                        <div className="mb-3">
                            <Label className="form-label">Add Notes</Label>
                            <Input
                            name="roster_notes"
                            type="textarea"
                            onChange={e => {
                                setClientNotes(e.target.value);
                            }}
                            value={ClientNotes}
                            />
                            
                        </div>
                    
                    </Col>
                </Row>

                <Row>
                    <Col>
                    <Input
                        type="checkbox"
                        name="sendSms"
                        onChange={(event) => handleSendSms(event)}
                    /> Send Sms
                    <div className="text-end">
                        <button onClick={addClientNotes}
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={rosterNotesLoading}
                        >
                        Add
                        </button>
                    </div>
                    </Col>
                </Row>

                <Row>
                    <div className="order_notes_div">
                    {
                    rosterNotesLoading ? <Spinners setLoading={setLoading} />
                    :
                    <ul className="order_notes">
                        { ClientNotesList.map(activity => (
                        <li key={activity.id} rel={activity.id} className="note">
                            <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                            <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                            <strong>
                            {activity.activity_by}
                            </strong>
                            </abbr></p>
                        </li>
                        ))}
                    </ul>
                    }
                    </div>
                </Row>

            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

ClientsProfile.propTypes = {
    match: PropTypes.any,
};
export default withRouter(ClientsProfile);