import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import axios from 'axios';
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {Name, Desc, Status } from "../../components/Common/TableColumns";
import Select from "react-select";

import { ACTION_URL, APP_URL, SITE_URL } from "helpers/url_helper"

import Dropzone from "react-dropzone";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle,
  ModalFooter
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getAlerts as onGetAlerts,
    getAlertRosterDetails as onGetAlertRosterDetails,
    updateAlertRosterDetails as onUpdateAlertRosterDetails
} from "store/actions";

const AlertRosterPage = props => {
  //meta title
  document.title="Roster Alerts | Tacveiha Panel";

  const verified_options = [
    {'label' : 'Select Status', value:'0'},
    {'label' : 'Verified', value:'1'}
  ]

  const params = props.router.params;  

  const [pageTitle, setPageTitle] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');
  const [rosterClient, setRosterClient] = useState('');
  const [rosterStaff, setRosterStaff] = useState('');
  const [payCat, setPayCat] = useState('');
  const [supportType, setSupportType] = useState('');
  const [verifiedStatus, setVerifiedStatus] = useState('');
  const [RosterNotesList, setRosterNotesList] = useState([]);


  //roster notes section
  const [RosterNotes, setRosterNotes] = useState('');
  const [TacChatChecked, setTacChatChecked] = useState(false);
  const [TacComChecked, setTacComChecked] = useState(false);
  const [SendSms, setSendSms] = useState(false);
  const [RosterNotesStaff, setRosterNotesStaff] = useState({label:'select staffs', value:0});
  const [RosterNotesStaffsDisabled, setRosterNotesStaffsDisabled ] = useState(true)
  const [SmsSubject, setSmsSubject ] = useState('');
  const [TacComSubject, setTacComSubject] = useState('');

  //client notes section
  const [ClientNotes, setClientNotes] = useState('');
  const [ClientNotesList, setClientNotesList] = useState([]);

  
  const [sendReportChecked, setSendReportChecked ] = useState(false);
  const [adminVerifiedStatus, setAdminVerifiedStatus] = useState(verified_options);

  //dropzone
  const [TopLoading, setTopLoading] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [selectedDocFiles, setselectedDocFiles] = useState([]);
   

  const [roster, setRoster] = useState([]);

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.AlertRostersReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        rosterAlertsList: rosterReducer.rosterAlertsList,
        alertRosterDetails: rosterReducer.alertRosterDetails,
        payCategoryDropdown: rosterReducer.payCategoryDropdown,
        supportTypeDropdown: rosterReducer.supportTypeDropdown,
        staffsDropdown: rosterReducer.staffsDropdown,
        loading: rosterReducer.loading,
        detailsLoading: rosterReducer.detailsLoading,
        hiddenColumns: rosterReducer.hiddenColumns
      })
  );
  const {
    rosterAlertsList, alertRosterDetails, payCategoryDropdown,supportTypeDropdown,staffsDropdown,loading, hiddenColumns,detailsLoading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);
  const [axiosLoading, setAxiosLoading] = useState(false);
  const [rosterNotesLoading, setRosterNotesLoading] = useState(false);
  const [clientsNotesLoading, setClientsNotesLoading] = useState(false);
  
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ros_id: ( roster && roster.ros_id) || "",
      start_date: ( roster && roster.start_date) || "",
      start_time: ( roster && roster.start_time) || "",
      finish_time: ( roster && roster.finish_time) || "",
      actual_start_time: ( roster && roster.actual_start_time) || "",
      actual_finish_time: ( roster && roster.actual_finish_time) || "",
      client_id: ( roster && roster.client_id) || "",
      staff_id: ( roster && roster.staff_id) || "",
      client_address: ( roster && roster.client_address) || "",
      odometer_start: ( roster && roster.odometer_start) || "",
      odometer_end: ( roster && roster.odometer_end) || "",
      roster_kms: ( roster && roster.roster_kms) || "",
      roster_kms_for: ( roster && roster.roster_kms_for) || "",
      roster_parking_fee: ( roster && roster.roster_parking_fee) || "",
      // ros_support_type: ( roster && roster.ros_support_type) || "",
      tasks: ( roster && roster.tasks) || "",
      roster_extra_details: ( roster && roster.roster_extra_details) || "",
      roster_staff_notes_verified: ( roster && roster.roster_staff_notes_verified) || "",
      sub_admin_verified_datetime: ( roster && roster.sub_admin_verified_datetime) || "",
      activity_by: ( roster && roster.activity_by) || "",
      admin_notes_verified: ( roster && roster.admin_notes_verified) || "",
      admin_followup_notes: ( roster && roster.admin_followup_notes) || "",
      different_location_text: ( roster && roster.different_location_text) || "",
      client_emergency_contact_email: ( roster && roster.client_emergency_contact_email) || "",
      client_emergency_contact_mobile: ( roster && roster.client_emergency_contact_mobile) || "",
      client_home_no: ( roster && roster.client_home_no) || "",
      client_mobile_no: ( roster && roster.client_mobile_no) || "",
      pay_category: ( roster && roster.pay_category) || "",
      billable: ( roster && roster.billable) || "No",
      payable: ( roster && roster.payable) || "No",
      allowance : ( roster && roster.allowance) || "",
      roster_admin_verified_status: ( roster && roster.roster_admin_verified_status) || "",
      send_roster_report: ( roster && roster.send_roster_report) || "",

    },
    validationSchema: Yup.object({
        start_time: Yup.string().required("Please enter start time"),
        finish_time: Yup.string().required("Please enter finish time"),
        actual_start_time: Yup.string().required("Please enter actual start time"),
        actual_finish_time: Yup.string().required("Please enter actual finish time"),
        admin_notes_verified: Yup.string().required("Please enter admin notes verified"),
        admin_followup_notes: Yup.string().required("Please enter admin followup notes"),
    }),
    onSubmit: values => {
      if (isEdit) {

        if( verifiedStatus == '' || verifiedStatus.value == 0 ) {
          toast.error("Please select roster verified status", { autoClose: 5000 });
          return false;
        }
        const updateUser = {
            ros_id: values.ros_id,
            start_time: values.start_time,
            finish_time: values.finish_time,
            actual_start_time: values.actual_start_time,
            actual_finish_time: values.actual_finish_time,
            roster_kms_for: values.roster_kms_for,
            odometer_start: values.odometer_start,
            odometer_end: values.odometer_end,
            roster_kms: values.roster_kms,
            admin_notes_verified: values.admin_notes_verified,
            admin_followup_notes: values.admin_followup_notes,
            ros_support_type: supportType,
            roster_parking_fee: values.roster_parking_fee,
            roster_admin_verified_status: verifiedStatus,
            send_roster_report: sendReportChecked ? 1 : 0
        };

        // console.log( updateUser);

        // return;
        // update data
        // dispatch(onUpdateAlertRosterDetails({'data_for':'update_alert_roster_details','data': updateUser,'user': loggedUser}));

        setAxiosLoading(true);
        axios.post(APP_URL+ACTION_URL,{'data_for':'admin_alert_edit_rosters','formData':updateUser, 'user': loggedUser})
          .then(res => {
            setAxiosLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
              toast.success(fetchedData.html, { autoClose: 5000 });
              setRosterNotesList(RosterNotesList => [fetchedData.rosterActivity,...RosterNotesList]);              
              localStorage.setItem('alerts_count',fetchedData.alerts_count);
              localStorage.setItem('admin_alerts_count',fetchedData.admin_alerts_count);
              localStorage.setItem('caremanager_alerts_count',fetchedData.caremanager_alerts_count);
              localStorage.setItem('service_manager_alerts_count',fetchedData.service_manager_alerts_count);

              location.reload();
            }
            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }

          }
        )
     
        setIsEdit(false);
      } else {

      }
    },
  });

  useEffect(() => {
    // console.log('123');

    const formData = {
      type: params.type
    }
    dispatch(onGetAlerts({'data_for':'get_roster_alerts','formData': formData,'user': loggedUser}));
  }, [params.type]);


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster([]);

      setSendSms(false);
      setRosterNotesStaffsDisabled(true);
      setTacChatChecked(false);
      setTacComChecked(false);
      setRosterNotes()
      setTacComSubject('')
      setRosterNotesList([]);

    } else {
        setModal(true);
    }

    

  };

  const addNewsFeeds = arg => {
    setIsEdit(false);
    toggle();
  }

  const handleUserClick = arg => {
    const user = arg;
    // console.log(user.ros_id);
    toggle();

    setAxiosLoading(true);
    axios.post(APP_URL+'/alert_rosters',{'data_for':'get_roster_alerts_details','ros_id': user.ros_id,'user': loggedUser})
      .then(res => {
        setAxiosLoading(false);
        const fetchedData = res.data.data;
      
        //dispatch(onGetAlertRosterDetails({'data_for':'get_roster_alerts_details','ros_id': user.ros_id,'user': loggedUser}));
        setRosterClient({label:fetchedData.client_name,value:fetchedData.client_id});
        setRosterStaff({label:fetchedData.staff_name,value:fetchedData.staff_id});
        setPayCat({label:fetchedData.pay_category_label,value:fetchedData.pay_category_value})
        setSupportType({label:fetchedData.ros_support_type_label,value:fetchedData.ros_support_type_value});
        setRosterNotesList(fetchedData.roster_notes);
        setClientNotesList(fetchedData.client_notes);
        setRoster({
              ros_id: fetchedData.ros_id,
              start_date: fetchedData.start_date,
              start_time: fetchedData.start_time,
              finish_time: fetchedData.finish_time,
              actual_start_time: fetchedData.actual_start_time,
              actual_finish_time: fetchedData.actual_finish_time,
              client_id: fetchedData.client_id,
              staff_id: fetchedData.staff_id,
              client_address: fetchedData.client_address,
              odometer_start: fetchedData.odometer_start,
              odometer_end: fetchedData.odometer_end,
              roster_kms: fetchedData.roster_kms,
              roster_kms_for: fetchedData.roster_kms_for,
              roster_parking_fee: fetchedData.roster_parking_fee,
              // ros_support_type: fetchedData.ros_support_type,
              tasks: fetchedData.tasks,
              roster_extra_details: fetchedData.roster_extra_details,
              roster_staff_notes_verified: fetchedData.roster_staff_notes_verified,
              sub_admin_verified_datetime: fetchedData.sub_admin_verified_datetime,
              activity_by: fetchedData.activity_by,
              admin_notes_verified: fetchedData.admin_notes_verified,
              admin_followup_notes: fetchedData.admin_followup_notes,
              different_location_text: fetchedData.different_location_text,
              client_emergency_contact_email: fetchedData.client_emergency_contact_email,
              client_emergency_contact_mobile: fetchedData.client_emergency_contact_mobile,
              client_home_no: fetchedData.client_home_no,
              client_mobile_no: fetchedData.client_mobile_no,
              pay_category: fetchedData.pay_category,
              billable: fetchedData.billable,
              payable: fetchedData.payable,
              allowance: fetchedData.allowance,
              roster_admin_verified_status: fetchedData.roster_admin_verified_status,
              send_roster_report: fetchedData.send_roster_report,
          });
          setIsEdit(true);
    })

  }

  const handleUserClicks = () => {
    setRoster("");
    setIsEdit(false);
    toggle();
  };

  // const clsoeModal = () => {
  //   toggle();
  // }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = roster => {
    setRoster(roster);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    //dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleVerifiedStatus = (e) => {
    setVerifiedStatus(e);
    // console.log(e)
  }


  const handleTacChat = (arg) => {
    setTacChatChecked(arg);

    if( arg === true ) {
      setRosterNotesStaffsDisabled(false);
    } else {
      setRosterNotesStaffsDisabled(true);
    }
  }

  const handleSendSms = (arg) => {
    setSendSms(arg);
    if( arg === true ) {
      setRosterNotesStaffsDisabled(false);
    } else {
      setRosterNotesStaffsDisabled(true);
    }
  }

  const addRosterNotes = () => {

    // console.log(RosterNotesStaff);

    if( RosterNotes == '' ) {
      toast.error("Please add notes", { autoClose: 5000 });
      return false;
    }

    if( TacComChecked && TacComSubject == '' ) {
      toast.error("Please provide taccom subject", { autoClose: 5000 });
      return false;
    }

    if( TacChatChecked &&  RosterNotesStaff.value == '0' ) {
      toast.error("Please select Staff", { autoClose: 5000 });
      return false;
    }

    if( SendSms && SmsSubject == '' ) {
      toast.error("Please provide sms subject", { autoClose: 5000 });
      return false;
    }

    if( SendSms && RosterNotesStaff.value == '0' ) {
      toast.error("Please select Staff", { autoClose: 5000 });
      return false;
    }

    
    const RosterNotesForm = {
        ros_id: roster.ros_id,
        rosterNotes: RosterNotes,
        tacChatChecked: TacChatChecked,
        tacComChecked:TacComChecked,
        tacComSubject: TacComSubject,
        sendSms: SendSms,
        sms_subject: SmsSubject,
        staffs: RosterNotesStaff
    };

    setRosterNotesLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'add_roster_notes','formData': RosterNotesForm,'user': loggedUser})
      .then(res => {
        setRosterNotesLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
          setRosterNotes('');
          setTacChatChecked(false)
          setTacComChecked(false)
          setSmsSubject('')
          setTacComSubject('');
          setSendSms(false)
          setRosterNotesStaff({label:'select staffs', value:0})
          setRosterNotesStaffsDisabled(true)
          setRosterNotesList(RosterNotesList => [fetchedData.data,...RosterNotesList]);
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
    })
  }


  const addClientsNotes = () => {

    if( ClientNotes == '' ) {
      toast.error("Please add notes", { autoClose: 5000 });
      return false;
    }
    const ClientsNotesForm = {
        ros_id: roster.client_id,
        notes: ClientNotes,
        activity_for: 'roster_client_notes'
    };

    setClientsNotesLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'add_notes_client','formData': ClientsNotesForm,'user': loggedUser})
      .then(res => {
        setClientsNotesLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
          setClientNotes('');
          setClientNotesList(ClientNotesList => [fetchedData.data,...ClientNotesList]);
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
        
    })
  }

  //dropzone
  const handleUploadDoc = (files) => {

    files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
    )
    
    setselectedDocFiles(files);

    const formData = new FormData();

    files.forEach((file) => {
        formData.append('file', file);
    });

    formData.append('data_for', 'client_doc');
    formData.append('user_id', roster.client_id);
    formData.append('user_type', 'client_doc');
    formData.append('is_expiry', '');
    formData.append('expiry_date', '');
    formData.append('doc_list_id', '');
    formData.append('doc_list_name', '');
    formData.append('added_by', loggedUser.uid);
    formData.append('role', loggedUser.role);

    setAxiosLoading(true);

    axios.post(APP_URL+'/upload_files.php',formData,{
        headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      setAxiosLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
            toast.success(fetchedData.html, { autoClose: 5000 });
            setselectedDocFiles([]);
        }

        if( fetchedData.status == 'error' ) {
            toast.error(fetchedData.html, { autoClose: 5000 });
        }

    })
}


/**
 * Formats the size
 */
function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}


  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "ros_id",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Client Name",
        accessor: "client_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Staff Name",
        accessor: "staff_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Roster Date",
        accessor: "start_date",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Start Time",
        accessor: "start_time",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Finish Time",
        accessor: "finish_time",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "ros_status",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              
            </div>
          );
        },
      },
    ],
    []
  );

  const handleEditRoster = () => {
    validation.handleSubmit();
  }

  return (
    <React.Fragment>
      { loading ? <TopProgressBar loading/> : '' }

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

      <Modal isOpen={modal} toggle={toggle} size="xl" backdrop="static" scrollable>
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "Edit Details" : "Add Details"} { axiosLoading ? <Spinners setLoading={setLoading} /> : '' }
        </ModalHeader>
        <ModalBody>

          <Row>
            <Col xs={8}>

              <Card>
                <CardBody>
                  <Form
                      // onSubmit={e => {
                      //   e.preventDefault();
                      //   validation.handleSubmit();
                      //   return false;
                      // }}
                    >

                      <Row>
                        <Col xs={4}>
                          <div className="mb-3">
                            <Label className="form-label">Date</Label>
                            <Input
                              name="start_date"
                              type="date"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.start_date}
                              options={{
                                // altInput: true,
                                // altFormat: "F j, Y",
                                dateFormat: "Y-m-d"
                              }}
                              // readOnly={true}
                            />
                          
                          </div>
                          
                        </Col>

                        <Col xs={4}>
                          <div className="mb-3">
                            <Label className="form-label">Start Time</Label>
                            <Input
                              name="start_time"
                              type="time"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.start_time}
                              readOnly={true}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i"
                              }}
                            />
                          </div>
                          
                        </Col>

                        <Col xs={4}>
                          <div className="mb-3">
                            <Label className="form-label">Finish Time</Label>
                            <Input
                              name="finish_time"
                              type="time"
                              value={validation.values.finish_time}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i"
                              }}
                            />
                            
                          </div>
                          
                        </Col>

                      </Row>

                      <Row>
                    <Col xs={4}>
                      {/* <div className="mb-3">
                        <Label className="form-label">Date</Label>
                        <Input
                          name="service_name"
                          type="date"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.service_name || ""}
                          invalid={
                            validation.touched.service_name &&
                              validation.errors.service_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.service_name &&
                          validation.errors.service_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.service_name}
                          </FormFeedback>
                        ) : null}
                      </div> */}
                      
                    </Col>

                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Actual Start Time</Label>
                        <Input
                          name="actual_start_time"
                          type="time"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.actual_start_time || ""}
                          invalid={
                            validation.touched.actual_start_time &&
                              validation.errors.actual_start_time
                              ? true
                              : false
                          }
                        />
                        {validation.touched.actual_start_time &&
                          validation.errors.actual_start_time ? (
                          <FormFeedback type="invalid">
                            {validation.errors.actual_start_time}
                          </FormFeedback>
                        ) : null}
                      </div>
                      
                    </Col>

                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Actual Finish Time</Label>
                        <Input
                          name="actual_finish_time"
                          type="time"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.actual_finish_time || ""}
                          invalid={
                            validation.touched.actual_finish_time &&
                              validation.errors.actual_finish_time
                              ? true
                              : false
                          }
                        />
                        {validation.touched.actual_finish_time &&
                          validation.errors.actual_finish_time ? (
                          <FormFeedback type="invalid">
                            {validation.errors.actual_finish_time}
                          </FormFeedback>
                        ) : null}
                      </div>
                      
                    </Col>

                  </Row>

                  <Row>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Roster Client</Label>
                        <Select
                          value={rosterClient}
                          name="client_id"
                          options={rosterClient}
                          className="select2-selection"
                          defaultValue={rosterClient}
                          isDisabled={true}
                        />
                      </div>
                      
                    </Col>

                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Roster Staff</Label>
                        <Select
                          value={rosterStaff}
                          name="staff_id"
                          options={rosterStaff}
                          className="select2-selection"
                          defaultValue={rosterStaff}
                          isDisabled={true}
                        />
                      </div>
                      
                    </Col>

                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Location</Label>
                        <Input
                          name="client_address"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.client_address}
                          invalid={
                            validation.touched.location &&
                              validation.errors.location
                              ? true
                              : false
                          }
                        />
                        {validation.touched.location &&
                          validation.errors.location ? (
                          <FormFeedback type="invalid">
                            {validation.errors.location}
                          </FormFeedback>
                        ) : null}
                      </div>
                      
                    </Col>

                  </Row>

                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Odometer Start Reading</Label>
                        <Input
                          name="odometer_start"
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.odometer_start}
                        />
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Odometer End Reading</Label>
                        <Input
                          name="odometer_end"
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.odometer_end}
                        />
                      </div>
                      
                    </Col>

                  </Row>

                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Kms</Label>
                        <Input
                          name="roster_kms"
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={(validation.values.odometer_end - validation.values.odometer_start).toFixed(2)}
                          invalid={
                            validation.touched.roster_kms &&
                              validation.errors.roster_kms
                              ? true
                              : false
                          }
                          readOnly
                        />
                        {validation.touched.roster_kms &&
                          validation.errors.roster_kms ? (
                          <FormFeedback type="invalid">
                            {validation.errors.roster_kms}
                          </FormFeedback>
                        ) : null}
                      </div>
                      
                    </Col>

                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Kms Description</Label>
                        <Input
                          name="roster_kms_for"
                          type="textarea"
                          value={validation.values.roster_kms_for || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                      </div>
                      
                    </Col>

                  </Row>

                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Support Type</Label>
                        <Select
                          value={supportType}
                          name="ros_support_type"
                          options={supportTypeDropdown}
                          className="select2-selection"
                          defaultValue={supportType}
                          onChange={(e) => setSupportType(e)}
                          onBlur={validation.handleBlur}
                        />
                      
                      </div>
                      
                    </Col>

                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Parking Fee</Label>
                        <Input
                          name="roster_parking_fee"
                          type="number"
                          value={validation.values.roster_parking_fee || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                      </div>
                      
                    </Col>

                  </Row>

                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Care Instructions / Task</Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={validation.values.tasks}
                          name="task"
                          onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            // console.log('Editor is ready to use!', editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            validation.values.tasks = data;
                          }}
                          onBlur={(event, editor) => {
                            const data = editor.getData();
                            validation.values.tasks = data;
                          }}
                        />
                      </div>
                      
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Old Staff Notes</Label>
                        <Input
                          name="roster_extra_details"
                          type="textarea"
                          value={validation.values.roster_extra_details || ""}
                          readOnly={true}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                      </div>
                      
                    </Col>
                  </Row>

                  <Row>

                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Staff Notes</Label>
                        <Input
                          name="roster_staff_notes_verified"
                          type="textarea"
                          value={validation.values.roster_staff_notes_verified || ""}
                          readOnly={true}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />

                        
                        { !isEmpty(validation.values.sub_admin_verified_datetime) ? 
                        <em>{validation.values.sub_admin_verified_datetime} by {validation.values.activity_by}</em>
                        : '' }
                      </div>
                      
                      
                    </Col>

                  </Row>

                  <Row>

                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Super Admin/Sub Admin Update Care Notes</Label>
                        <Input
                          name="admin_notes_verified"
                          type="textarea"
                          value={validation.values.admin_notes_verified || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.admin_notes_verified &&
                              validation.errors.admin_notes_verified
                              ? true
                              : false
                          }
                        />
                        {validation.touched.admin_notes_verified &&
                          validation.errors.admin_notes_verified ? (
                          <FormFeedback type="invalid">
                            {validation.errors.admin_notes_verified}
                          </FormFeedback>
                        ) : null}

                      </div>
                      
                    </Col>

                  </Row>

                  <Row>

                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Admin Followup Notes</Label>
                        <Input
                          name="admin_followup_notes"
                          type="textarea"
                          value={validation.values.admin_followup_notes || ""}
                          placeholder="Admin Followup Notes"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.admin_followup_notes &&
                              validation.errors.admin_followup_notes
                              ? true
                              : false
                          }
                        />
                        {validation.touched.admin_followup_notes &&
                          validation.errors.admin_followup_notes ? (
                          <FormFeedback type="invalid">
                            {validation.errors.admin_followup_notes}
                          </FormFeedback>
                        ) : null}
                      </div>
                      
                    </Col>

                  </Row>

                  <Row>

                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Different Location Text</Label>
                        <Input
                          name="different_location_text"
                          type="textarea"
                          value={validation.values.different_location_text || ""}
                          readOnly={true}
                          placeholder="Different Location Text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                      </div>
                      
                    </Col>

                  </Row>

                  {/* { loggedUser.role == 'admin' || loggedUser.role == 'sub_admin' } */}
                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Provider Email</Label>
                        <Input
                          name="client_emergency_contact_email"
                          type="text"
                          value={validation.values.client_emergency_contact_email || ""}
                          readOnly={true}
                          
                        />
                      </div>
                      
                    </Col>

                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Provider Contact</Label>
                        <Input
                          // name="client_emergency_contact_mobile"
                          type="text"
                          value={validation.values.client_emergency_contact_mobile || ""}
                          readOnly={true}
                        />
                      </div>
                      
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Primary Contact & Security Code </Label>
                        <Input
                          
                          type="text"
                          value={validation.values.client_mobile_no || ""}
                          readOnly={true}
                        />
                      </div>
                      
                    </Col>

                    <Col xs={6}>
                      <div className="mb-3">
                        <Label className="form-label">Emergency Contact</Label>
                        <Input
                          
                          type="text"
                          value={validation.values.client_home_no || ""}
                          readOnly={true}
                        />
                      </div>
                      
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Pay Category External ID</Label>

                        <Select
                          value={payCat}
                          name="client_id"
                          options={payCategoryDropdown}
                          className="select2-selection"
                          defaultValue={payCat}
                          onChange={(e) => setPayCat(e)}
                          onBlur={validation.handleBlur}
                        />
                        
                      </div>
                      
                    </Col>
                    
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Billable&nbsp;&nbsp;</Label>
                        <Input
                          name="billable"
                          type="radio"
                          value="Yes"
                          onClick={validation.handleChange}
                          checked={validation.values.billable == "Yes" ? true : false }
                          
                        />&nbsp;&nbsp;Yes&nbsp;&nbsp;
                        <Input
                        name="billable"
                          type="radio"
                          value="No"
                          onClick={validation.handleChange}
                          checked={validation.values.billable == "No" ? true : false }
                        />&nbsp;&nbsp;No&nbsp;&nbsp;
                        
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Billable&nbsp;&nbsp;</Label>
                        <Input
                          name="payable"
                          type="radio"
                          value="Yes"
                          onClick={validation.handleChange}
                          checked={validation.values.payable == "Yes" ? true : false }
                        />&nbsp;&nbsp;Yes&nbsp;&nbsp;
                        <Input
                        name="payable"
                          type="radio"
                          value="No"
                          onClick={validation.handleChange}
                          checked={validation.values.payable == "No" ? true : false }
                        />&nbsp;&nbsp;&nbsp;No
                        
                      </div>
                      
                    </Col>

                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Allowance</Label>

                        <Input
                          type="text"
                          value={validation.values.allowance || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          
                        />
                        
                      </div>
                      
                    </Col>

                  </Row>

                  <Row>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label className="form-label">Choose Status</Label>

                        <Select
                          value={verifiedStatus}
                          name="roster_admin_verified_status"
                          options={adminVerifiedStatus}
                          className="select2-selection"
                          onChange={(e) => {
                            handleVerifiedStatus(e);
                          }}
                          onBlur={validation.handleBlur}
                        />
                        
                      </div>
                      
                      { verifiedStatus.value == '1' && (
                      <div className="mb-3">
                      <Input
                          name="send_roster_report"
                          type="checkbox"
                          value="1"                          
                          onChange={() =>
                            setSendReportChecked(!sendReportChecked)
                          }
                        /> Send Roster Report
                      </div>
                      )}
                    </Col>

                  
                  </Row>

                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                    <CardTitle>Upload Documents</CardTitle>
                    <Dropzone 
                        onDrop={acceptedFiles => {
                            handleUploadDoc(acceptedFiles)
                        }}
                    >
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                        <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here</h4>
                        </div>
                        </div>
                    )}
                    </Dropzone>

                    <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedDocFiles.map((f, i) => {
                        // console.log(f);
                        return (
                        <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                        >
                            <div className="p-2">
                            <Row className="align-items-center">
                                <Col className="col-auto">
                                {f.type != 'application/pdf' ?
                                <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                />
                                : null }
                                </Col>
                                <Col>
                                <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                >
                                    {f.name}
                                </Link>
                                <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                </p>
                                </Col>
                            </Row>
                            </div>
                        </Card>
                        )
                    })}
                    </div>

                </CardBody>
              </Card>

            </Col>

            <Col xs={4}>

              <Row>

                <Col xs={12}>

                  <div className="mb-3">
                    <Label className="form-label">Add Notes</Label>
                    <Input
                      name="roster_notes"
                      type="textarea"
                      onChange={e => {
                        
                        setRosterNotes(e.target.value);
                      }}
                      value={RosterNotes}
                    />
                    
                  </div>
                
                </Col>
                  
              </Row>
              { SendSms  &&  (

              <Row>

                <Col xs={12}>

                  <div className="mb-3">
                    <Label className="form-label">Sms Subject</Label>
                    <Input
                      name="sms_subject"
                      type="text"
                      onChange={e => {
                        setSmsSubject(e.target.value);
                      }}
                      value={SmsSubject}
                    />
                    
                  </div>
                
                </Col>
                  
              </Row>
              
            )}

              { TacComChecked  &&  (

              <Row>

                <Col xs={12}>

                  <div className="mb-3">
                    <Label className="form-label">Taccom Subject</Label>
                    <Input
                      name="taccom_subject"
                      type="text"
                      onChange={e => {
                        setTacComSubject(e.target.value);
                      }}
                      value={TacComSubject}
                    />
                    
                  </div>
                
                </Col>
                  
              </Row>
              )}

              <Row>

                <Col xs={4}>
                  <div className="mb-3">
                    <Label className="form-label">TacChat</Label>
                    <Input
                      name="tacchat"
                      type="checkbox"
                      value="1"
                      // checked={TacChatChecked}
                      onChange={() =>
                        handleTacChat(!TacChatChecked)
                      }
                    />
                    &nbsp;&nbsp;
                  </div>
                </Col>

                <Col xs={4}>
                  <div className="mb-3">
                    <Label className="form-label">TacCom</Label>
                    <Input
                      name="taccom"
                      type="checkbox"
                      value="1"
                      // checked={TacComChecked}
                      // checked={TacComChecked}     
                      onChange={() =>
                        setTacComChecked(!TacComChecked)
                      }
                    />
                    &nbsp;&nbsp;
                  </div>
                </Col>

                <Col xs={4}>
                  <div className="mb-3">
                    <Label className="form-label">Sms</Label>
                    <Input
                      name="send_sms"
                      type="checkbox"
                      value="1"
                      // checked={SendSms}
                      onChange={() =>
                        handleSendSms(!SendSms)
                        // setSendSms(!SendSms)
                      }
                    />
                    &nbsp;&nbsp;
                  </div>
                </Col>
                  
              </Row>

              <Row>

                <Col xs={12}>
                  <div className="mb-3">
                    <Label className="form-label">Staffs</Label>

                    
                    <Select
                      value={RosterNotesStaff}
                      name="roster_notes_staffs"
                      options={staffsDropdown}
                      className="select2-selection"
                      onChange={(e) => {
                        setRosterNotesStaff(e);
                      }}
                      isDisabled={RosterNotesStaffsDisabled}
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                      }}
                    />
                    
                  </div>
                
                </Col>
                  
              </Row>

              <Row>
                <Col>
                  <div className="text-end">
                    <button onClick={addRosterNotes}
                      type="submit"
                      className="btn btn-success save-user"
                      disabled={axiosLoading}
                    >
                      Add
                    </button>
                  </div>
                </Col>
              </Row>

              <Row>
                <div className="order_notes_div">
                  {
                  rosterNotesLoading ? <Spinners setLoading={setLoading} />
                  :
                  <ul className="order_notes">
                    {RosterNotesList.map(activity => (
                      <li key={activity.id} rel={activity.id} className="note">
                        <div dangerouslySetInnerHTML={{__html: activity.activity_for }} className="note_content"></div>
                        <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                        <strong>
                          {activity.activity_by}
                        </strong>
                        </abbr></p>
                      </li>
                    ))}
                  </ul>
                  }
                </div>
              </Row>

              {/* Clients Notes */}

              <Row>

                <Col xs={12}>
                  <br/>
                <h4 className="card-title mb-4">Client's Notes</h4>

                  <div className="mb-3">
                    <Label className="form-label">Add Notes</Label>
                    <Input
                      name="client_notes"
                      type="textarea"
                      onChange={e => {
                        setClientNotes(e.target.value);
                      }}
                      value={ClientNotes}
                    />
                    
                  </div>
                
                </Col>
                  
              </Row>

              <Row>
                <Col>
                  <div className="text-end">
                    <button onClick={addClientsNotes}
                      type="submit"
                      className="btn btn-success save-user"
                      disabled={axiosLoading}
                    >
                      Add
                    </button>
                  </div>
                </Col>
              </Row>

              <Row>
              
                <div className="order_notes_div">
                  {
                  clientsNotesLoading ? <Spinners setLoading={setLoading} />
                  :
                  <ul className="order_notes">
                    {ClientNotesList.map(activity => (
                      <li key={activity.id} rel={activity.id} className="note">
                        <div dangerouslySetInnerHTML={{__html: activity.action + ' - ' + activity.activity_for }} className="note_content"></div>
                        <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                        <strong>
                          {activity.activity_by}
                        </strong>
                        </abbr></p>
                      </li>
                    ))}
                  </ul>
                  }
                </div>
              </Row>

            </Col>

          </Row>
                
        </ModalBody>
        <ModalFooter>

          <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="btn"
                      className="btn btn-success save-user"
                      disabled={axiosLoading}
                      onClick={handleEditRoster}
                    >
                      Update
                    </button>
                  </div>
                </Col>
            </Row>
          
        </ModalFooter>
      </Modal>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem={params.type} />

          <Row className="mb-6">
            {/* <Col sm="12">
                <div className="text-sm-end">
                    <Button
                    type="button"
                    color="primary"
                    className="mb-2 me-2"
                    onClick={addNewsFeeds}
                    >
                    
                    Add News Feeds
                    </Button>
                </div>
            </Col> */}
        </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* {
                    loading ? <Spinners setLoading={setLoading} />
                    : ''
                  } */}
                  <TableContainer
                    columns={columns}
                    data={rosterAlertsList}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={50}
                    className="custom-header-css"
                    excelExport={false}
                    fileName=''
                    hiddenColumns={hiddenColumns}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default withRouter(AlertRosterPage);