import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import axios from 'axios';
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import {Name, Email, Tags, Projects, Status } from "../Providers/columns";
import { ACTION_URL, APP_URL, ADMIN_URL } from "helpers/url_helper"
import { toast } from "react-toastify";

import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"

const libraries =['places'];

//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
  getDepartments as onGetDepartments,
  updateDepartment as onUpdateDepartment,
  addDepartments as onAddDepartments
} from "store/actions";

function Providers(){
  //meta title
  document.title="Departments| Tacveiha Panel";

  const inputRef = useRef();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries
  });
  
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');
  const [ModalLoading, setModalLoading] = useState(false);
  const [StaffsExecluded, setStaffsExecluded] = useState([]);
  const [staffs_dropdown, setStaffs_dropdown] = useState([]);

  const [StaffAddress, setStaffAddress] = useState('');
  const [StaffLatLng, setStaffLatLng] = useState('');

  const [roster, setRoster] = useState(null);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      user_status: (roster && roster.user_status) || "",
      user_type: (roster && roster.user_type) || "provider",
      department_name: (roster && roster.department_name) || "",
      department_fname: (roster && roster.department_fname) || "",
      department_lname: (roster && roster.department_lname) || "",
      department_email: (roster && roster.department_email) || "",
      user_pass: "",
      user_pass_confirm: "",
      department_mobile_no: (roster && roster.department_mobile_no) || "",
      department_home_no: (roster && roster.department_home_no) || "",
      department_address: (roster && roster.department_address) || "",
      department_emergency_contact_name: (roster && roster.department_emergency_contact_name) || "",
      department_emergency_contact_mobile: (roster && roster.department_emergency_contact_mobile) || "",
      department_emergency_contact_email: (roster && roster.department_emergency_contact_email) || "",
      report_weekly: (roster && roster.report_weekly) ? true : false ,
      report_weekly_email: (roster && roster.report_weekly_email) || "",
      report_monthly: (roster && roster.report_monthly) ? true : false,
      report_monthly_email: (roster && roster.report_monthly_email) || "",
      report_daily: (roster && roster.report_daily) ? true : false, 
      report_daily_email: (roster && roster.report_daily_email) || "",
    },
    validationSchema: Yup.object({
      user_type: Yup.string().required("Please select User Type"),
      department_name: Yup.string().required("Please provide department name"),
      department_fname: Yup.string().required("Please provide department first name"),
      department_lname: Yup.string().required("Please provide department last name"),
      department_email: Yup.string().required("Please provide department email"),
      user_pass: Yup.string().oneOf([Yup.ref('user_pass_confirm'),null], 'Passwords much match'),
      user_pass_confirm: Yup.string().oneOf([Yup.ref('user_pass'),null], 'Passwords much match'),
      department_mobile_no: Yup.string().required("Please provie department mobile no"),
      // department_address: Yup.string().required("Please provide department address"),
      department_emergency_contact_name: Yup.string().required("Please Provide Emergency Contact Name"),
      department_emergency_contact_mobile: Yup.string().required("Please Provide Emergency Contact Mobile No"),
      department_emergency_contact_email: Yup.string().required("Please Provide Emergency Contact Email"),
      report_weekly: Yup.boolean().default(false),
      report_weekly_email: Yup.string().when('report_weekly', {
        is: true,
        then: schema => schema.required("Please provide weekly report email"),
        otherwise: schema => schema.optional(),
      }),
      report_monthly: Yup.boolean().default(false),
      report_monthly_email: Yup.string().when('report_monthly', {
        is: true,
        then: schema => schema.required("Please provide monthly report email"),
        otherwise: schema => schema.optional(),
      }),
      report_daily: Yup.boolean().default(false),
      report_daily_email: Yup.string().when('report_daily', {
        is: true ,
        then: schema => schema.required("Please provide daily report email"),
        otherwise: schema => schema.optional(),
      })
    }),
    onSubmit: values => {

      if( StaffAddress == "" ) {
        toast.error("Please provide address", { autoClose: 5000 });
        return false;
      }


      // console.log('form values ' + values);
      if (isEdit) {
        const updateUser = {
          user_id: roster.user_id,
          user_status: values.user_status,
          user_type: values.user_type,
          department_name: values.department_name,
          department_fname: values.department_fname,
          department_lname: values.department_lname,
          department_email: values.department_email,
          user_pass: values.user_pass,
          user_pass_confirm: values.user_pass_confirm,
          department_mobile_no: values.department_mobile_no,
          department_address: StaffAddress,
          department_latlng: StaffLatLng,
          department_emergency_contact_name: values.department_emergency_contact_name,
          department_emergency_contact_mobile: values.department_emergency_contact_mobile,
          department_emergency_contact_email: values.department_emergency_contact_email,
          report_weekly: (values.report_weekly === true) ? 1 : 0,
          report_weekly_email: values.report_weekly_email,
          report_monthly: (values.report_monthly === true) ? 1 : 0,
          report_monthly_email: values.report_monthly_email,
          report_daily: (values.report_daily === true) ? 1 : 0,
          report_daily: values.report_daily_email,
        };

        // console.log(updateUser);        
        setModalLoading(true);

        axios.post(APP_URL+ACTION_URL,{'data_for': 'edit_department','formData': updateUser,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
              dispatch(onUpdateDepartment(fetchedData.data));
              toast.success(fetchedData.html, { autoClose: 5000 });
              validation.resetForm();
              setIsEdit(false);
              toggle();
            }

            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
        
      } else {
        const newData = {
          user_type: values.user_type,
          department_name: values.department_name,
          department_fname: values.department_fname,
          department_lname: values.department_lname,
          department_email: values.department_email,
          department_mobile_no: values.department_mobile_no,
          department_address: StaffAddress,
          department_emergency_contact_name: values.department_emergency_contact_name,
          department_emergency_contact_mobile: values.department_emergency_contact_mobile,
          department_emergency_contact_email: values.department_emergency_contact_email,
        };
        // save new user
        setModalLoading(true);

        axios.post(APP_URL+ACTION_URL,{'data_for': 'add_department','formData': newData,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
            
            if( fetchedData.status == 'success' ) {
              dispatch(onAddDepartments(fetchedData.data));
              toast.success(fetchedData.html, { autoClose: 5000 });
              toggle();
            }

            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
        
      }
    },
  });

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.DepartmentReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        providers: rosterReducer.providers,
        staffsDropdown: rosterReducer.staffsDropdown,
        loading: rosterReducer.loading
      })
  );
  const {
    providers, staffsDropdown, loading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);
  const user = JSON.parse(localStorage.getItem("authUser"));

  useEffect(() => {
    if (providers && !providers.length) {
      // const todaysDate = moment().format("YYYY-MM-DD");
      dispatch(onGetDepartments({'data_for': 'providers','user': user}));
    }
  }, [dispatch]);


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster(null);
    } else {
        setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg;
    setRoster({
      user_id: user.user_id,
      user_status: user.user_status,
      user_type: user.user_type,
      department_name: user.department_name,
      department_fname: user.department_fname,
      department_lname: user.department_lname,
      department_email: user.user_email,
      department_mobile_no: user.department_mobile_no,
      department_address: user.department_address,
      department_emergency_contact_name: user.department_emergency_contact_name,
      department_emergency_contact_mobile: user.department_emergency_contact_mobile,
      department_emergency_contact_email: user.department_emergency_contact_email,
      report_weekly: user.report_weekly,
      report_weekly_email: user.report_weekly_email,
      report_monthly: user.report_monthly,
      report_monthly_email: user.report_monthly_email,
      report_daily: user.report_daily,
      report_daily: user.report_daily_email,
    });
    setStaffAddress(user.department_address);
    setStaffLatLng(user.department_latlng);
    setIsEdit(true);
    toggle();
  };

  const handleUserClicks = () => {
    setRoster([]);
    setIsEdit(false);
    toggle();
  };

  const handlePlaceChanged = () => { 
    const [ place ] = inputRef.current.getPlaces();
    if(place) { 
        validation.values.department_address = place.formatted_address;
        setStaffAddress(place.formatted_address);
        setStaffLatLng(place.geometry.location.lat()+','+place.geometry.location.lng());
    }
  }


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Department Name",
        accessor: "department_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "user_email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      // {
      //   Header: "Gender",
      //   accessor: "client_gender",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return <Name {...cellProps} />;
      //   },
      // },
      {
        Header: "Address",
        accessor: "department_address",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Phone No.",
        accessor: "department_mobile_no",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      
      {
        Header: "Status",
        accessor: "user_status",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Departments" breadcrumbItem="Providers" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <TableContainer
                    columns={columns}
                    data={providers}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={50}
                    className="custom-header-css"
                    isAddBtn={true}
                    btnText={'Add Department'}
                  />
                }
                  <Modal isOpen={modal} toggle={toggle} size="xl" backdrop="static" scrollable>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Details" : "Add Details"}
                      { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

                        { isEdit && 
                      <Row className="mb-4">

                      <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                      > User Status </Label>
                      <Col sm={9}>
                          <Input
                              name="user_status"
                              type="radio"
                              onChange={validation.handleChange}
                              value='1'
                              invalid={
                                  validation.touched.user_status &&
                                  validation.errors.user_status
                                  ? true
                                  : false
                              }
                              checked={validation.values.user_status == 1 ? true : false }
                              
                          /> &nbsp;&nbsp;Active
                          &nbsp;&nbsp;<Input
                              name="user_status"
                              type="radio"
                              onChange={validation.handleChange}
                              value='0'
                              invalid={
                                  validation.touched.user_status &&
                                  validation.errors.user_status
                                  ? true
                                  : false
                              }
                              checked={validation.values.user_status == 0 ? true : false }
                          
                          />&nbsp;&nbsp;Inactive
                          
                          {validation.touched.user_status &&
                          validation.errors.user_status ? (
                          <FormFeedback type="invalid">
                          {validation.errors.user_status}
                          </FormFeedback>
                          ) : null}
                      </Col>

                      </Row>
                      }

                      <Row className="mb-4">

                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        > User Type </Label>
                        <Col sm={9}>
                            <Input
                                name="user_type"
                                type="radio"
                                onChange={validation.handleChange}
                                value='provider'
                                invalid={
                                    validation.touched.user_type &&
                                    validation.errors.user_type
                                    ? true
                                    : false
                                }
                                checked={validation.values.user_type == "provider" ? true : false }
                                
                            /> &nbsp;&nbsp;Provider
                            &nbsp;&nbsp;<Input
                                name="user_type"
                                type="radio"
                                onChange={validation.handleChange}
                                value='contractors'
                                invalid={
                                    validation.touched.user_type &&
                                    validation.errors.user_type
                                    ? true
                                    : false
                                }
                                checked={validation.values.user_type == "contractors" ? true : false }
                            
                            />&nbsp;&nbsp;Contractors
                            
                            {validation.touched.user_type &&
                            validation.errors.user_type ? (
                            <FormFeedback type="invalid">
                            {validation.errors.user_type}
                            </FormFeedback>
                            ) : null}
                        </Col>

                      </Row>

                      <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Department Name
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="department_name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.department_name}
                                invalid={
                                validation.touched.department_name &&
                                    validation.errors.department_name
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.department_name &&
                                validation.errors.department_name ? (
                                <FormFeedback type="invalid">
                                {validation.errors.department_name}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                First Name
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="department_fname"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.department_fname}
                                invalid={
                                validation.touched.department_fname &&
                                    validation.errors.department_fname
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.department_fname &&
                                validation.errors.department_fname ? (
                                <FormFeedback type="invalid">
                                {validation.errors.department_fname}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Last Name
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="department_lname"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.department_lname}
                                invalid={
                                validation.touched.department_lname &&
                                    validation.errors.department_lname
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.department_lname &&
                                validation.errors.department_lname ? (
                                <FormFeedback type="invalid">
                                {validation.errors.department_lname}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Email
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="department_email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.department_email}
                                invalid={
                                validation.touched.department_email &&
                                    validation.errors.department_email
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.department_email &&
                                validation.errors.department_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.department_email}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        { isEdit && 
                        <>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="user_pass"
                                type="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.user_pass}
                                invalid={
                                validation.touched.user_pass &&
                                    validation.errors.user_pass
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.user_pass &&
                                validation.errors.user_pass ? (
                                <FormFeedback type="invalid">
                                {validation.errors.user_pass}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Confirm Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="user_pass_confirm"
                                type="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.user_pass_confirm}
                                invalid={
                                validation.touched.user_pass_confirm &&
                                    validation.errors.user_pass_confirm
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.user_pass_confirm &&
                                validation.errors.user_pass_confirm ? (
                                <FormFeedback type="invalid">
                                {validation.errors.user_pass_confirm}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        </>
                        }

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Mobile No
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="department_mobile_no"
                                type="number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.department_mobile_no}
                                invalid={
                                validation.touched.department_mobile_no &&
                                    validation.errors.department_mobile_no
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.department_mobile_no &&
                                validation.errors.department_mobile_no ? (
                                <FormFeedback type="invalid">
                                {validation.errors.department_mobile_no}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Address
                            </Label>
                            <Col sm={9}>

                            <StandaloneSearchBox
                                onLoad={ref => inputRef.current = ref}
                                onPlacesChanged={handlePlaceChanged}
                            >
                                <input
                                    name="department_address"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Location"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={ validation.values.department_address }
                                />
                            </StandaloneSearchBox>
                            </Col>
                            
                        </Row>

                        

                        {/* <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Address
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="department_address"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.department_address}
                                invalid={
                                validation.touched.department_address &&
                                    validation.errors.department_address
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.department_address &&
                                validation.errors.department_address ? (
                                <FormFeedback type="invalid">
                                {validation.errors.department_address}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row> */}

                      <h4>Emergency Contact</h4>

                      <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Contact Name
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="department_emergency_contact_name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.department_emergency_contact_name}
                                invalid={
                                validation.touched.department_emergency_contact_name &&
                                    validation.errors.department_emergency_contact_name
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.department_emergency_contact_name &&
                                validation.errors.department_emergency_contact_name ? (
                                <FormFeedback type="invalid">
                                {validation.errors.department_emergency_contact_name}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Mobile No
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="department_emergency_contact_mobile"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.department_emergency_contact_mobile}
                                invalid={
                                validation.touched.department_emergency_contact_mobile &&
                                    validation.errors.department_emergency_contact_mobile
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.department_emergency_contact_mobile &&
                                validation.errors.department_emergency_contact_mobile ? (
                                <FormFeedback type="invalid">
                                {validation.errors.department_emergency_contact_mobile}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Contact Email
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="department_emergency_contact_email"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.department_emergency_contact_email}
                                invalid={
                                validation.touched.department_emergency_contact_email &&
                                    validation.errors.department_emergency_contact_email
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.department_emergency_contact_email &&
                                validation.errors.department_emergency_contact_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.department_emergency_contact_email}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        {isEdit && 
                        <>
                        {/* <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Staffs Excluded from rosters
                            </Label>
                            <Col sm={9}>
                                <Select
                                    isMulti={true}
                                    value={StaffsExecluded}
                                    name="client_department"
                                    options={staffs_dropdown}
                                    onChange={(e) => setStaffsExecluded(e)}
                                    className="select2-selection"
                                    defaultValue={staffs_dropdown}
                                />
                            </Col>
                            
                        </Row> */}

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Staffs Excluded from rosters
                            </Label>
                            <Col sm={9}>
                                <Select
                                    isMulti={true}
                                    value={StaffsExecluded}
                                    name="client_department"
                                    options={staffsDropdown}
                                    onChange={(e) => setStaffsExecluded(e)}
                                    className="select2-selection"
                                    defaultValue={staffsDropdown}
                                    styles={{
                                      menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                />
                            </Col>
                            
                        </Row>

                        <Row className="mb-4">
                          <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                          >
                            Auto Report Send
                          </Label>
                          <Col sm={9}>
                                <Input
                                    type="checkbox"
                                    value="1"
                                    name="report_weekly"
                                    onChange={validation.handleChange}
                                    checked={validation.values.report_weekly == 1 ? true : false}
                                /> Weekly

                                { validation.values.report_weekly == 1 && 
                                <>
                                <Col sm={12}>
                                  <Input
                                      name="report_weekly_email"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.report_weekly_email}
                                      invalid={
                                        validation.touched.report_weekly_email &&
                                        validation.errors.report_weekly_email
                                        ? true
                                        : false
                                      }
                                  />
                                  <em>Add emails with (,) to send report to multiple emails</em>
                                </Col>

                                {validation.touched.report_weekly_email &&
                                validation.errors.report_weekly_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.report_weekly_email}
                                </FormFeedback>
                                ) : null}
                              </>
                              }
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                          >
                            
                          </Label>
                          <Col sm={9}>
                                <Input
                                    type="checkbox"
                                    value="1"
                                    name="report_monthly"
                                    onChange={validation.handleChange}
                                    checked={validation.values.report_monthly == 1 ? true : false}
                                /> Monthly

                                { validation.values.report_monthly  == 1 && 
                                <>

                                <Col sm={12}>
                                  <Input
                                      name="report_monthly_email"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.report_monthly_email}
                                      invalid={
                                        validation.touched.report_monthly_email &&
                                        validation.errors.report_monthly_email
                                        ? true
                                        : false
                                      }
                                  />
                                  <em>Add emails with (,) to send report to multiple emails</em>
                                </Col>

                                {validation.touched.report_monthly_email &&
                                  validation.errors.report_monthly_email ? (
                                  <FormFeedback type="invalid">
                                  {validation.errors.report_monthly_email}
                                  </FormFeedback>
                                  ) : null}

                                </>
                              }
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                          >
                            
                          </Label>
                          <Col sm={9}>
                                <Input
                                    type="checkbox"
                                    value="1"
                                    name="report_daily"
                                    onChange={validation.handleChange}
                                    checked={validation.values.report_daily == 1 ? true : false}
                                /> Daily

                                { validation.values.report_daily == 1 && 
                                <>
                                <Col sm={12}>
                                  <Input
                                      name="report_daily_email"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.report_daily_email}
                                      invalid={
                                        validation.touched.report_daily_email &&
                                        validation.errors.report_daily_email
                                        ? true
                                        : false
                                      }
                                  />
                                  <em>Add emails with (,) to send report to multiple emails</em>
                                </Col>

                                {validation.touched.report_daily_email &&
                                  validation.errors.report_daily_email ? (
                                  <FormFeedback type="invalid">
                                  {validation.errors.report_daily_email}
                                  </FormFeedback>
                                  ) : null}
                                  
                                </>

                                }
                          </Col>
                        </Row>
                        </>
                        }

                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                                disabled={ModalLoading}
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default Providers;