import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';
import { ACTION_URL, APP_URL, NO_CLOCKIN_ROSTERS_PAGE } from "helpers/url_helper"
import { toast } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";


import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle
} from "reactstrap";

import { Name, Status } from "components/Common/TableColumns"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"
// import RosterDetailsModal from "components/Forms/RostersDetailsModal"

function NoClockInAllRostersActive () {
  //meta title
  document.title="No Clock In Rosters | Tacveiha Panel";
  
  
    const loggedUser = JSON.parse(localStorage.getItem("authUser"));

    const dispatch = useDispatch();
    const [roster, setRoster] = useState([]);
    const [ModalLoading, setModalLoading] = useState(false);
    const [TableData, setTableData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [RosterNotesList, setRosterNotesList] = useState([]);

    //roster notes section
    const [RosterNotes, setRosterNotes] = useState('');
    const [addNotesModal, setAddNotesModal] = useState(false);

    useEffect(() => {

        setModalLoading(true);

        axios.post(APP_URL+ NO_CLOCKIN_ROSTERS_PAGE,{'data_for': 'load_no_clock_in_rosters','user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
            if( fetchedData.status == 'success' ) {
              toast.success(fetchedData.html, { autoClose: 5000 });
              localStorage.setItem('no_clockin_alerts_count',fetchedData.count)
              
              setTableData(fetchedData.data)
              // setRosterNotesList(fetchedData.rostersActivity);
            }
            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })

    },[dispatch])

    const columns = useMemo(
      () => [
        {
          Header: "#",
          accessor: "id",
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
            Header: "Roster ID",
            accessor: "roster_id",
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
        },
        {
            Header: "Date & Time",
            accessor: "date_time",
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
        },
        {
          Header: "Client Name",
          accessor: "client_name",
          //disableFilters: true, 
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Staff Name",
          accessor: "staff_name",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Roster Time",
          accessor: "roster_time",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Client Contact No",
          accessor: "client_number",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
            Header: "Staff Contact No",
            accessor: "staff_number",
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
        {
          Header: "Roster Status",
          accessor: "status",
          filterable: true,
          Cell: cellProps => {
            return <Status {...cellProps} />;
          },
        },
        {
            Header: "Notes",
            Cell: cellProps => {
              return (
                <div className="d-flex gap-3">

                  
                  <Link
                    to="#"
                    className="btn btn-sm btn-info"
                    onClick={() => {
                      const userData = cellProps.row.original;
                      addNotes(userData);
                    }}
                  >
                    <i className="mdi mdi-plus ms-1" id="edittooltip12" />
                    <UncontrolledTooltip placement="top" target="edittooltip12">
                      Add Notes
                    </UncontrolledTooltip>
                  </Link>
                </div>
              );
            },
          },
        {
          Header: "Action",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    markAsChecked(userData);
                  }}
                >
                  <i className="mdi mdi-eye ms-1" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Mark As Checked
                  </UncontrolledTooltip>
                </Link>
              </div>
            );
          },
        },
      ],
      []
  )

  const addNotes = (userData) => {
    const user = userData;
    setRoster({
      ros_id: user.roster_id
    })

    toggleAddNotesModal();

    const formData = {
      ros_id: user.roster_id
    }

    setModalLoading(true);

        axios.post(APP_URL+ NO_CLOCKIN_ROSTERS_PAGE,{'data_for': 'get_roster_activity','formData': formData, 'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
            if( fetchedData.status == 'success' ) {
              setRosterNotesList(fetchedData.rostersActivity);
            }
            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })

  }

  const toggleAddNotesModal = () => {
    if( addNotesModal ) {
      setAddNotesModal(false)
    } else {
      setAddNotesModal(true)
    }
  }

  const addRosterNotes = () => {

    
    if( RosterNotes == '' ) {
      toast.error("Please add notes", { autoClose: 5000 });
      return false;
    }
    
    const RosterNotesForm = {
        ros_id: roster.ros_id,
        rosterNotes: RosterNotes,
    }

    setModalLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'add_roster_notes','formData': RosterNotesForm,'user': loggedUser})
      .then(res => {
        setModalLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
          setRosterNotesList(RosterNotesList => [fetchedData.data,...RosterNotesList]);
          setRosterNotes('');
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
    })
  }

  const markAsChecked = (userData) => {

    const formData = {
      ros_id: userData.roster_id,
      id: userData.id,
    }

    setModalLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'clock_in_roster_mark_as_read','formData': formData,'user': loggedUser})
      .then(res => {
        setModalLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
          
          const updatedItems = TableData.filter((item) => item.id !== userData.id);
          setTableData(updatedItems);

        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
    })


  }


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  return (
    <React.Fragment>
      { ModalLoading ? <TopProgressBar loading/> : '' }
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem="No Clockin Rostes Active" />

          {/* Add Notes Modal */}
          <Modal isOpen={addNotesModal} toggle={toggleAddNotesModal} size="xl" backdrop="static" scrollable >
            <ModalHeader toggle={toggleAddNotesModal} tag="h4">
                Add Notes{ ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
            </ModalHeader>
            <ModalBody style={{ minHeight: '400px' }}>

              <Row>
                <Col md={8}>

                  <Row>
                      <Col xs={12}>
                          <div className="mb-3">
                          <Label className="form-label">Add Notes</Label>
                          <Input
                              name="roster_notes"
                              type="textarea"
                              onChange={e => {
                              
                              setRosterNotes(e.target.value);
                              }}
                              value={RosterNotes}
                          />
                          </div>
                      </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button onClick={addRosterNotes}
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Add
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={4}>
                  <CardTitle>Activiry Log</CardTitle>
                    <div className="order_notes_div">

                      <ul className="order_notes">
                          { RosterNotesList.map(activity => (
                          <li key={activity.id} rel={activity.id} className="note">
                              <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                              <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                              <strong>
                              {activity.activity_by}
                              </strong>
                              </abbr></p>
                          </li>
                          ))}
                      </ul>
                    </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          { ModalLoading ? <Spinners setLoading={setLoading} />
            :
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row className="mb-2">

                                <TableContainer
                                    columns={columns}
                                    data={TableData}
                                    isGlobalFilter={true}
                                    isDateSelecteOptions={true}
                                    showSearchBtn={true}
                                    customPageSize={25}
                                    className="custom-header-css"
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
              </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  );


}

export default NoClockInAllRostersActive;