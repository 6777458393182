import {
  GET_ALL_ROSTERS,
  GET_ALL_ROSTERS_FAIL,
  GET_ALL_ROSTERS_SUCCESS,
  GET_ROSTER_DETAIL,
  GET_ROSTER_DETAIL_FAIL,
  GET_ROSTER_DETAIL_SUCCESS,
  GET_TODAYS_ROSTERS,
  GET_TODAYS_ROSTERS_SUCCESS,
  GET_TODAYS_ROSTERS_FAIL,
  GET_GROUP_ROSTERS,
  GET_GROUP_ROSTERS_SUCCESS,
  GET_GROUP_ROSTERS_FAIL,
  ADD_GROUP_ROSTER,
  ADD_GROUP_ROSTER_SUCCESS,
  ADD_GROUP_ROSTER_FAIL,
  UPDATE_GROUP_ROSTER,
  UPDATE_GROUP_ROSTER_SUCCESS,
  UPDATE_GROUP_ROSTER_FAIL,
  ADD_ROSTERS_DATA,
  ADD_ROSTERS_DATA_SUCCESS,
  ADD_ROSTERS_DATA_FAIL,
  UPDATE_TODAYS_ROSTER,
  UPDATE_ROSTERS_TABLE,
} from "./actionTypes"

export const getRosters = (params,types) => ({
  type: GET_ALL_ROSTERS,
  params,
  types,
})

export const getRostersSuccess = allRosters => ({
  type: GET_ALL_ROSTERS_SUCCESS,
  payload: allRosters,
})

export const getRostersFail = error => ({
  type: GET_ALL_ROSTERS_FAIL,
  payload: error,
})

export const getRostersDetail = invoiceId => ({
  type: GET_ROSTER_DETAIL,
  invoiceId,
})

export const getRostersDetailSuccess = invoices => ({
  type: GET_ROSTER_DETAIL_SUCCESS,
  payload: invoices,
})

export const getRostersDetailFail = error => ({
  type: GET_ROSTER_DETAIL_FAIL,
  payload: error,
})

export const getTodaysRostes = (params,types) => ({
  type: GET_TODAYS_ROSTERS,
  params,
  types
})

export const getTodaysRostesSuccess = todaysRosters => ({
  type: GET_TODAYS_ROSTERS_SUCCESS,
  payload: todaysRosters,
})

export const getTodaysRostesFail = error => ({
  type: GET_TODAYS_ROSTERS_FAIL,
  payload: error,
})

export const getGroupRosters = (params,types) => ({
  type: GET_GROUP_ROSTERS,
  params,
  types
})

export const getGroupRostesSuccess = data => ({
  type: GET_GROUP_ROSTERS_SUCCESS,
  payload: data,
})

export const getGroupRostesFail = error => ({
  type: GET_GROUP_ROSTERS_FAIL,
  payload: error,
})

export const addNewGroupRoster = (params,types) => ({
  type: ADD_GROUP_ROSTER,
  payload: params,
  types
})

export const addNewGroupRosterSuccess = data => ({
  type: ADD_GROUP_ROSTER_SUCCESS,
  payload: data,
})

export const addNewGroupRosterFail = error => ({
  type: ADD_GROUP_ROSTER_FAIL,
  payload: error,
})


export const updateGroupRoster = (params,types) => ({
  type: UPDATE_GROUP_ROSTER,
  payload: params,
  types
})

export const updateGroupRosterSuccess = data => ({
  type: UPDATE_GROUP_ROSTER_SUCCESS,
  payload: data,
})

export const updateGroupRosterFail = error => ({
  type: UPDATE_GROUP_ROSTER_FAIL,
  payload: error,
})

export const addRostersData = (params,types) => ({
  type: ADD_ROSTERS_DATA,
  payload: params,
  types
})

export const addRostersDataSuccess = data => ({
  type: ADD_ROSTERS_DATA_SUCCESS,
  payload: data,
})

export const addRostersDataFail = error => ({
  type: ADD_ROSTERS_DATA_FAIL,
  payload: error,
})

export const updateRostersTable = data => ({
  type: UPDATE_ROSTERS_TABLE,
  payload: data,
})

export const updateTodaysRosters = data => ({
  type: UPDATE_TODAYS_ROSTER,
  payload: data,
})