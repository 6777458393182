import {
  GET_ALL_ROSTERS_FAIL,
  GET_ALL_ROSTERS_SUCCESS,
  GET_ROSTER_DETAIL_SUCCESS,
  GET_ROSTER_DETAIL_FAIL,
  GET_ALL_ROSTERS,
  GET_TODAYS_ROSTERS,
  GET_TODAYS_ROSTERS_SUCCESS,
  GET_TODAYS_ROSTERS_FAIL,
  GET_GROUP_ROSTERS,
  GET_GROUP_ROSTERS_SUCCESS,
  GET_GROUP_ROSTERS_FAIL,
  ADD_GROUP_ROSTER,
  ADD_GROUP_ROSTER_SUCCESS,
  ADD_GROUP_ROSTER_FAIL,
  UPDATE_GROUP_ROSTER,
  UPDATE_GROUP_ROSTER_SUCCESS,
  UPDATE_GROUP_ROSTER_FAIL,
  ADD_ROSTERS_DATA,
  ADD_ROSTERS_DATA_SUCCESS,
  ADD_ROSTERS_DATA_FAIL,
  UPDATE_TODAYS_ROSTER,
  UPDATE_ROSTERS_TABLE
} from "./actionTypes"

const INIT_STATE = {
  rostersTable: [],
  todaysRosters: [],
  rosterDetails: {},
  groupRosters: [],
  rostersClients:[],
  rostersStaffs:[],
  payCategory:[],
  supportTypes:[],
  nursingSupportTypes:[],
  groupRosterDropdown:[],
  broadcastStaffsDrowdown:[],
  error: {},
  loading: false,
}

const RostersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_ROSTERS:
      console.log('loading');
      return {
        ...state,
        rostersTable: [],
        loading: true
     }
    case GET_ALL_ROSTERS_SUCCESS:
      console.log('success');
      return {
        ...state,
        rostersTable: action.payload.allRosters,
        loading: false
     }

    case GET_ALL_ROSTERS_FAIL:
      console.log('failed');
      return {
        ...state,
        error: action.payload,

      }

    case GET_ROSTER_DETAIL_SUCCESS:
      return {
        ...state,
        rosterDetails: action.payload,
        loading: false
      }

    case GET_ROSTER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload.error,
      }

      case GET_TODAYS_ROSTERS:
      console.log('todays rostes');
      return {
        ...state,
        todaysRosters: state.todaysRosters,
        loading: true
     }
    case GET_TODAYS_ROSTERS_SUCCESS:
      console.log('success');
      return {
        ...state,
        todaysRosters: action.payload.allRosters,
        loading: false
     }

    case GET_TODAYS_ROSTERS_FAIL:
      console.log('failed');
      return {
        ...state,
        error: action.payload.error,
      }

      case GET_GROUP_ROSTERS:
      console.log('group rostes');
      return {
        ...state,
        groupRosters: [],
        loading: true
     }
    case GET_GROUP_ROSTERS_SUCCESS:
      console.log('group rosters success');
      return {
        ...state,
        groupRosters: action.payload.groupRosters,
        loading: false
     }

    case GET_GROUP_ROSTERS_FAIL:
      console.log('failed');
      return {
        ...state,
        error: action.payload.error,
      }
      case ADD_GROUP_ROSTER:
        return {
            ...state,
            loading: true
      };
      case ADD_GROUP_ROSTER_SUCCESS:
        return {
          ...state,
          groupRosters: [...state.groupRosters, action.payload.groupRosters],
          loading: false
      };
      case ADD_GROUP_ROSTER_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
      };

      case UPDATE_GROUP_ROSTER:
        return {
          ...state,
          loading: true
      };

      case UPDATE_GROUP_ROSTER_SUCCESS:
        return {
            ...state,
            groupRosters: state.groupRosters.map(rosters =>
              rosters.id.toString() === action.payload.groupRosters.id.toString()
                    ? { rosters, ...action.payload.groupRosters }
                    : rosters
            ),
            loading: false
        };

    case UPDATE_GROUP_ROSTER_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        };
    case ADD_ROSTERS_DATA:
      console.log('loading');
      return {
        rostersClients: state.rostersClients,
        rostersStaffs: state.rostersStaffs,
        payCategory: state.payCategory,
        supportTypes: state.supportTypes,
        broadcastStaffs: state.broadcastStaffs,
        groupRosterDropdown: state.groupRosterDropdown,
        loading: true
      }
      case ADD_ROSTERS_DATA_SUCCESS:
        console.log('success');
        return {
          ...state,
          rostersClients: action.payload.rostersClients,
          rostersStaffs: action.payload.rostersStaffs,
          payCategory: action.payload.payCategory,
          supportTypes: action.payload.supportTypes,
          broadcastStaffs: action.payload.broadcastStaffs,
          groupRosterDropdown: action.payload.groupRosterDropdown,
          loading: false
        }
    
      case ADD_ROSTERS_DATA_FAIL:
        console.log('failed');
        return {
          ...state,
          error: action.payload,
        }

      case UPDATE_ROSTERS_TABLE:
        return {
          ...state,
          rostersTable: state.rostersTable.filter(rosters =>
            !action.payload.includes(rosters.ros_id)
          ),
          loading: false
      }

      case UPDATE_TODAYS_ROSTER:
        return {
          ...state,
          todaysRosters: state.todaysRosters.filter(rosters =>
            !action.payload.includes(rosters.ros_id)
          ),
          loading: false
      }
          
    default:
      return state
  }
}

export default RostersReducer
