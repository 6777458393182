import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, isWeakMap, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';
import { ACTION_URL, APP_URL, ADMIN_URL, GET_STAFFS } from "helpers/url_helper"
import {Name, Email, Tags, Projects, Status } from "../../../components/Common/TableColumns";
import Select from "react-select";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";

import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  InputGroup,
  CardTitle,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


const libraries =['places'];

//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getCsmStaffs as onGetCsmStaff,
    addCsmStaffs as onAddCsmStaffs,
    updateCsmStaff as onUpdateCsmStaff
} from "store/actions";

function CsmStaffs(){
  //meta title
  document.title="CSM Staffs| Tacveiha Panel";

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries
  });

  // console.log(getLoggedInUser );

  const [ModalLoading, setModalLoading] = useState(false);
  
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');

  const [providersDropdown, setProvidersDropdown] = useState([]);
  const [staffsProviders, setStaffsProviders] = useState([]);
  const [StaffDob, setStaffDob] = useState('');
  
  const [StaffAddress, setStaffAddress] = useState('');
  const [StaffLatLng, setStaffLatLng] = useState('');
  
  const [roster, setRoster] = useState(null);
  const [Loading, setLoading] = useState(false);
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));
  const [selectedFiles, setselectedFiles] = useState([]);
  const [profileCover, setProfileCover] = useState();


  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.StaffsReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        csmStaffsList: rosterReducer.csmStaffs,
        staffsProvidersList: rosterReducer.staffsProviders,
        loading: rosterReducer.loading
      })
  );
  const {
    csmStaffsList, staffsProvidersList, loading
  } = useSelector(groupRostersProperties);



  useEffect(() => {
    //if (isEmpty(todaysRosters)) {
      //if (csmStaffsList && !csmStaffsList.length) {
      dispatch(onGetCsmStaff({'data_for': 'get_csm_staffs','user': loggedUser}));
      //}
    //}
  }, [dispatch]);
  

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      user_status : ( roster && roster.user_status ) || "",
      // department_id: ( roster && roster.providers ) || "",
      user_title : ( roster && roster.user_title ) || "",
      employee_id : ( roster && roster.employee_id ) || "",
      staff_fname : ( roster && roster.staff_fname ) || "",
      staff_lname : ( roster && roster.staff_lname ) || "",
      staff_email : ( roster && roster.staff_email ) || "",
      staff_password : "",
      staff_confirm_password : "",
      staff_gender : ( roster && roster.staff_gender ) || "",
      staff_dob : ( roster && roster.staff_dob ) || "",
      staff_mobile_no : ( roster && roster.staff_mobile_no ) || "",
      staff_home_no : ( roster && roster.staff_home_no ) || "",
      staff_language : ( roster && roster.staff_language ) || "",
      staff_address : ( roster && roster.staff_address ) || "",
      staff_latlng : ( roster && roster.staff_latlng ) || "",
      profile_cover : ( roster && roster.profile_cover ) || "",
      staff_emergency_contact_name : ( roster && roster.staff_emergency_contact_name ) || "",
      staff_emergency_contact_relation : ( roster && roster.staff_emergency_contact_relation ) || "",
      staff_emergency_contact_mobile : ( roster && roster.staff_emergency_contact_mobile ) || "",
      staff_emergency_contact_email : ( roster && roster.staff_emergency_contact_email ) || "",
      sms_auth : ( roster && roster.sms_auth ) || 0,
      email_auth : ( roster && roster.email_auth ) || 0,
      auth_email_address: ( roster && roster.auth_email_address ) || "",
    },
    validationSchema: Yup.object({
      user_status: Yup.string().required("Required"),
      employee_id : Yup.string().required("Required"),
      user_title: Yup.string().required("Required"),
      staff_fname: Yup.string().required("Required"),
      staff_lname: Yup.string().required("Required"),
      staff_email: Yup.string().required("Required"),
      staff_gender: Yup.string().required("Required"),
      staff_mobile_no: Yup.string().required("Required"),
      staff_password: Yup.string(),
      staff_confirm_password: Yup.string().oneOf([Yup.ref('staff_password'),null],'Passwords must match')
      // staff_home_no: Yup.string().required("Required"),
      // staff_language: Yup.string().required("Required"),
      // staff_address: Yup.string().required("Required"),
      // staff_postcode: Yup.string().required("Required"),
      // staff_emergency_contact_name : Yup.string().required("Required"),
      // staff_emergency_contact_relation: Yup.string().required("Required"),
      // staff_emergency_contact_mobile : Yup.string().required("Required"),
      // staff_emergency_contact_email : Yup.string().required("Required"),
    }),
    onSubmit: values => {

      // console.log(values);

      // console.log(roster);
      
      if (isEdit) {

        if( StaffDob == "" ) {
          toast.error("Please provide staff date of birth", { autoClose: 5000 });
          return false;
        }

        if( values.email_auth == 1 &&  values.auth_email_address == '' ) {
          toast.error("Please provide authentication email address", { autoClose: 5000 });
          return false;
        }

        const updateUser = {
          user_id: roster.id,
          user_status : values.user_status,
          department_id: staffsProviders,
          user_title: values.user_title,
          employee_id: values.employee_id,
          staff_fname: values.staff_fname,
          staff_lname: values.staff_lname,
          staff_email: values.staff_email,
          staff_password: values.staff_password,
          staff_dob: StaffDob,
          profile_pic: profileCover,
          staff_gender: values.staff_gender,
          staff_mobile_no: values.staff_mobile_no,
          staff_home_no: values.staff_home_no,
          staff_language: values.staff_language,
          staff_address: values.staff_address,
          staff_latlng: values.staff_latlng,
          staff_emergency_contact_name: values.staff_emergency_contact_name,
          staff_emergency_contact_relation: values.staff_emergency_contact_relation,
          staff_emergency_contact_mobile: values.staff_emergency_contact_mobile,
          staff_emergency_contact_email: values.staff_emergency_contact_email,
          sms_auth: values.sms_auth,
          email_auth: values.email_auth,
          auth_email_address: values.auth_email_address,
        };

        // console.log(updateUser);
        // console.log(loading);
        // return false;

        // update user
        // dispatch(onUpdateCsmStaff('update_csm_staff',updateUser,loggedUser));
        // validation.resetForm();
        // setIsEdit(false);
      } else {

        if( staffsProviders.length == 0 ) {
          toast.error("Please provide providers", { autoClose: 5000 });
          return false;
        }

        // const formData = new FormData();
        // formData.append('file', profileCover);
        // formData.append('fileName', profileCover.name);
        
        const newData = {
          user_status : values.user_status,
          department_id: staffsProviders,
          user_title: values.user_title,
          employee_id: values.employee_id,
          staff_fname: values.staff_fname,
          staff_lname: values.staff_lname,
          staff_email: values.staff_email,
          staff_password: values.staff_password,
          staff_dob: StaffDob,
          staff_gender: values.staff_gender,
          staff_mobile_no: values.staff_mobile_no,
          staff_home_no: values.staff_home_no,
          staff_language: values.staff_language,
          staff_address: StaffAddress,
          staff_latlng: StaffLatLng,
          profile_pic: profileCover,
          staff_emergency_contact_name: values.staff_emergency_contact_name,
          staff_emergency_contact_relation: values.staff_emergency_contact_relation,
          staff_emergency_contact_mobile: values.staff_emergency_contact_mobile,
          staff_emergency_contact_email: values.staff_emergency_contact_email,
          sms_auth: values.sms_auth,
          email_auth :values.email_auth,
          auth_email_address :values.auth_email_address,
        };
        // save new user
        dispatch(onAddCsmStaffs('add_csm_staff',newData,loggedUser));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster(null);
    } else {
        setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg;
    const formData = {
        user_id: user.user_id
    }

    setModalLoading(true);
    axios.post(APP_URL+GET_STAFFS,{'data_for':'get_csm_staff_details','formData':formData,'user':loggedUser})
    .then(res => {
      setModalLoading(false);
      const fetchedData = res.data;

      setRoster({
        id: fetchedData.userRow.user_id,
        user_status : fetchedData.userRow.user_status,
        user_title: fetchedData.userRow.user_title,
        employee_id: fetchedData.userRow.employee_id,
        staff_fname: fetchedData.userRow.staff_fname,
        staff_lname: fetchedData.userRow.staff_lname,
        staff_email: fetchedData.userRow.user_email,
        staff_dob: fetchedData.userRow.staff_dob,
        staff_gender: fetchedData.userRow.staff_gender,
        staff_mobile_no: fetchedData.userRow.staff_mobile_no,
        staff_home_no: fetchedData.userRow.staff_home_no,
        staff_language: fetchedData.userRow.staff_language,
        staff_address: fetchedData.userRow.staff_address,
        staff_latlng: fetchedData.userRow.staff_latlng,
        staff_emergency_contact_name: fetchedData.userRow.staff_emergency_contact_name,
        staff_emergency_contact_relation: fetchedData.userRow.staff_emergency_contact_relation,
        staff_emergency_contact_mobile: fetchedData.userRow.staff_emergency_contact_mobile,
        staff_emergency_contact_email: fetchedData.userRow.staff_emergency_contact_email,
        sms_auth: fetchedData.userRow.sms_auth,
        email_auth: fetchedData.userRow.email_auth,
        auth_email_address :fetchedData.userRow.auth_email_address,
      });
      setStaffDob(fetchedData.userRow.staff_dob);
      // setProvidersDropdown(fetchedData.departments);
      setStaffsProviders(fetchedData.staffsProviders);
    })

    
    setIsEdit(true);
    toggle();
  };

  const handleUserClicks = () => {
    setRoster("");
    setIsEdit(false);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = roster => {
    setRoster(roster);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    //dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleFromDate = value => {
    // console.log(value);
    setFromDate(value);
  }

  const handleToDate = value => {
    setToDate(value);
  }


  const handlePlaceChanged = () => { 
    const [ place ] = inputRef.current.getPlaces();
    if(place) { 
        validation.values.staff_address = place.formatted_address;
        setStaffAddress(place.formatted_address);
        setStaffLatLng(place.geometry.location.lat()+','+place.geometry.location.lng());
    }
  }

  function handleCoverChange(e) {
    // console.log(e.target.files);
    setProfileCover(URL.createObjectURL(e.target.files[0]));
}


  const keyField = "id";

  const columns = useMemo(
    () => [
      {
        Header: "Staff Name",
        accessor: "staff_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "user_email",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Mobile No",
        accessor: "staff_mobile_no",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Address",
        accessor: "staff_address",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Csm Staffs" breadcrumbItem=" Manage Csm Staff Accounts" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <>
                    <TableContainer
                      columns={columns}
                      data={csmStaffsList}
                      isGlobalFilter={true}
                      isDateSelecteOptions={true}
                      showSearchBtn={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={50}
                      className="custom-header-css"
                      isAddBtn={true}
                      btnText={'Add CSM Staff'}
                    />
                  </>
                }
                  <Modal isOpen={modal} toggle={toggle} size="xl" backdrop="static" scrollable>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Csm Staff" : "Add Csm Staff "}
                    </ModalHeader>
                    <ModalBody>

                    {
                    ModalLoading ? <Spinners setLoading={setLoading} />
                    :
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

                    <Row className="mb-4">

                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                        Account Status
                    </Label>
                    <Col sm={9}>
                            <Input
                                name="user_status"
                                type="radio"
                                onChange={validation.handleChange}
                                value='1'
                                invalid={
                                    validation.touched.user_status &&
                                    validation.errors.user_status
                                    ? true
                                    : false
                                }
                                checked={validation.values.user_status == 1 ? true: false}
                            /> &nbsp;&nbsp;Active
                            &nbsp;&nbsp;<Input
                                name="user_status"
                                type="radio"
                                onChange={validation.handleChange}
                                value='0'
                                invalid={
                                validation.touched.user_status &&
                                    validation.errors.user_status
                                    ? true
                                    : false
                                }
                                checked={validation.values.user_status == 0 ? true: false}
                            />&nbsp;&nbsp;InActive
                            {validation.touched.user_status &&
                            validation.errors.user_status ? (
                            <FormFeedback type="invalid">
                            {validation.errors.user_status}
                            </FormFeedback>
                            ) : null}
                        </Col>

                    </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Providers
                            </Label>
                            <Col sm={9}>
                                <Select
                                    isMulti={true}
                                    value={staffsProviders}
                                    name="department_id"
                                    options={staffsProvidersList}
                                    onChange={(e) => setStaffsProviders(e)}
                                    className="select2-selection"
                                    defaultValue={staffsProviders}
                                    styles={{
                                      // Fixes the overlapping problem of the component
                                      menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Employeed ID
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="employee_id"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.employee_id}
                                invalid={
                                validation.touched.employee_id &&
                                    validation.errors.employee_id
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.employee_id &&
                                validation.errors.employee_id ? (
                                <FormFeedback type="invalid">
                                {validation.errors.employee_id}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>
                        
                        <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Title
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Mr'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.user_title == 'Mr' ? true: false}
                                /> &nbsp;&nbsp;Mr
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Mrs'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.user_title == 'Mrs' ? true: false}
                                />&nbsp;&nbsp;Mrs
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Miss'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.user_title == 'Miss' ? true: false}
                                />&nbsp;&nbsp;Miss
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Ms'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.user_title == 'Ms' ? true: false}
                                />&nbsp;&nbsp;Ms
                                {validation.touched.user_title &&
                                validation.errors.user_title ? (
                                <FormFeedback type="invalid">
                                {validation.errors.user_title}
                                </FormFeedback>
                                ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                First Name
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_fname"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_fname}
                                invalid={
                                validation.touched.staff_fname &&
                                    validation.errors.staff_fname
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_fname &&
                                validation.errors.staff_fname ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_fname}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Last Name
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_lname"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_lname}
                                invalid={
                                validation.touched.staff_lname &&
                                    validation.errors.staff_lname
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_lname &&
                                validation.errors.staff_lname ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_lname}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Staff Email
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_email"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_email}
                                invalid={
                                    validation.touched.staff_email &&
                                    validation.errors.staff_email
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_email &&
                                validation.errors.staff_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_email}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_password"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_password}
                                invalid={
                                    validation.touched.staff_password &&
                                    validation.errors.staff_password
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_password &&
                                validation.errors.staff_password ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_password}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                               Re-type Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_confirm_password"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_confirm_password}
                                invalid={
                                    validation.touched.staff_confirm_password &&
                                    validation.errors.staff_confirm_password
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_confirm_password &&
                                validation.errors.staff_confirm_password ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_confirm_password}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>


                        <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Staff Gender
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="staff_gender"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Male'
                                    invalid={
                                        validation.touched.staff_gender &&
                                        validation.errors.staff_gender
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.staff_gender == 'Male' ? true : false }
                                /> &nbsp;&nbsp;Male
                                &nbsp;&nbsp;<Input
                                    name="staff_gender"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Female'
                                    invalid={
                                        validation.touched.staff_gender &&
                                        validation.errors.staff_gender
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.staff_gender == 'Female' ? true : false }
                                />&nbsp;&nbsp;Female
                                
                                {validation.touched.staff_gender &&
                                validation.errors.staff_gender ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_gender}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Staff DOB
                            </Label>
                            <Col sm={9}>
                                <InputGroup>
                                <Flatpickr
                                    name="staff_dob"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setStaffDob(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_dob || StaffDob}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                                
                            </Col>

                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Mobile No
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_mobile_no"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_mobile_no}
                                invalid={
                                validation.touched.staff_mobile_no &&
                                    validation.errors.staff_mobile_no
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_mobile_no &&
                                validation.errors.staff_mobile_no ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_mobile_no}
                                </FormFeedback>
                            ) : null}
                            </Col>
                            
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Home Contact No
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_home_no"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_home_no}
                                invalid={
                                validation.touched.staff_home_no &&
                                    validation.errors.staff_home_no
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_home_no &&
                                validation.errors.staff_home_no ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_home_no}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Language Spoken
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_language"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_language}
                                invalid={
                                validation.touched.staff_language &&
                                    validation.errors.staff_language
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_language &&
                                validation.errors.staff_language ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_language}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Address
                            </Label>
                            <Col sm={9}>

                            <StandaloneSearchBox
                                // onLoad={ref => inputRef.current = ref}
                                onPlacesChanged={handlePlaceChanged}
                            >
                                <input
                                    name="staff_address"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Location"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={ validation.values.staff_address }
                                />
                            </StandaloneSearchBox>
                            </Col>
                            {validation.touched.staff_address &&
                                validation.errors.staff_address ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_address}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        {/* <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Profile Cover
                            </Label>
                            <Col sm={9}>
                            <Input
                                type="file"
                                onChange={handleCoverChange}
                                accept='.jpg, .png|image/*'
                            />
                            <img src={profileCover}  height={110}/>
                            
                            </Col>
                        
                        </Row> */}

                        <CardTitle>Emergency Contact</CardTitle>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Name
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_emergency_contact_name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_emergency_contact_name}
                                invalid={
                                validation.touched.staff_emergency_contact_name &&
                                    validation.errors.staff_emergency_contact_name
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_emergency_contact_name &&
                                validation.errors.staff_emergency_contact_name ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_emergency_contact_name}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Relation
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="staff_emergency_contact_relation"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_emergency_contact_relation}
                                    invalid={
                                        validation.touched.staff_emergency_contact_relation &&
                                        validation.errors.staff_emergency_contact_relation
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.staff_emergency_contact_relation &&
                                validation.errors.staff_emergency_contact_relation ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_emergency_contact_relation}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Mobile No
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="staff_emergency_contact_mobile"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_emergency_contact_mobile}
                                    invalid={
                                        validation.touched.staff_emergency_contact_mobile &&
                                        validation.errors.staff_emergency_contact_mobile
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.staff_emergency_contact_mobile &&
                                validation.errors.staff_emergency_contact_mobile ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_emergency_contact_mobile}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Email
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="staff_emergency_contact_email"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_emergency_contact_email}
                                    invalid={
                                        validation.touched.staff_emergency_contact_email &&
                                        validation.errors.staff_emergency_contact_email
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.staff_emergency_contact_email &&
                                validation.errors.staff_emergency_contact_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_emergency_contact_email}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Sms Authentication
                            </Label>
                            <Col sm={9}>
                              <Input
                                name="sms_auth"
                                type="radio"
                                onChange={validation.handleChange}
                                value='1'
                                invalid={
                                    validation.touched.sms_auth &&
                                    validation.errors.sms_auth
                                    ? true
                                    : false
                                }
                                checked={validation.values.sms_auth == 1 ? true: false}
                              /> &nbsp;&nbsp;Yes
                                &nbsp;&nbsp;
                                <Input
                                  name="sms_auth"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='0'
                                  invalid={
                                  validation.touched.sms_auth &&
                                      validation.errors.sms_auth
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.sms_auth == 0 ? true: false}
                                />&nbsp;&nbsp;No
                              {validation.touched.sms_auth &&
                              validation.errors.sms_auth ? (
                              <FormFeedback type="invalid">
                              {validation.errors.sms_auth}
                              </FormFeedback>
                              ) : null}
                          </Col>
                            
                        </Row>
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Email Authentication
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="email_auth"
                                type="radio"
                                onChange={validation.handleChange}
                                value='1'
                                invalid={
                                    validation.touched.email_auth &&
                                    validation.errors.email_auth
                                    ? true
                                    : false
                                }
                                checked={validation.values.email_auth == 1 ? true: false}
                              /> &nbsp;&nbsp;Yes
                                &nbsp;&nbsp;
                                <Input
                                  name="email_auth"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='0'
                                  invalid={
                                  validation.touched.email_auth &&
                                      validation.errors.email_auth
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.email_auth == 0 ? true: false}
                                />&nbsp;&nbsp;No
                              {validation.touched.email_auth &&
                              validation.errors.email_auth ? (
                              <FormFeedback type="invalid">
                              {validation.errors.email_auth}
                              </FormFeedback>
                              ) : null}
                            </Col>
                            
                        </Row>

                        {  validation.values.email_auth == 1  && (
                            <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Provide Authentication Email
                            </Label>
                            <Col sm={9}>
                            <Input
                                    name="auth_email_address"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.auth_email_address}
                                    // invalid={
                                    //     validation.touched.client_emergency_contact_email &&
                                    //     validation.errors.client_emergency_contact_email
                                    //     ? true
                                    //     : false
                                    // }
                                />
                                
                            </Col>

                        </Row>
                        )}

                        {/* documents and files uplaod */}
                        { !!isEdit ? (
                          <></>      
                        ) : null }

                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    }
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default CsmStaffs;