import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import axios from 'axios';
import { toast , ToastContainer} from "react-toastify";
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { ACTION_URL, APP_URL, GET_ALERTS_PAGE, SITE_URL } from "helpers/url_helper"
import Spinners from "components/Common/Spinner";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle
  } from "reactstrap";


  const RosterDetailsModal = ({ros_id, page, show, onCloseModal}) => {

    const verified_options = [
        {'label' : 'Select Status', value:'0'},
        {'label' : 'Verified', value:'1'},
        {'label' : 'Escalate due Admin Work', value:'5'},
        {'label' : 'Escalate due to Care Management', value:'7'},
        {'label' : 'Service Manager', value:'9'}
    ]

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    const [ModalLoading, setModalLoading] = useState(false);
    const [roster, setRoster] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [RostersDetails, setRostersDetails] = useState([]);

    const [adminVerifiedStatus, setAdminVerifiedStatus] = useState(verified_options);

    const [payCat, setPayCat] = useState('');
    const [supportType, setSupportType] = useState('');
    const [verifiedStatus, setVerifiedStatus] = useState([]);
    const [RosterNotesList, setRosterNotesList] = useState([]);
    const [supportTypeDropdown, setSupportTypeDropdown] = useState([]);
    const [payCategoryDropdown, setPayCategoryDropdown] = useState([]);
    const [sendReportChecked, setSendReportChecked] = useState(false)


    //roster notes section
    const [RosterNotes, setRosterNotes] = useState('');

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
            ros_id: ( roster && roster.ros_id) || "",
            start_date: ( roster && roster.start_date) || "",
            start_time: ( roster && roster.start_time) || "",
            finish_time: ( roster && roster.finish_time) || "",
            actual_start_time: ( roster && roster.actual_start_time) || "",
            actual_finish_time: ( roster && roster.actual_finish_time) || "",
            client_name: ( roster && roster.client_name) || "",
            staff_name: ( roster && roster.staff_name) || "",
            odometer_start: ( roster && roster.odometer_start) || "",
            odometer_end: ( roster && roster.odometer_end) || "",
            roster_kms: ( roster && roster.roster_kms) || "",
            roster_kms_for: ( roster && roster.roster_kms_for) || "",
            roster_parking_fee: ( roster && roster.roster_parking_fee) || "",
            roster_extra_details: ( roster && roster.roster_extra_details) || "",
            different_location_text: ( roster && roster.different_location_text) || "",
            client_emergency_contact_email: ( roster && roster.client_emergency_contact_email) || "",
            transport_inst: ( roster && roster.transport_inst) || "",
        },
        validationSchema: Yup.object({
            // actual_start_time: Yup.string().required("Please enter actual start time"),
            // actual_finish_time: Yup.string().required("Please enter actual finish time"),
        }),
        onSubmit: values => {

          // console.log(values);

          // console.log(verifiedStatus);
            
    
            if( verifiedStatus == '' || verifiedStatus == 0 ) {
              toast.error("Please select roster verified status", { autoClose: 5000 });
              return false;
            }

            const updateUser = {
                ros_id: values.ros_id,
                start_time: values.start_time,
                finish_time: values.finish_time,
                actual_start_time: values.actual_start_time,
                actual_finish_time: values.actual_finish_time,
                roster_kms_for: values.roster_kms_for,
                odometer_start: values.odometer_start,
                odometer_end: values.odometer_end,
                roster_kms: values.roster_kms,
                admin_notes_verified: values.admin_notes_verified,
                admin_followup_notes: values.admin_followup_notes,
                ros_support_type: supportType,
                roster_parking_fee: values.roster_parking_fee,
                roster_staff_notes_verified: values.roster_extra_details,
                roster_sub_admin_verified_status: verifiedStatus ? verifiedStatus : 0,
                send_roster_report_admin: sendReportChecked ? 1 : 0,
                transport_inst: values.transport_inst,
            };

            setModalLoading(true);
            axios.post(APP_URL+ACTION_URL,{'data_for':'admin_staff_edit_rosters','formData': updateUser,'user': loggedUser})
            .then(res => {
                setModalLoading(false);
                const fetchedData = res.data;

                if( fetchedData.status == 'success' ) {
                    toast.success(fetchedData.html, { autoClose: 5000 });
                    setRosterNotesList(RosterNotesList => [fetchedData.rosterActivity,...RosterNotesList]);
                    location.reload();
                }

                if( fetchedData.status == 'error' ) {
                    toast.error(fetchedData.html, { autoClose: 5000 });
                }
            })
            
        },
    })

    useEffect(() => {
        if( show ) { 
          setRoster([]);
          setRosterNotesList([]);
            const formData = {
                ros_id : ros_id
            }
            setModalLoading(true);
            axios.post(APP_URL+GET_ALERTS_PAGE,{'data_for':'load_admin_alert_roster_details', 'formData': formData, 'user': loggedUser})
            .then(res => {
                setModalLoading(false);
                const fetchedData = res.data;

                setPayCategoryDropdown(fetchedData.pay_category_dropdown);
                setSupportTypeDropdown(fetchedData.support_type_dropdown);

                setPayCat({label:fetchedData.rosterDetails.pay_category_label,value:fetchedData.rosterDetails.pay_category_value})
                setSupportType({label:fetchedData.rosterDetails.ros_support_type_label,value:fetchedData.rosterDetails.ros_support_type_value})
                setRoster({
                    ros_id: fetchedData.rosterDetails.ros_id,
                    start_date: fetchedData.rosterDetails.start_date,
                    start_time: fetchedData.rosterDetails.start_time,
                    finish_time: fetchedData.rosterDetails.finish_time,
                    actual_start_time: fetchedData.rosterDetails.actual_start_time,
                    actual_finish_time: fetchedData.rosterDetails.actual_finish_time,
                    client_name: fetchedData.rosterDetails.client_name,
                    staff_name: fetchedData.rosterDetails.staff_name,
                    odometer_start: fetchedData.rosterDetails.odometer_start,
                    odometer_end: fetchedData.rosterDetails.odometer_end,
                    roster_kms: fetchedData.rosterDetails.roster_kms,
                    roster_kms_for: fetchedData.rosterDetails.roster_kms_for,
                    roster_parking_fee: fetchedData.rosterDetails.roster_parking_fee,
                    ros_support_type: fetchedData.rosterDetails.ros_support_type,
                    roster_extra_details: fetchedData.rosterDetails.roster_extra_details,
                    different_location_text: fetchedData.rosterDetails.different_location_text,
                    client_emergency_contact_email: fetchedData.rosterDetails.client_emergency_contact_email,
                    transport_inst: fetchedData.rosterDetails.transport_inst,
                })

                setVerifiedStatus(fetchedData.rosterDetails.roster_sub_admin_verified_status);
                setRosterNotesList(fetchedData.rosterActivity)
            })
        }
    }, [show])

    const handleVerifiedStatus = (e) => {
        setVerifiedStatus(e.value);
        // console.log(e.value)
    }

    const addRosterNotes = () => {

    
        if( RosterNotes == '' ) {
          toast.error("Please add notes", { autoClose: 5000 });
          return false;
        }
        
        const RosterNotesForm = {
            ros_id: roster.ros_id,
            rosterNotes: RosterNotes,
        }
    
        setModalLoading(true);
        axios.post(APP_URL+ACTION_URL,{'data_for':'add_roster_notes','formData': RosterNotesForm,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
    
            if( fetchedData.status == 'success' ) {
              setRosterNotesList(RosterNotesList => [fetchedData.data,...RosterNotesList]);
              setRosterNotes('');
            }
    
            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
      }

    return (
        <>
        <Modal isOpen={show} toggle={onCloseModal} size="xl" backdrop="static" scrollable >
            <ModalHeader toggle={onCloseModal} tag="h4">
                Roster Details { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
            </ModalHeader>
            <ModalBody style={{ minHeight: '400px' }}>
            
                <Row>
                    <Col md={8}>
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                            <Row>
                                <Col md={6}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-email-Input">Client Name</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={validation.values.client_name}
                                    />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-password-Input">Staff name</Label>
                                    <Input
                                        type="pastextsword"
                                        className="form-control"
                                        value={validation.values.staff_name}
                                    />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-email-Input">Date</Label>
                                    <Input
                                        name="start_date"
                                        type="text"
                                        className="form-control"
                                        value={validation.values.start_date}
                                        readOnly={true}
                                    />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-password-Input">Start Time</Label>
                                    <Input
                                      name="start_time"
                                        type="time"
                                        className="form-control"
                                        value={validation.values.start_time}
                                        onChange={validation.handleChange}
                                    />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-password-Input">Finish Time</Label>
                                    <Input
                                    name="finish_time"
                                        type="time"
                                        className="form-control"
                                        value={validation.values.finish_time}
                                        onChange={validation.handleChange}
                                    />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <div className="mb-3">
                                    
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-password-Input">Actual Start Time</Label>
                                    <Input
                                        name="actual_start_time"
                                        type="time"
                                        className="form-control"
                                        value={validation.values.actual_start_time}
                                        onChange={validation.handleChange}
                                    />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-password-Input">Actual Finish Time</Label>
                                    <Input
                                        name="actual_finish_time"
                                        type="time"
                                        className="form-control"
                                        value={validation.values.actual_finish_time}
                                        onChange={validation.handleChange}
                                    />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                            <Col xs={6}>
                              <div className="mb-3">
                                <Label className="form-label">Odometer Start Reading</Label>
                                <Input
                                  name="odometer_start"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.odometer_start}
                                 />
                              </div>
                            </Col>

                            <Col xs={6}>
                              <div className="mb-3">
                                <Label className="form-label">Odometer End Reading</Label>
                                <Input
                                  name="odometer_end"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.odometer_end}
                                 />
                              </div>
                              
                            </Col>

                          </Row>

                          <Row>
                            <Col xs={6}>
                              <div className="mb-3">
                                <Label className="form-label">Kms</Label>
                                <Input
                                  name="roster_kms"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={(validation.values.odometer_end - validation.values.odometer_start).toFixed(2)}
                                  
                                  readOnly
                                />

                              </div>
                              
                            </Col>

                            <Col xs={6}>
                              <div className="mb-3">
                                <Label className="form-label">Kms Description</Label>
                                <Input
                                  name="roster_kms_for"
                                  type="textarea"
                                  value={validation.values.roster_kms_for || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                />
                              </div>
                              
                            </Col>

                          </Row>

                          <Row>
                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">Staff Notes</Label>
                                <Input
                                  name="roster_extra_details"
                                  type="textarea"
                                  value={validation.values.roster_extra_details}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                />
                              </div>
                              
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={6}>
                              <div className="mb-3">
                                <Label className="form-label">Provider Email</Label>
                                <Input
                                  name="client_emergency_contact_email"
                                  type="text"
                                  value={validation.values.client_emergency_contact_email || ""}
                                  readOnly={true}
                                  
                                />
                              </div>
                              
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Pay Category External ID</Label>

                                    <Select
                                    value={payCat}
                                    name="client_id"
                                    options={payCategoryDropdown}
                                    className="select2-selection"
                                    defaultValue={payCat}
                                    // onChange={(e) => setPayCat(e)}
                                    // onBlur={validation.handleBlur}
                                    isClearable={true}
                                    // isDisabled={true}
                                    styles={{
                                      menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}

                                    />
                                    
                                </div>
                            </Col>
                        </Row>

                        <Row>

                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">Different Location Text</Label>
                                <Input
                                  name="different_location_text"
                                  type="textarea"
                                  value={validation.values.different_location_text || ""}
                                  readOnly={true}
                                  placeholder="Different Location Text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                />
                              </div>
                              
                            </Col>

                          </Row>

                          {roster.supportType == 'Transport Assistance'}
                          <Row>
                            <Col xs={6}>
                              <div className="mb-3">
                                <Label className="form-label">Parking Fee</Label>
                                <Input
                                  name="roster_parking_fee"
                                  type="number"
                                  value={validation.values.roster_parking_fee || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                />
                              </div>
                            </Col>

                            <Col xs={6}>
                              <div className="mb-3">
                                <Label className="form-label">Transport Instructions</Label>
                                <Input
                                  name="transport_inst"
                                  type="textarea"
                                  rows="4"
                                  value={validation.values.transport_inst || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                />
                              </div>
                            </Col>

                          </Row>

                          <Row>

                          <Col xs={6}>
                              <div className="mb-3">
                                <Label className="form-label">Support Type</Label>
                                <Select
                                  value={supportType}
                                  name="ros_support_type"
                                  options={supportTypeDropdown}
                                  className="select2-selection"
                                  defaultValue={supportType}
                                  // onChange={(e) => setSupportType(e)}
                                  // onBlur={validation.handleBlur}
                                  // isDisabled={true}
                                  styles={{
                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                  }}
                                />
                              
                              </div>

                            </Col>

                            <Col xs={6}>
                              <div className="mb-3">
                                <Label className="form-label">Choose Status</Label>

                                <Select
                                  value={adminVerifiedStatus.verifiedStatus}
                                  name="roster_sub_admin_verified_status"
                                  options={adminVerifiedStatus}
                                  className="select2-selection"
                                  onChange={(e) => {
                                    handleVerifiedStatus(e);
                                  }}
                                  onBlur={validation.handleBlur}
                                  styles={{
                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                  }}
                                />
                                
                              </div>
                              
                              {/* {page !='completed_rosters' && } */}
                              { verifiedStatus == '1' && (
                              <div className="mb-3">
                              <Input
                                  name="send_roster_report"
                                  type="checkbox"
                                  value="1"                          
                                  onChange={() =>
                                    setSendReportChecked(!sendReportChecked)
                                  }
                                /> Send Roster Report
                              </div>
                              )}
                            </Col>
                          </Row>


                          <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                    disabled={ModalLoading}
                                  >
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row>


                        </Form>

                    </Col>
                    
                    <Col md={4}>
                        <CardTitle>Activiry Log</CardTitle>

                        <Row>

                            <Col xs={12}>

                                <div className="mb-3">
                                <Label className="form-label">Add Notes</Label>
                                <Input
                                    name="roster_notes"
                                    type="textarea"
                                    onChange={e => {
                                    
                                    setRosterNotes(e.target.value);
                                    }}
                                    value={RosterNotes}
                                />
                                
                                </div>
                            
                            </Col>
                            
                        </Row>

                        <Row>
                          <Col>
                            <div className="text-end">
                              <button onClick={addRosterNotes}
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Add
                              </button>
                            </div>
                          </Col>
                        </Row>

                        <ul className="order_notes">
                            { RosterNotesList.map(activity => (
                            <li key={activity.id} rel={activity.id} className="note">
                                <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                <strong>
                                {activity.activity_by}
                                </strong>
                                </abbr></p>
                            </li>
                            ))}
                        </ul>
                    
                    </Col>
                </Row>

            </ModalBody>
        </Modal>
        <ToastContainer />
        </>
    )

}

RosterDetailsModal.propTypes = {
    onCloseModal: PropTypes.func,
    show: PropTypes.any
}

export default RosterDetailsModal;