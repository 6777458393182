import {
  GET_CONTRACTORS,
  GET_CONTRACTORS_SUCCESS,
  GET_CONTRACTORS_FAIL,
  GET_CONTRACTORS_DOCUMENTS,
  GET_CONTRACTORS_DOCUMENTS_SUCCESS,
  GET_CONTRACTORS_DOCUMENTS_FAIL,
  ADD_CONTRACTOR_DOCUMENTS,
  ADD_CONTRACTOR_DOCUMENTS_SUCCESS,
  ADD_CONTRACTOR_DOCUMENTS_FAIL,
  UPDATE_CONTRACTOR_DOCUMENTS,
  UPDATE_CONTRACTOR_DOCUMENTS_SUCCESS,
  UPDATE_CONTRACTOR_DOCUMENTS_FAIL,
  ADD_CONTRACTOR,
  UPDATE_CONTRACTOR
} from "./actionTypes"

const INIT_STATE = {
  contractors: [],
  contractorsDocs: [],
  docsDropdown: [],
  hiddenColumns: [],
  error: {},
  loading: false,
}

const ContractorsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONTRACTORS:
      console.log('loading');
      return {
        ...state,
        contractors: state.contractors,
        docsDropdown: state.docsDropdown,
        hiddenColumns: state.hiddenColumns,
        loading: true
     }
    case GET_CONTRACTORS_SUCCESS:
      console.log('success');
      return {
        ...state,
        contractors: action.payload.data,
        docsDropdown: action.payload.DocumentsDropdown,
        hiddenColumns: action.payload.hiddenColumns,
        loading: false
     }

    case GET_CONTRACTORS_FAIL:
    console.log('failed');
    return {
      ...state,
      error: action.payload,
      loading: false
    }

    case GET_CONTRACTORS_DOCUMENTS:
    console.log('loading');
    return {
      ...state,
      contractorsDocs: [], //[...state.contractorsDocs],
      loading: true
    }

    case GET_CONTRACTORS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        contractorsDocs: action.payload.data,
        loading: false
      }

    case GET_CONTRACTORS_DOCUMENTS_FAIL:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }

      case ADD_CONTRACTOR_DOCUMENTS:
        return {
            ...state,
            loading: true
      };
      case ADD_CONTRACTOR_DOCUMENTS_SUCCESS:
        return {
          ...state,
          contractorsDocs: [...state.contractorsDocs, action.payload.data],
          loading: false
      };
      case ADD_CONTRACTOR_DOCUMENTS_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
      };

    //   case GET_CASEMANAGER_DETAILS:
    //   console.log('loading');
    //   return {
    //     casemanager: [],
    //     loading: true
    //  }

    // case GET_CASEMANAGER_DETAILS_SUCCESS:
    //   console.log('success');
    //   return {
    //     ...state,
    //     casemanager: action.payload.data,
    //     loading: false
    //  }

    // case GET_CASEMANAGER_DETAILS_FAIL:
    // console.log('failed');
    // return {
    //   ...state,
    //   error: action.payload,
    // }

      case UPDATE_CONTRACTOR_DOCUMENTS:
        return {
          ...state,
          loading: true
      };

      case UPDATE_CONTRACTOR_DOCUMENTS_SUCCESS:
        return {
            ...state,
            contractorsDocs: state.contractorsDocs.map(rosters =>
              rosters.id.toString() === action.payload.data.id.toString()
                    ? { rosters, ...action.payload.data }
                    : rosters
            ),
            loading: false
      };

      case UPDATE_CONTRACTOR_DOCUMENTS_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        };

        case ADD_CONTRACTOR:
          return {
            ...state,
            contractors: [...state.contractors, action.payload],
            loading: false
        };
  
        case UPDATE_CONTRACTOR:
          return {
              ...state,
              contractors: state.contractors.map(rosters =>
                rosters.user_id.toString() === action.payload.user_id.toString()
                      ? { rosters, ...action.payload }
                      : rosters
              ),
              loading: false
        }

      default:
        return state
  }
}

export default ContractorsReducer
