import React, { useEffect, useRef, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import alertSound from '../../../src/assets/alerts/alert.mp3';
import bellSound from '../../../src/assets/alerts/bell.mp3';
import broadcastAlertSound from '../../../src/assets/alerts/broadcat_alert.mp3';
import checklistAlertSound from '../../../src/assets/alerts/checklist_alerts_electronic.mp3';
import hornAlertSound from '../../../src/assets/alerts/horn.mp3';
import emergencyAlertSound from '../../../src/assets/alerts/emergency_alerts_bloom.mp3';


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link, Route } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

//firebae config
import { db } from "helpers/firebaseConfig";


import { ADMIN_URL } from "helpers/url_helper";

const SidebarContent = props => {

  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const [ChatAlertsCount, setChatAlertsCount] = useState(0);
  const [AlertsCount, setAlertsCount] = useState(localStorage.getItem('alerts_count'));
  const [AdminWorkAlertsCount, setAdminWorkAlertsCount] = useState(localStorage.getItem('admin_alerts_count'));
  const [AlertCMAlertsCount, setAlertCMAlertsCount] = useState(localStorage.getItem('caremanager_alerts_count'));
  const [AlertSMAlertsCount, setAlertSMAlertsCount] = useState(localStorage.getItem('service_manager_alerts_count'));

  const [EmergencyAlertsCount, setEmergencyAlertsCount] = useState(localStorage.getItem('emergency_alerts_count'));
  const [BroadcastRosterAlertsCount, setBroadcastRosterAlertsCount] = useState(localStorage.getItem('broadcast_alerts_count'));

  const [StaffLeaveApprovedAlertsCount, setStaffLeaveApprovedAlertsCount] = useState(localStorage.getItem('leave_approved_count'));
  const [StaffLeaveUnApprovedAlertsCount, setStaffLeaveUnApprovedAlertsCount] = useState(localStorage.getItem('leave_unapproved_count'));
  const [ClientAwayAlertsCount, setClientAwayAlertsCount] = useState(localStorage.getItem('client_away_alerts_count'));
  const [ChecklistAlertCount, setChecklistAlertCount] = useState(localStorage.getItem('checklist_alerts_count'));
  const [TaccomAlerts, SetTaccomAlerts] = useState(0);

  const [IncidentAlertsCount, setIncidentAlertsCount] = useState(localStorage.getItem('incidents_count'));

  const [RecruitmentAlertsCount, setRecruitmentAlertsCount] = useState(localStorage.getItem('recruitment_count'));
  const [RecruitmentEmployedAlertsCount, setRecruitmentEmployedAlertsCount] = useState(localStorage.getItem('recruitment_employed_count'));
  const [FeedbackAlertsCount, setFeedbackAlertsCount] = useState(localStorage.getItem('feedback_count'));

  const [NoClockInAlertsCount, setNoClockInAlertsCount] = useState(localStorage.getItem('no_clockin_alerts_count'));
  const [CompletedRosterAlertsCount, setCompletedRosterAlertsCount] = useState(localStorage.getItem('completed_rosters_alerts_count'));



  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();

  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (let i = 0; i < items.length; ++i) {
      
      if ('#'+pathName === items[i].hash) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname,activateParentDropdown]);

  // const activeMenu = useCallback(() => {
  //   const pathName = path.pathname;
  //   console.log(pathName);
  //   let matchingMenuItem = null;
  //   const ul = document.getElementById("side-menu");
  //   const items = ul.getElementsByTagName("a");
  //   removeActivation(items);

  //   console.log(items);
  //   for (let i = 0; i < items.length; ++i) {
  //     console.log(items[i].pathname);
  //     if (pathName === items[i].pathname) {
  //       matchingMenuItem = items[i];
  //       break;
  //     }
  //   }

  //   console.log(matchingMenuItem)
  //   if (matchingMenuItem) {
  //     activateParentDropdown(matchingMenuItem);
  //   }
  // }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();

  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function play_alert_sound(){
    const audio = new Audio(alertSound); // Path to your sound file

    audio.addEventListener('canplaythrough', (event) => {
      // the audio is now playable; play it if permissions allow
      audio.play();
    });
  }

  const play_broadcast_alert_sound = () => {
    const audio = new Audio(broadcastAlertSound); // Path to your sound file
    audio.addEventListener('canplaythrough', (event) => {
      // the audio is now playable; play it if permissions allow
      audio.play();
    });

    // audio.load()
    // audio.play()
  }

  const play_completed_ros_alerts_audio = () => {
    if( loggedUser.role == 'subadmin') {
      const audio = new Audio(bellSound); // Path to your sound file
      audio.addEventListener('canplaythrough', (event) => {
        // the audio is now playable; play it if permissions allow
        audio.play();
      });
      // audio.load()
      // audio.play()
    }
  }


  const play_noclockin_rosters_audio = () => {
    if( loggedUser.role == 'subadmin') {
      const audio = new Audio(hornAlertSound); // Path to your sound file
      audio.addEventListener('canplaythrough', (event) => {
        // the audio is now playable; play it if permissions allow
        audio.play();
      });
      // audio.load()
      // audio.play()
    }
  }

  const play_emergency_alert_audio = () => {

      const audio = new Audio(emergencyAlertSound); // Path to your sound file
      audio.addEventListener('canplaythrough', (event) => {
        // the audio is now playable; play it if permissions allow
        audio.play();
      });
      // audio.load()
      // audio.play()
  }

  const play_checklist_alert_audio = () => {
    if( loggedUser.role == 'subadmin') {
      const audio = new Audio(checklistAlertSound); // Path to your sound file
      audio.addEventListener('canplaythrough', (event) => {
        // the audio is now playable; play it if permissions allow
        audio.play();
      });
      // audio.load()
      // audio.play()
    }
  }

  const play_clientaway_alert_audio = () => {
    if( loggedUser.role == 'admin') {
      const audio = new Audio(alertSound); // Path to your sound file
      audio.addEventListener('canplaythrough', (event) => {
        // the audio is now playable; play it if permissions allow
        audio.play();
      });
      // audio.load()
      // audio.play()
    }
  }
  

  const testingCountRef = db.ref('testingCount');

  const messageNewRef = db.ref('message_test');
  const chatRef = db.ref('chatRef_test');

  const messageRef = db.ref('message');
  const userRef = db.ref('user');
  const broadcastRef = db.ref('broadcast');
  const rosterCompletedRef = db.ref('rosterCompleted');
  const noClockInRef = db.ref('noClockIn');

  const emergencyRef = db.ref('emergencyAlerts');
  const checklistRef = db.ref('checklistAlerts');
  const clientAwayRef = db.ref('clientAwayAlerts');

  const taccom_chats = db.ref('taccom_chats');


  useEffect(() => {

    var total_count = 0;
    chatRef.once("value", (snap) => {
      snap.forEach( function(data) {
        
        // console.log( data.val().user_read_status)
        if( data.val().user_read_status == 1 ) {
          total_count = parseInt(total_count) + 1;
        }
      })
      localStorage.setItem('chat_count',total_count)
      setChatAlertsCount(total_count);

    });

    chatRef.on("child_changed", a => {

      // console.log(a.val());

      var total_count = localStorage.getItem('chat_count'); //parseInt($(`#chats_new_alerts`).html());
 
      if( a.val().user_read_status == 1 ) {
        total_count = parseInt(total_count) + 1;
      }

      if( a.val().user_read_status == 0 ) {
        total_count = parseInt(total_count) - 1;
      }

      if( total_count < 0 ) {
        total_count = 0;
      }
      // if ( $(`#chats_new_alerts`).length ) {
      //   $(`#chats_new_alerts`).html(total_count);
      // }

      localStorage.setItem('chat_count',total_count)

      console.log(total_count);
      setChatAlertsCount(total_count);

      // if( total_count > 0 ) {
      //   play_alert_sound();
      // }

    })



    messageNewRef.on("child_added", a => {
      
      var total_count = 0; //localStorage.getItem('chat_count'); //parseInt($(`#chats_new_alerts`).html());

      if( a.val().ke == 1 ) {
        
        if( a.val().read_status == 0 ) {

          console.log('read status ' + 0);

          total_count = parseInt(total_count) + 1;
        
          localStorage.setItem('chat_count',total_count)
          setChatAlertsCount(total_count)

          // if ( $(`#chats_new_alerts`).length ) {
          //   $(`#chats_new_alerts`).html(total_count);
          // }
        }

      }

      if( total_count > 0 ) {
        console.log('playing sound')
        play_alert_sound();
      }

    })


    //old chat count
    messageRef.limitToLast(1).on("child_added", a => {
      //console.log("added", a.key, a.val());
      var total_count = localStorage.getItem('chat_old_count'); 
      if( a.val().ke == 1 ) {
        if ( total_count ) {
            co = parseInt(total_count) + 1;
            messageRef.child(a.key).remove();

            localStorage.setItem('chat_old_count',co)
            play_alert_sound();
        }
      }

    })

    broadcastRef.on("child_added", a => {
      //console.log("added", a.key, a.val());

      var co = localStorage.getItem('broadcast_alerts_count');
      if( a.val().count == 1 ) {
        //if ( $('#broadcast_roster').length ) {
            co = parseInt(co) + 1;
        //}
        //console.log(co);
        setBroadcastRosterAlertsCount(co);
        localStorage.setItem('broadcast_alerts_count',co)
        // $(`#broadcast_roster`).html(co);
        play_broadcast_alert_sound();
        broadcastRef.child(a.key).remove();
      }

    });

    rosterCompletedRef.on("child_added", a => {
      //console.log("added", a.key, a.val());
      var co = localStorage.getItem('completed_rosters_alerts_count');
      if( a.val().count == 1 ) {
        // if ( $('.completed_rosters').length ) {
            co =parseInt(co) + 1;
        // }
        //console.log(co);
        // $(`.completed_rosters`).html(co);
        localStorage.setItem('completed_rosters_alerts_count',co);
        setCompletedRosterAlertsCount(co);
        play_completed_ros_alerts_audio();
        //console.log('completed_rosters alert');
        rosterCompletedRef.child(a.key).remove();
      }

    });

    noClockInRef.on("child_added", a => {
      //console.log("added", a.key, a.val());
      var co = localStorage.getItem('no_clockin_alerts_count');
      if( a.val().count == 1 ) {
        // if ( $('.no_clock_in_rosters').length ) {
            co = parseInt(co) + 1;
        // }
        //console.log(co);
        // $(`.no_clock_in_rosters`).html(co);
        localStorage.setItem('no_clockin_alerts_count',co);
        setNoClockInAlertsCount(co);
        play_noclockin_rosters_audio();
        //console.log('no_clock_in_rosters alert');
        noClockInRef.child(a.key).remove();
      }

    });

    emergencyRef.on("child_added", a => {
      var co = localStorage.getItem('emergency_alerts_count');
      if( a.val().count == 1 ) {
        //if ( $('.emergency_alerts').length ) {
            co = parseIntco + 1;
        //}
        // $(`.emergency_alerts`).html(co);
        localStorage.setItem('emergency_alerts_count',co);
        setEmergencyAlertsCount(co);
        play_emergency_alert_audio();

        emergencyRef.child(a.key).remove();
      }

    });

    checklistRef.on("child_added", a => {

      var co = localStorage.getItem('checklist_alerts_count');
      if( a.val().count == 1 ) {
        // if ( $('.checklist_alerts').length ) {
            co = parseInt(co) + 1;
        // }
        //console.log(co);
        // $(`.checklist_alerts`).html(co);
        localStorage.setItem('checklist_alerts_count',co);
        setChecklistAlertCount(co);
        play_checklist_alert_audio();
        //console.log('checklist_alerts');
        checklistRef.child(a.key).remove();
      }

    });

    clientAwayRef.on("child_added", a => {
      console.log("added", a.key, a.val());
      var co = localStorage.getItem('client_away_alerts_count');
      if( a.val().count == 1 ) {
        //if ( $('.client_away_alerts').length ) {
            co = parseInt(co)+ 1;
        //}
        //console.log(co);
        //$(`.client_away_alerts`).html(co);
        localStorage.setItem('client_away_alerts_count',co);
        setClientAwayAlertsCount(co);
        play_clientaway_alert_audio();
        //console.log('client_away_alerts');
        clientAwayRef.child(a.key).remove();
      }

    });

    taccom_chats.once("value", (snap) => {
      // console.log("added", a.key, a.val());
      var total_count = 0;
      snap.forEach( function(data) {
        if( data.val().task_status == 0 ) {
          total_count = total_count + 1;
        }
      })

      SetTaccomAlerts(total_count);

      // $(`#taccom`).html(total_count);

    });


    testingCountRef.on("child_added", a => {
        // let co = localStorage.getItem('emergency_alerts_count');
        // console.log(co)
        if( a.val().count == 1 ) {
          // co = parseInt(co) + 1;
          // console.log(co)
          // setEmergencyAlertsCount(co);
          // localStorage.setItem('emergency_alerts_count',co);
          play_alert_sound()
          // testingCountRef.child(a.key).remove();
        }
    })

  }, []);

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

          { (loggedUser.role == 'admin' || loggedUser.role == 'sub_admin')  && 
          <>

          <li>
            <Link to="/public-holiday" >
              <i className="bx bxs-detail"></i>
              <span>{props.t("Public Holidays")}</span>
            </Link>
          </li>
          
          <li>
            <Link to="/send-sms" >
              <i className="bx bxs-detail"></i>
              <span>{props.t("Send Sms")}</span>
            </Link>
          </li>


           <li>
              <Link to="/" className="has-arrow">
              
                <i className="bx bx-bell"></i>
                <span>{props.t("Alerts")}</span>
                <span className="badge rounded-pill bg-danger">{localStorage.getItem('alerts_count')}</span>
              </Link>
              <ul className="sub-menu">
              <li>
                  <Link to="/alerts/admin-work-alerts">{props.t("Admin Work Alerts")}&nbsp;<span className="badge rounded-pill bg-danger">{localStorage.getItem('admin_alerts_count')}</span></Link>
              </li>

              <li>
                  <Link to="/alerts/care-management-alerts">{props.t("Care Management Alerts")}&nbsp;<span className="badge rounded-pill bg-danger">{localStorage.getItem('caremanager_alerts_count')}</span></Link>
              </li>

              <li>
                  <Link to="/alerts/service-manager-alerts">{props.t("Service Manager Alerts")}&nbsp;<span className="badge rounded-pill bg-danger">{localStorage.getItem('service_manager_alerts_count')}</span></Link>
              </li>
                
              </ul>
            </li>

            <li>
              <Link to="/" className="has-arrow">
                <i className="bx bx-bell"></i>
                <span>{props.t("Emergency Alerts")}</span>
                <span className="badge rounded-pill bg-danger">{EmergencyAlertsCount}</span>
              </Link>
              <ul className="sub-menu">
              <li>
                  <Link to="/emergency-active-alerts">{props.t("Active Alerts")} <span className="badge rounded-pill bg-danger">{EmergencyAlertsCount}</span></Link>
                  
              </li>

              <li>
                  <Link to="/emergency-archived-alerts">{props.t("Archived Alerts")}</Link>
              </li>
                
              </ul>
            </li>
            <li>
              <Link to={ADMIN_URL+'locations.php?uid='+loggedUser.uid+'&role='+loggedUser.role+'&type='+loggedUser.usertype} target="_blank" > 
                <i className="bx bx-map"></i>
                <span>{props.t("Map Locations")}</span>
              </Link>
            </li>

            <li>
              <Link to={ADMIN_URL+'taccom.php?uid='+loggedUser.uid+'&role='+loggedUser.role+'&type='+loggedUser.usertype} target="_blank">
                <i className="bx bx-task"></i>
                <span>{props.t("TacCom")}</span>
                <span className="badge rounded-pill bg-danger">{TaccomAlerts}</span>
              </Link>
            </li>

            <li>
              <Link to={ADMIN_URL+'new-chat.php?uid='+loggedUser.uid+'&role='+loggedUser.role+'&type='+loggedUser.usertype} target="_blank" >
                <i className="bx bx-chat"></i>
                <span>{props.t("TacChat")}</span>
                <span className="badge rounded-pill bg-danger">{ChatAlertsCount}</span>
              </Link>
            </li>

            {loggedUser.role == 'admin' &&
          <>

          <li>
          <Link to="/admin/chats.php" target="_blank">
            <i className="bx bxs-detail"></i>
            <span>{props.t("Old Chat")}</span>
           < span className="badge rounded-pill bg-danger">{ChatAlertsCount}</span>
          </Link>
          </li>
          
          </>
          }

            <li>
              <Link to="/broadcast-rosters" >
                <i className="mdi mdi-air-horn"></i>
                <span>{props.t("Broadcast Rosters")}</span>
                <span className="badge rounded-pill bg-danger">{BroadcastRosterAlertsCount}</span>
              </Link>
            </li>

          {loggedUser.role == 'admin' && 
            <>
              {/* <li>
                <Link to="/admin-staff-rosters" >
                  <i className="bx bx-unite"></i>
                  <span>{props.t("Care Notes Review")}</span>
                </Link>
              </li>

              <li>
                <Link to="/completed-rosters" >
                  <i className="bx bx-unite"></i>
                  <span>{props.t("Completed Rosters")}</span>
                </Link>
              </li> */}
            </>
          }

            <li>
              <Link to="/#" className="has-arrow">
                <i className="mdi mdi-application-cog"></i>
                <span>{props.t("Rosters")}</span>
              </Link>
              <ul className="sub-menu">
              { loggedUser.role == 'admin' && 
              <li>
                  <Link to="/rosters/add-rosters">{props.t("Add Roster")}</Link>
              </li>
              }
              <li>
                  <Link to="/rosters/all-rosters">{props.t("All Rosters")}</Link>
              </li>
              <li>
                  <Link to="/rosters/todays-rosters">{props.t("Todays Rosters List")}</Link>
              </li>
              <li>
                  <Link to="/rosters/group-rosters">{props.t("Group Rosters List")}</Link>
              </li>
                
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Departments")}</span>
              </Link>
              <ul className="sub-menu">
              {/* <li>
                  <Link to="/add-department">{props.t("Add New")}</Link>
              </li> */}
              <li>
                  <Link to="/providers">{props.t("Providers")}</Link>
              </li>
              <li>
                  <Link to="/third-party-contractors">{props.t("Third Party Contractors")}</Link>
              </li>
                
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Case Managers")}</span>
              </Link>
              <ul className="sub-menu">
              {/* <li>
                  <Link to="/casemanager/add">{props.t("Add New")}</Link>
              </li> */}
              <li>
                  <Link to="/casemanagers">{props.t("Case Managers")}</Link>
              </li>

              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Contractors")}</span>
              </Link>
              <ul className="sub-menu">
              {/* <li>
                  <Link to="/contractors/add">{props.t("Add New")}</Link>
              </li> */}
              <li>
                  <Link to="/contractors">{props.t("Contractors")}</Link>
              </li>

              <li>
                  <Link to="/contractors/documents">{props.t("Contractors Document Name")}</Link>
              </li>

              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Clients")}</span>
              </Link>
              <ul className="sub-menu">
              <li>
                  <Link to="/client-service-types">{props.t("Service Types")}</Link>
              </li>
              <li>
                  <Link to="/client-isolation-lists">{props.t("Isolation List")}</Link>
              </li>
              <li>
                  <Link to="/clients/add-client">{props.t("Add New Client")}</Link>
              </li>
              <li>
                  <Link to="/clients">{props.t("Clients")}</Link>
              </li>
              <li>
                  <Link to="/clients/archived">{props.t("Archived Clients")}</Link>
              </li>
              <li>
                  <Link to="/client-service-lists">{props.t("Client Service List")}</Link>
              </li>
                
              </ul>
            </li>


            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Staffs")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                    <Link to="/staffs/services-types">{props.t("Service Types")}</Link>
                </li>
                <li>
                    <Link to="/staffs/languages">{props.t("Languages")}</Link>
                </li>
                <li>
                    <Link to="/staffs/add-staff">{props.t("Add Staff")}</Link>
                </li>
                <li>
                    <Link to="/staffs/csm-staffs">{props.t("CSM Admin Logins")}</Link>
                </li>
                { loggedUser.role != 'sub_admin' &&
                <li>
                    <Link to="/staffs/support-staffs">{props.t("Support Staff")}</Link>
                </li>
                }
                <li>
                    <Link to="/staffs/admin-staffs">{props.t("Admin Staff")}</Link>
                </li>
                <li>
                    <Link to="/staffs/hr-managers">{props.t("Human Resource Manager")}</Link>
                </li>

                <li>
                    <Link to="/staffs/">{props.t("Support Worker")}</Link>
                </li>

                <li>
                    <Link to="/archived-staffs">{props.t("Archived Support Worker")}</Link>
                </li>
              </ul>
            </li>

            

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Staff Leaves Alerts")}</span>
                <span className="badge rounded-pill bg-danger">{localStorage.getItem('leave_unapproved_count')}</span>
              </Link>
              <ul className="sub-menu">
              <li>
                  <Link to="/staffleaves/add">{props.t("Add Leave")}</Link>
              </li>
              <li>
                  <Link to="/staffleaves/processed">{props.t("Staff Leave Processed")}</Link>
              </li>

              <li>
                  <Link to="/staffleaves/approved">{props.t("Staff Leave Approved")} <span className="badge rounded-pill bg-danger">{localStorage.getItem('leave_approved_count')}</span></Link>
              </li>

              <li>
                  <Link to="/staffleaves/approved-archived">{props.t("Staff Leave Approved Archived")}</Link>
              </li>

              <li>
                  <Link to="/staffleaves/unapproved">{props.t("Staff Leave Unapproved")}<span className="badge rounded-pill bg-danger">{localStorage.getItem('leave_unapproved_count')}</span></Link>
              </li>

              <li>
                  <Link to="/staffleaves/deleted">{props.t("Staff Leave Deleted")}</Link>
              </li>

              </ul>
            </li>


            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-bell-plus"></i>
                <span>{props.t("Client Away Alerts")}</span>
                <span className="badge rounded-pill bg-danger">{ClientAwayAlertsCount}</span>
              </Link>
              <ul className="sub-menu">
              <li>
                  <Link to="/clientsawayalerts/add">{props.t("Add New")}</Link>
              </li>

              <li>
                  <Link to="/clientsawayalerts/approved">{props.t("Clients Away Alert Approved")}</Link>
              </li>

              <li>
                  <Link to="/clientsawayalerts/unapproved">{props.t("Clients Away Alert Unapproved")}<span className="badge rounded-pill bg-danger">{ClientAwayAlertsCount}</span></Link>
              </li>

              <li>
                  <Link to="/clientsawayalerts/deleted">{props.t("Clients Away Alert Deleted")}</Link>
              </li>

              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-report"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu">
              <li>
                  <Link to="/reports/roster-report">{props.t("Roster Report")}</Link>
              </li>

              <li>
                  <Link to="/reports/report-with-notes">{props.t("Report With Notes")}</Link>
              </li>

              <li>
                  <Link to="/reports/checklist-report">{props.t("Checklist Rports")}</Link>
              </li>

              <li>
                  <Link to="/reports/two-hour-roster-report">{props.t("<2 Hour Roster Report")}</Link>
              </li>
              <li>
                  <Link to="/reports/group-roster-report">{props.t("Group Roster Report")}</Link>
              </li>

              <li>
                  <Link to="/reports/buddy-report">{props.t("Buddy Report")}</Link>
              </li>

              { loggedUser.role == 'admin' && 
              
              <li>
                  <Link to="/reports/support-worker-doc-hr-report">{props.t("Support Worker Doc HR Report")}</Link>
              </li>
              
              }

              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-report"></i>
                <span>{props.t("Incident Reports")}</span>
                <span className="badge rounded-pill bg-danger">{localStorage.getItem('incidents_count')}</span>
              </Link>
              <ul className="sub-menu">
              { loggedUser.role == 'admin' && 
              <li>
                  <Link to="/incident-reports/add">{props.t("Add")}</Link>
              </li>
              }
              <li>
                  <Link to="/incident-reports/active">{props.t("Active")}</Link>
              </li>

              <li>
                  <Link to="/incident-reports/completed">{props.t("Completed")}</Link>
              </li>

              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx bx-news"></i>
                <span>{props.t("News Feeds")}</span>
              </Link>
              <ul className="sub-menu">

              <li>
                  <Link to="/news-feeds/active">{props.t("Active")}</Link>
              </li>

              <li>
                  <Link to="/news-feeds/archived">{props.t("Archived")}</Link>
              </li>

              </ul>
            </li>

            {loggedUser.role == 'admin' && 
            <>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("Client Types")}</span>
              </Link>
              <ul className="sub-menu">

              <li>
                  <Link to="/client-types">{props.t("Client Types")}</Link>
              </li>

              <li>
                  <Link to="/client-package-types">{props.t("Client Package Types")}</Link>
              </li>

              <li>
                  <Link to="/client-package-types/archived">{props.t("Client Package Types Archived")}</Link>
              </li>

              </ul>
            </li>

            <li>
              <Link to="/settings" >
                <i className="mdi mdi-order-bool-descending"></i>
                <span>{props.t("Settings")}</span>
              </Link>
            </li>

            <li>
              <Link to="/package-report-settings" >
                <i className="mdi mdi-application-cog"></i>
                <span>{props.t("Package Report Settings")}</span>
              </Link>
            </li>


            <li>
              <Link to="/pay-category-list" >
                <i className="mdi mdi-backburger"></i>
                <span>{props.t("pay Category")}</span>
              </Link>
            </li>

            <li>
              <Link to="/vaccination-details" >
                <i className="bx bx-first-aid"></i>
                <span>{props.t("Vaccination Records")}</span>
              </Link>
            </li>

            <li>
              <Link to="/checklist-questions" >
                <i className="bx bx-first-aid"></i>
                <span>{props.t("Covid-19 Questions")}</span>
              </Link>
            </li>

            <li>
              <Link to="/hr-documents-name-list" >
                <i className="bx bx-file"></i>
                <span>{props.t("Hr Documents Name")}</span>
              </Link>
            </li>
            
            </>
            }

            <li>
              <Link to="/feedbacks" >
                <i className="bx bx-file"></i>
                <span>{props.t("Feedbacks")} <span className="badge rounded-pill bg-danger">{localStorage.getItem('feedback_count')}</span></span>
              </Link>
            </li>

            <li>
              <Link to="/recruitments/" className="has-arrow">
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("Recruitments")} <span className="badge rounded-pill bg-danger">{localStorage.getItem('recruitment_count')}</span></span>
              </Link>
              <ul className="sub-menu">

              <li>
                  <Link to="/recruitments/interview-questions">{props.t("Interview Questions")}</Link>
              </li>

              <li>
                  <Link to="/recruitments/candidate-lists">{props.t("Candidate Lists")}</Link>
              </li>

              <li>
                  <Link to="/recruitments/employed-lists">{props.t("Employed Lists")} <span className="badge rounded-pill bg-danger">{localStorage.getItem('recruitment_employed_count')}</span></Link>
              </li>

              </ul>
            </li>

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-task"></i>
                <span>{props.t("Tasks")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tasks-list">{props.t("Task List")}</Link>
                </li>
                <li>
                  <Link to="/tasks-kanban">{props.t("Kanban Board")}</Link>
                </li>
                <li>
                  <Link to="/tasks-create">{props.t("Create Task")}</Link>
                </li>
              </ul>
            </li> */}
          </>
          }

          { loggedUser.role == 'human_resource' && 
            <>
            
            <li>
              <Link to="/send-sms" >
                <i className="bx bxs-detail"></i>
                <span>{props.t("Send Sms")}</span>
              </Link>
            </li>

            <li>
              <Link to={ADMIN_URL+'locations.php?uid='+loggedUser.uid+'&role='+loggedUser.role+'&type='+loggedUser.usertype} target="_blank" >
                <i className="bx bx-map"></i>
                <span>{props.t("Map Locations")}</span>
              </Link>
            </li>

            <li>
              <Link to={ADMIN_URL+'taccom.php?uid='+loggedUser.uid+'&role='+loggedUser.role+'&type='+loggedUser.usertype} target="_blank" >
                <i className="bx bx-task"></i>
                <span>{props.t("TacCom")}</span>
                <span className="badge rounded-pill bg-danger">{TaccomAlerts}</span>
              </Link>
            </li>

            <li>
              <Link to={ADMIN_URL+'new-chat.php?uid='+loggedUser.uid+'&role='+loggedUser.role+'&type='+loggedUser.usertype} target="_blank" >
                <i className="bx bx-chat"></i>
                <span>{props.t("TacChat")}</span>
                <span className="badge rounded-pill bg-danger">{ChatAlertsCount}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Case Manager")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                    <Link to="/contractors">{props.t("Contractors")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Staffs")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                    <Link to="/staffs/add-staff">{props.t("Add Staff")}</Link>
                </li>
                <li>
                    <Link to="/staffs/">{props.t("Support Worker")}</Link>
                </li>
                <li>
                    <Link to="/archived-staffs">{props.t("Archived Support Worker")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-report"></i>
                <span>{props.t("Incident Reports")}</span>
                <span className="badge rounded-pill bg-danger">{localStorage.getItem('incidents_count')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                    <Link to="/incident-reports/active">{props.t("Active")}</Link>
                </li>

                <li>
                    <Link to="/incident-reports/completed">{props.t("Completed")}</Link>
                </li>

              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx bx-news"></i>
                <span>{props.t("News Feeds")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                    <Link to="/news-feeds/active">{props.t("Active")}</Link>
                </li>

                <li>
                    <Link to="/news-feeds/archived">{props.t("Archived")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Staff Leaves")}</span>
                <span className="badge rounded-pill bg-danger">{localStorage.getItem('leave_unapproved_count')}</span>
              </Link>
              <ul className="sub-menu">
              <li>
                  <Link to="/staffleaves/add">{props.t("Add Leave")}</Link>
              </li>
              <li>
                  <Link to="/staffleaves/processed">{props.t("Staff Leave Processed")}</Link>
              </li>

              <li>
                  <Link to="/staffleaves/approved">{props.t("Staff Leave Approved")} <span className="badge rounded-pill bg-danger">{localStorage.getItem('leave_approved_count')}</span></Link>
              </li>

              <li>
                  <Link to="/staffleaves/approved-archived">{props.t("Staff Leave Approved Archived")}</Link>
              </li>

              <li>
                  <Link to="/staffleaves/unapproved">{props.t("Staff Leave Unapproved")}<span className="badge rounded-pill bg-danger">{localStorage.getItem('leave_unapproved_count')}</span></Link>
              </li>

              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-report"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                    <Link to="/reports/support-worker-doc-hr-report">{props.t("Support Worker Doc HR Report")}</Link>
                </li>

                <li>
                    <Link to="/reports/support-worker-all-doc-hr-report">{props.t("Support Worker All Doc HR Report")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/hr-documents-name-list" >
                <i className="bx bx-file"></i>
                <span>{props.t("Hr Documents Name")}</span>
              </Link>
            </li>

            <li>
              <Link to="/feedbacks" >
                <i className="bx bx-file"></i>
                <span>{props.t("Feedbacks")} <span className="badge rounded-pill bg-danger">{localStorage.getItem('feedback_count')}</span></span>
              </Link>
            </li>

            <li>
              <Link to="/recruitments/" className="has-arrow">
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("Recruitments")} <span className="badge rounded-pill bg-danger">{localStorage.getItem('recruitment_count')}</span></span>
              </Link>
              <ul className="sub-menu">

              <li>
                  <Link to="/recruitments/interview-questions">{props.t("Interview Questions")}</Link>
              </li>

              <li>
                  <Link to="/recruitments/candidate-lists">{props.t("Candidate Lists")}</Link>
              </li>

              <li>
                  <Link to="/recruitments/employed-lists">{props.t("Employed Lists")}<span className="badge rounded-pill bg-danger">{localStorage.getItem('recruitment_employed_count')}</span></Link>
              </li>

              </ul>
            </li>

            </>
          }

          { loggedUser.role == 'department' && 
          <>
          <li>
              <Link to="/casemanagers" >
              <i className="bx bx-store"></i>
                <span>{props.t("Case Managers")}</span>
              </Link>
            </li>

            <li>
              <Link to="/clients" >
              <i className="bx bx-store"></i>
                <span>{props.t("Clients")}</span>
              </Link>
            </li>

            <li>
              <Link to="/reports/roster-department-report" >
              <i className="bx bx-store"></i>
                <span>{props.t("Reports")}</span>
              </Link>
            </li>

            <li>
              <Link to="/department-profile" >
              <i className="bx bx-store"></i>
                <span>{props.t("My Profile")}</span>
              </Link>
            </li>

          </>
          }
          
          { loggedUser.role == 'case_manager' && 
          <>

            <li>
              <Link to="/clients" >
              <i className="bx bx-store"></i>
                <span>{props.t("Clients")}</span>
              </Link>
            </li>

            <li>
              <Link to="/reports/roster-department-report" >
              <i className="bx bx-store"></i>
                <span>{props.t("Reports")}</span>
              </Link>
            </li>

            <li>
              <Link to="/case-manager-profile/" >
              <i className="bx bx-store"></i>
                <span>{props.t("My Profile")}</span>
              </Link>
            </li>

          </>
          }


        { loggedUser.role == 'subadmin' && 
          <>

            <li>
              <Link to="/send-sms" >
                <i className="bx bxs-detail"></i>
                <span>{props.t("Send Sms")}</span>
              </Link>
            </li>

            <li>
              <Link to="/broadcast-rosters" >
                <i className="bx bx-unite"></i>
                <span>{props.t("Broadcast Rosters")} <span className="badge rounded-pill bg-danger">{BroadcastRosterAlertsCount}</span></span>
                
              </Link>
            </li>


            <li>
                <Link to="/admin-staff-rosters" >
                  <i className="bx bx-unite"></i>
                  <span>{props.t("Care Notes Review")}</span>
                </Link>
              </li>

              <li>
                <Link to="/completed-rosters" >
                  <i className="bx bx-unite"></i>
                  <span>{props.t("Completed Rosters")}<span className="badge rounded-pill bg-danger">{CompletedRosterAlertsCount}</span></span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-store"></i>
                  <span>{props.t("Rosters")}</span>
                </Link>
                <ul className="sub-menu">
                <li>
                    <Link to="/rosters/all-rosters">{props.t("All Rosters")}</Link>
                </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="dripicons dripicons-calendar"></i>
                  <span>{props.t("No Clockin Rosters")} <span className="badge rounded-pill bg-danger">{localStorage.getItem('no_clockin_alerts_count')}</span></span>
                </Link>
                <ul className="sub-menu">
                  <li>
                      <Link to="/no-clockin-rosters-active">{props.t("All Rosters")}<span className="badge rounded-pill bg-danger">{localStorage.getItem('no_clockin_alerts_count')}</span></Link>
                  </li>

                  <li>
                      <Link to="/no-clockin-rosters-archived">{props.t("Archived Rosters")}</Link>
                  </li>

                </ul>
              </li>

              <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-bell"></i>
                <span>{props.t("Emergency Alerts")} <span className="badge rounded-pill bg-danger">{EmergencyAlertsCount}</span></span>
               
              </Link>
              <ul className="sub-menu">
              <li>
                  <Link to="/emergency-active-alerts">{props.t("Active Alerts")} <span className="badge rounded-pill bg-danger">{EmergencyAlertsCount}</span></Link>
                  
              </li>

              <li>
                  <Link to="/emergency-archived-alerts">{props.t("Archived Alerts")}</Link>
              </li>
                
              </ul>
            </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="dripicons dripicons-calendar"></i>
                  <span>{props.t("Checklist Alerts")} <span className="badge rounded-pill bg-danger">{ChecklistAlertCount}</span></span>
                </Link>
                <ul className="sub-menu">
                  <li>
                      <Link to="/checklist-alerts-active">{props.t("Active Checklist Alerts")}<span className="badge rounded-pill bg-danger">{ChecklistAlertCount}</span></Link>
                  </li>

                  <li>
                      <Link to="/checklist-alerts-archived">{props.t("Checklist Alerts (Archived)")}</Link>
                  </li>

                </ul>
              </li>

              <li>
              <Link to="/clients" >
              <i className="bx bx-store"></i>
                <span>{props.t("Clients")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Staffs")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                    <Link to="/staffs/">{props.t("Support Worker")}</Link>
                </li>
              </ul>
            </li>


              

            <li>
              <Link to={ADMIN_URL+'locations.php?uid='+loggedUser.uid+'&role='+loggedUser.role+'&type='+loggedUser.usertype} target="_blank" >
                <i className="bx bx-map"></i>
                <span>{props.t("Map Locations")}</span>
              </Link>
            </li>

            <li>
              <Link to={ADMIN_URL+'taccom.php?uid='+loggedUser.uid+'&role='+loggedUser.role+'&type='+loggedUser.usertype} target="_blank">
                <i className="bx bx-task"></i>
                <span>{props.t("TacCom")}</span>
                <span className="badge rounded-pill bg-danger">{TaccomAlerts}</span>
              </Link>
            </li>

            <li>
              <Link to={ADMIN_URL+'new-chat.php?uid='+loggedUser.uid+'&role='+loggedUser.role+'&type='+loggedUser.usertype} target="_blank">
                <i className="bx bx-chat"></i>
                <span>{props.t("TacChat")}</span>
                <span className="badge rounded-pill bg-danger">{ChatAlertsCount}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Staff Leaves Alerts")}</span>
                <span className="badge rounded-pill bg-danger">{localStorage.getItem('leave_unapproved_count')}</span>
              </Link>
              <ul className="sub-menu">
              <li>
                  <Link to="/staffleaves/add">{props.t("Add Leave")}</Link>
              </li>
              <li>
                  <Link to="/staffleaves/processed">{props.t("Staff Leave Processed")}</Link>
              </li>

              <li>
                  <Link to="/staffleaves/approved">{props.t("Staff Leave Approved")} <span className="badge rounded-pill bg-danger">{localStorage.getItem('leave_approved_count')}</span></Link>
              </li>

              <li>
                  <Link to="/staffleaves/approved-archived">{props.t("Staff Leave Approved Archived")}</Link>
              </li>

              <li>
                  <Link to="/staffleaves/unapproved">{props.t("Staff Leave Unapproved")}<span className="badge rounded-pill bg-danger">{localStorage.getItem('leave_unapproved_count')}</span></Link>
              </li>

              <li>
                  <Link to="/staffleaves/deleted">{props.t("Staff Leave Deleted")}</Link>
              </li>

              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-bell-plus"></i>
                <span>{props.t("Client Away Alerts")}</span>
                <span className="badge rounded-pill bg-danger">{ClientAwayAlertsCount}</span>
              </Link>
              <ul className="sub-menu">
              <li>
                  <Link to="/clientsawayalerts/add">{props.t("Add New")}</Link>
              </li>

              <li>
                  <Link to="/clientsawayalerts/approved">{props.t("Clients Away Alert Approved")}</Link>
              </li>

              <li>
                  <Link to="/clientsawayalerts/unapproved">{props.t("Clients Away Alert Unapproved")}<span className="badge rounded-pill bg-danger">{ClientAwayAlertsCount}</span></Link>
              </li>

              <li>
                  <Link to="/clientsawayalerts/deleted">{props.t("Clients Away Alert Deleted")}</Link>
              </li>

              </ul>
            </li>

            <li>
              <Link to="/feedbacks" >
                <i className="bx bx-file"></i>
                <span>{props.t("Feedbacks")} <span className="badge rounded-pill bg-danger">{localStorage.getItem('feedback_count')}</span></span>
              </Link>
            </li>

          </>
          }

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
