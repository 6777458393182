import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import * as Yup from 'yup';
import { Link } from "react-router-dom"
import axios from 'axios';
import { toast } from "react-toastify";
import * as moment from "moment";
import { useFormik } from "formik";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { ACTION_URL, APP_URL, GET_STAFFS} from "helpers/url_helper"
import Spinners from "components/Common/Spinner";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { Name } from "components/Common/TableColumns";


import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle
  } from "reactstrap";
import StaffRosterviewDetails from "./StaffRosterViewDetails";


  const CalanderScheduleModal = ({userID, scheduleFor, type, userName, show, onCloseModal}) => {

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    const [ModalLoading, setModalLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [Loading, setLoading] = useState(false);

    const[Events, setEvents] = useState([]);
    const[CalanderStartDate, setCalanderStartDate] = useState('');
    const[CalanderEndDate, setCalanderEndDate] = useState('');
    const[viewRosterDetails, setViewRosterDetails] = useState(false);
    const[RosDetails, setRosDetails] = useState([]);
    const[RosNotes, setRosNotes] = useState([]);
    const[rosID, setRosID] = useState('');


    const handleStaffsAllRosters = (payload) => {

        if( CalanderStartDate != payload.startStr ) {
          setCalanderStartDate(payload.startStr);
          setCalanderEndDate(payload.endStr);
    
          const formData = {
            staff_id: userID,
            start_date: payload.startStr,
            end_date: payload.endStr
          }
          setModalLoading(true);
          axios.post(APP_URL+GET_STAFFS,{'data_for':'get_staff_calander_rosters_list','formData': formData,'user': loggedUser})
            .then(res => {
              setModalLoading(false);
              const fetchedData = res.data;
              setEvents(fetchedData);
          })
    
        }
    
    }

    // const toggleViewRosterDetails = () => {
    //     if (viewRosterDetails) {
    //         viewRosterDetails(false);
    //     } else {
    //         viewRosterDetails(true);
    //     }
    // }

    /**
    * Handling click on event on calendar
    */
    const handleEventClick = arg => {
        if( arg.event.id ) {
            if( !isNaN(arg.event.id) ) {
                setRosID(arg.event.id);
                setViewRosterDetails(true);
            }
        }

    }


    return (
        <>
       
        {/* Calander modals start */}

        <StaffRosterviewDetails
            rosID={rosID}
            show={viewRosterDetails}
            onCloseSecondModal={() => setViewRosterDetails(false)}
        />

        <Modal isOpen={show} toggle={onCloseModal} size="xl" backdrop="static" scrollable>
        <ModalHeader toggle={onCloseModal} tag="h4">
            {userName} Rosters List { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
        </ModalHeader>
        <ModalBody>
            
                <Row>
                    <Col xs={12}>

                    <FullCalendar
                        plugins={[
                        BootstrapTheme,
                        dayGridPlugin,
                        listPlugin,
                        interactionPlugin,
                        ]}
                        slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        }}
                        events={Events}
                        editable={false}
                        droppable={false}
                        selectable={true}
                        // dateClick={handleDateClick}
                        navLinks={true}
                        eventClick={handleEventClick}
                        // eventDidMount={handleEventMount}
                        // select={handleDateSelect}
                        datesSet={handleStaffsAllRosters}
                        displayEventEnd={true}
                        eventTimeFormat={{
                        hour: 'numeric',
                        minute: '2-digit',
                        meridiem: 'short',
                        }}
                        
                        
                    />

                    </Col>
                </Row>
            
            <br/>
            <br/>
            <br/>
        </ModalBody>
        </Modal>

        </>
    )

}

CalanderScheduleModal.propTypes = {
    onCloseModal: PropTypes.func,
    show: PropTypes.any
}

export default CalanderScheduleModal;