import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import axios from 'axios';
import { toast } from "react-toastify";
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ACTION_URL, APP_URL, ADMIN_URL } from "helpers/url_helper"
import {Name, Email, Tags, Projects, Status,Types } from "../../../components/Common/TableColumns";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
  getAdminStaffs as onGetAdminStaffs,
} from "store/actions";

function AdminStaffs(){
  //meta title
  document.title="Admin Staffs | Tacveiha Panel";
  
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const dispatch = useDispatch();
  const selectGroupRostersState = (state) => state.StaffsReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        adminStaffs: rosterReducer.adminStaffs,
        loading: rosterReducer.loading
      })
  );
  const {
    adminStaffs, loading
  } = useSelector(groupRostersProperties);

  const [Loading, setLoading] = useState(loading);

  useEffect(() => {
    //if (adminStaffs && !adminStaffs.length) {
      dispatch(onGetAdminStaffs({'data_for': 'get_admin_staffs','user': loggedUser}));
    //}
  }, [dispatch],[adminStaffs]);


  const handleUserClicks = () => {
    window.open('#/staffs/add-staff/','_blank');
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "staff_fname",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Last Name",
        accessor: "staff_lname",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      
      {
        Header: "Email",
        accessor: "user_email",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Mobile No",
        accessor: "staff_mobile_no",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "user_status",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Link
                         to={"/staffs/edit-staff/" +cellProps.row.original.user_id}
                        className="btn btn-sm btn-soft-info"
                        id={`deletetooltip-${cellProps.row.original.id}`}
                    >
                        <i className="mdi mdi-pencil" />
                        <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                            Edit Profile
                        </UncontrolledTooltip>
                    </Link>
                </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }
      
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Staffs" breadcrumbItem="Admin Staffs" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                  {
                      loading ? <Spinners setLoading={setLoading} />
                      :
                    <TableContainer
                      columns={columns}
                      data={adminStaffs}
                      isGlobalFilter={true}
                      isDateSelecteOptions={true}
                      showSearchBtn={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={50}
                      className="custom-header-css"
                      isAddBtn={true}
                      btnText={'Add Staff'}
                    />
                  }
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default AdminStaffs;