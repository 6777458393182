import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import * as Yup from 'yup';
import { Link } from "react-router-dom"
import axios from 'axios';
import { toast } from "react-toastify";
import * as moment from "moment";
import { useFormik } from "formik";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { ACTION_URL, APP_URL, GET_STAFFS} from "helpers/url_helper"
import Spinners from "components/Common/Spinner";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { Name } from "components/Common/TableColumns";


import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle
  } from "reactstrap";


  const StaffRosterviewDetails = ({rosID, show, onCloseSecondModal}) => {

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    const [ModalLoading, setModalLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [Loading, setLoading] = useState(false);

    const[Events, setEvents] = useState([]);
    const[CalanderStartDate, setCalanderStartDate] = useState('');
    const[CalanderEndDate, setCalanderEndDate] = useState('');
    const[viewRosterDetails, setVieRosterDetais] = useState(false);
    const[RosDetails, setRosDetails] = useState([]);
    const[RosNotes, setRosNotes] = useState([]);
    const[FeedbackReply, setFeedbackReply] = useState('');
    const[FeedbackReplyPosted, setFeedbackReplyPosted] = useState(false);



    useEffect(() => {

        if( show ) {

        const formData = {
            ros_id: rosID,
          }
    
          setModalLoading(true);
          // setModalLoading(false);
          axios.post(APP_URL+ACTION_URL,{'data_for':'get_roster_view_details','formData': formData,'user': loggedUser})
            .then(res => {
              setModalLoading(false);
              const fetchedData = res.data;
              setRosDetails(fetchedData.rosterDetails)
              setRosNotes(fetchedData.rosterNotes);
              setFeedbackReplyPosted(false);
          })

        }
    
    },[show])

    const submitFeedbackReply = () => {

        const formData = {
            ros_id: rosID,
            feedback_reply: FeedbackReply
          }
    
          setModalLoading(true);
          // setModalLoading(false);
          axios.post(APP_URL+ACTION_URL,{'data_for':'post_roster_feedaback_reply','formData': formData,'user': loggedUser})
            .then(res => {
              setModalLoading(false);
              const fetchedData = res.data;

              if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                setFeedbackReplyPosted(true);
              }

              if( fetchedData.status == 'error' ) {
                setFeedbackReplyPosted(false);
                toast.error(fetchedData.html, { autoClose: 5000 });
              }


          })

    }

    return (
        <>

        <Modal isOpen={show} toggle={onCloseSecondModal} size="lg" backdrop="static" scrollable>
        <ModalHeader toggle={onCloseSecondModal} tag="h4">
            Rosters Details { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
        </ModalHeader>
        <ModalBody>
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs={12}>
                        <div className="mb-3">
                            <Label className="form-label">Repeat Type</Label>
                            <Input
                                type="text"
                                defaultValue={RosDetails.repeat_type}
                                readOnly={true}
                            />
                            </div>
                            
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                            <Label className="form-label">Suppor Type</Label>
                            <Input
                                type="text"
                                defaultValue={RosDetails.ros_support_type}
                                readOnly={true}
                                disabled={true}
                            />
                            </div>
                            
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                            <Label className="form-label">Client Address</Label>
                            <Input
                                type="text"
                                defaultValue={RosDetails.client_address}
                                readOnly={true}
                                disabled={true}
                            />
                            </div>
                            
                        </Col>

                    </Row>

                    { loggedUser.role == 'admin'  && 
                    <>
                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                            <Label className="form-label">Total KMs</Label>
                            <Input
                                type="text"
                                defaultValue={RosNotes.roster_kms}
                                readOnly={true}
                                disabled={true}
                            />
                            </div>
                            
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                        <div className="mb-3">
                            <Label className="form-label">Description</Label>
                            <Input
                                type="text"
                                defaultValue={RosNotes.roster_kms_desc}
                                readOnly={true}
                                disabled={true}
                            />
                            </div>
                            
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                            <Label className="form-label">Kms For</Label>
                            <Input
                                type="text"
                                defaultValue={RosNotes.roster_kms_for}
                                readOnly={true}
                                disabled={true}
                            />
                            </div>
                            
                        </Col>
                    </Row>

                    </>

                    }

                    { RosDetails.roster_sub_admin_verified_status == 1 && 
                    <>
                        <Row>
                        <Col xs={12}>
                        <div className="mb-3">
                            <Label className="form-label">Care Notes</Label>
                            <Input
                                type="textarea"
                                rows="5"
                                defaultValue={RosNotes.roster_staff_notes_verified}
                                readOnly={true}
                                disabled={true}
                            />
                            </div>
                            
                        </Col>
                    </Row>
                    </>
                    }
                    
                    { (RosDetails.roster_sub_admin_verified_status == 5 && RosDetails.roster_admin_verified == 1) &&  
                    <>
                        <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                            <Label className="form-label">Admin Updated Care Notes</Label>
                            <Input
                                type="textarea"
                                rows="5"
                                defaultValue={RosNotes.admin_notes_verified}
                                readOnly={true}
                                disabled={true}
                            />
                            </div>
                            
                        </Col>
                    </Row>
                    </>
                    }
                    
                    
                    { (RosDetails.roster_sub_admin_verified_status == 5 && RosDetails.roster_admin_verified == 0 ) &&  
                    <>
                        <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                            <Label className="form-label">Care Notes</Label>
                            <Input
                                type="textarea"
                                rows="5"
                                defaultValue='Care notes to be verified'
                                readOnly={true}
                                disabled={true}
                            />
                            </div>
                            
                        </Col>
                    </Row>
                    </>
                    }
                    
                    { loggedUser.role  == 'admin' &&  
                    <>
                    <Row>
                        <Col xs={6}>
                            <div className="mb-3">
                            <Label className="form-label">Actual start time</Label>
                            <Input
                                type="text"
                                defaultValue={RosNotes.start_time}
                                readOnly={true}
                                disabled={true}
                            />
                            </div>
                            
                        </Col>
                        <Col xs={6}>
                            <div className="mb-3">
                            <Label className="form-label">Actual finish time</Label>
                            <Input
                                type="text"
                                defaultValue={RosNotes.end_time}
                                readOnly={true}
                                disabled={true}
                            />
                            </div>
                            
                        </Col>
                    </Row>
                    </>
                    }

                    { !RosDetails.ros_feedback && 
                        <>
                        <Row>
                            <Col xs={12}>
                                <div className="mb-3">
                                <Label className="form-label">Client Feedback : </Label>
                                &nbsp;&nbsp;No feedback provided by client yet.
                                </div>
                                
                            </Col>
                        </Row>
                        </>
                    }
                    { RosDetails.ros_feedback && 
                        <>
                        <Row>
                            <Col xs={12}>
                                <div className="mb-3">
                                <Label className="form-label">Client Feedback</Label>
                                <Input
                                    type="textarea"
                                    rows="5"
                                    defaultValue={RosDetails.ros_feedback}
                                    readOnly={true}
                                    disabled={true}
                                />
                                </div>
                                
                            </Col>
                        </Row>

                        { RosDetails.ros_feedback_reply  ? 
                        
                            <Row>
                                <Col xs={12}>
                                    <div className="mb-3">
                                    <Label className="form-label">Admin Reply</Label>
                                    <Input
                                        type="textarea"
                                        rows="5"
                                        defaultValue={RosDetails.ros_feedback_reply}
                                        readOnly={true}
                                        disabled={true}
                                    />
                                    </div>
                                    
                                </Col>
                            </Row>
                            : 
                            <>
                            <Row>
                                <Col xs={12}>
                                    <div className="mb-3">
                                    <Label className="form-label">Admin Reply</Label>
                                    <Input
                                        type="textarea"
                                        rows="5"
                                        value={FeedbackReply}
                                        onChange={e => {
                                            setFeedbackReply(e.target.value);
                                        }}                       
                                        placeholder="Write your reply"        
                                    />
                                    </div>
                                    
                                </Col>
                            </Row>
                            { !FeedbackReplyPosted && 
                            <Row>
                                <Col>
                                    <div className="text-end">
                                        <Button onClick={submitFeedbackReply}
                                            type="submit"
                                            className="btn btn-success save-user"
                                            disabled={ModalLoading}
                                        >
                                        Add
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                            }

                        </>

                        }

                    </>
                    }
                    
                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                            <Label className="form-label">Admin Notes</Label>
                            <Input
                                type="textarea"
                                rows="5"
                                defaultValue={RosNotes.clockout_notes}      
                                disabled={true}
                            />
                            </div>
                            
                        </Col>
                    </Row>

                </Col>
            </Row>
        </ModalBody>
        </Modal>

        </>
    )

}

StaffRosterviewDetails.propTypes = {
    onCloseSecondModal: PropTypes.func,
    show: PropTypes.any
}

export default StaffRosterviewDetails;