import {
  GET_CASEMANAGERS,
  GET_CASEMANAGERS_FAIL,
  GET_CASEMANAGERS_SUCCESS,
  GET_CASEMANAGER_DETAILS,
  GET_CASEMANAGER_DETAILS_SUCCESS,
  GET_CASEMANAGER_DETAILS_FAIL,
  GET_CASEMANAGERS_CLIENTS,
  GET_CASEMANAGERS_CLIENTS_SUCCESS,
  GET_CASEMANAGERS_CLIENTS_FAIL,
  ADD_CASEMANAGER,
  ADD_CASEMANAGER_SUCCESS,
  ADD_CASEMANAGER_FAIL,
  UPDATE_CASEMANAGER,
  UPDATE_CASEMANAGER_SUCCESS,
  UPDATE_CASEMANAGER_FAIL
} from "./actionTypes"

const INIT_STATE = {
  casemanagers: [],
  casemanagersClients: [],
  error: {},
  loading: false,
  columns_hidden: [],
}

const CasemanagerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CASEMANAGERS:
      console.log('loading');
      return {
        ...state,
        casemanagers: state.casemanagers,
        columns_hidden: state.columns_hidden,
        loading: true
     }
    case GET_CASEMANAGERS_SUCCESS:
      console.log('success');
      return {
        ...state,
        casemanagers: action.payload.data,
        columns_hidden: action.payload.columns_hidden,
        loading: false
     }

    case GET_CASEMANAGERS_FAIL:
    console.log('failed');
    return {
      ...state,
      error: action.payload,

    }

    case GET_CASEMANAGERS_CLIENTS:
    console.log('loading');
    return {
      casemanagersClients: [],
      loading: true
    }

    case GET_CASEMANAGERS_CLIENTS_SUCCESS:
      return {
        ...state,
        casemanagersClients: action.payload.data,
        loading: false
      }

    case GET_CASEMANAGERS_CLIENTS_FAIL:
      return {
        ...state,
        error: action.payload.error,
      }

      // case ADD_CASEMANAGER:
      //   return {
      //       ...state,
      //       loading: true
      // };

      case ADD_CASEMANAGER:
        return {
          ...state,
          casemanagers: [...state.casemanagers, action.payload],
          loading: false
      };

      case UPDATE_CASEMANAGER:
        return {
            ...state,
            casemanagers: state.casemanagers.map(rosters =>
              rosters.user_id.toString() === action.payload.user_id.toString()
                    ? { rosters, ...action.payload }
                    : rosters
            ),
            loading: false
      };

    //   case ADD_CASEMANAGER_FAIL:
    //     return {
    //       ...state,
    //       error: action.payload.error,
    //       loading: false
    //   };

    //   case GET_CASEMANAGER_DETAILS:
      
    //   return {
    //     casemanager: [],
    //     loading: true
    //  }

    // case GET_CASEMANAGER_DETAILS_SUCCESS:
      
    //   return {
    //     ...state,
    //     casemanager: action.payload.data,
    //     loading: false
    //  }

    // case GET_CASEMANAGER_DETAILS_FAIL:
   
    // return {
    //   ...state,
    //   error: action.payload,
    // }

    //   case UPDATE_CASEMANAGER:
    //     return {
    //       ...state,
    //       loading: true
    //   };

      // case UPDATE_CASEMANAGER:
      //   return {
      //       ...state,
      //       casemanager: state.providers.map(rosters =>
      //         rosters.user_id.toString() === action.payload.data.user_id.toString()
      //               ? { rosters, ...action.payload.data }
      //               : rosters
      //       ),
      //       loading: false
      // };

      // case UPDATE_CASEMANAGER_FAIL:
      //   return {
      //       ...state,
      //       error: action.payload.error,
      //       loading: false
      //   };

      default:
        return state
  }
}

export default CasemanagerReducer
