import {
    GET_SUPPORT_WORKERS,
    GET_SUPPORT_WORKERS_SUCCESS,
    GET_SUPPORT_WORKERS_FAIL,
    GET_ARCHIVED_SUPPORT_WORKERS,
    GET_ARCHIVED_SUPPORT_WORKERS_SUCCESS,
    GET_ARCHIVED_SUPPORT_WORKERS_FAIL,

    GET_STAFFS_SERVICE_TYPES,
    GET_STAFFS_SERVICE_TYPES_SUCCESS,
    GET_STAFFS_SERVICE_TYPES_FAIL,
    ADD_STAFF_SERVICE_TYPES,
    ADD_STAFF_SERVICE_TYPES_SUCCESS,
    ADD_STAFF_SERVICE_TYPES_FAIL,
    UPDATE_STAFF_SERVICE_TYPE,
    UPDATE_STAFF_SERVICE_TYPE_SUCCESS,
    UPDATE_STAFF_SERVICE_TYPE_FAIL,

    ADD_STAFF,
    ADD_STAFF_SUCCESS,
    ADD_STAFF_FAIL,
    UPDATE_STAFF,
    UPDATE_STAFF_SUCCESS,
    UPDATE_STAFF_FAIL,

    GET_STAFF_LANGUAGES,
    GET_STAFF_LANGUAGES_SUCCESS,
    GET_STAFF_LANGUAGES_FAIL,
    ADD_STAFF_LANGUAGES,
    ADD_STAFF_LANGUAGES_SUCCESS,
    ADD_STAFF_LANGUAGES_FAIL,
    UPDATE_STAFF_LANGUAGE,
    UPDATE_STAFF_LANGUAGE_SUCCESS,
    UPDATE_STAFF_LANGUAGE_FAIL,

    GET_CSM_STAFFS,
    GET_CSM_STAFFS_SUCCESS,
    GET_CSM_STAFFS_FAIL,
    ADD_CSM_STAFFS,
    ADD_CSM_STAFFS_SUCCESS,
    ADD_CSM_STAFFS_FAIL,
    UPDATE_CSM_STAFF,
    UPDATE_CSM_STAFF_SUCCESS,
    UPDATE_CSM_STAFF_FAIL,

    GET_SUPPORT_STAFFS,
    GET_SUPPORT_STAFFS_SUCCESS,
    GET_SUPPORT_STAFFS_FAIL,
    ADD_SUPPORT_STAFF,
    ADD_SUPPORT_STAFF_SUCCESS,
    ADD_SUPPORT_STAFF_FAIL,
    UPDATE_SUPPORT_STAFF,
    UPDATE_SUPPORT_STAFF_SUCCESS,
    UPDATE_SUPPORT_STAFF_FAIL,


    GET_ADMIN_STAFFS,
    GET_ADMIN_STAFFS_SUCCESS,
    GET_ADMIN_STAFFS_FAIL,
    ADD_ADMIN_STAFF,
    ADD_ADMIN_STAFF_SUCCESS,
    ADD_ADMIN_STAFF_FAIL,
    UPDATE_ADMIN_STAFF,
    UPDATE_ADMIN_STAFF_SUCCESS,
    UPDATE_ADMIN_STAFF_FAIL,


    GET_HR_MANAGERS,
    GET_HR_MANAGERS_SUCCESS,
    GET_HR_MANAGERS_FAIL,
    ADD_HR_MANAGER,
    ADD_HR_MANAGER_SUCCESS,
    ADD_HR_MANAGER_FAIL,
    UPDATE_HR_MANAGER,
    UPDATE_HR_MANAGER_SUCCESS,
    UPDATE_HR_MANAGER_FAIL,

    GET_LOAD_STAFFS,
    GET_LOAD_STAFFS_SUCCESS,
    GET_LOAD_STAFFS_FAIL
  
    } from "./actionTypes"

    const INIT_STATE = {
        staffs: [],
        archivedStaffs: [],
        staffsServiceTypes: [],
        staffsLanguages: [],
        csmStaffs: [],
        supportStaffs: [],
        adminStaffs: [],
        hrManagers: [],
        error: {},
        loading: false,
        staffsProviders: [],
        allStaffsDropdown: [],
        loadStaffsDropdown: [],
        clientsDropdown: [],
        payCategory:[],
        supportTypes:[],
        groupRosterDropdown: [],
        broadcastStaffs: [],

      }

const StaffsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

    case GET_SUPPORT_WORKERS:
        return {
          ...state,
          staffs: state.staffs,
          allStaffsDropdown: state.allStaffsDropdown,
          loadStaffsDropdown: state.loadStaffsDropdown,
          clientsDropdown: state.clientsDropdown,
          payCategory: state.payCategory,
          supportTypes: state.supportTypes,
          groupRosterDropdown: state.groupRosterDropdown,
          broadcastStaffs: state.broadcastStaffs,
          loading: true,
          hiddenColumns: state.hiddenColumns,
        }

    case GET_SUPPORT_WORKERS_SUCCESS:
        return {
          ...state,
          staffs: action.payload.data,
          allStaffsDropdown: action.payload.allStaffsDropdown,
          loadStaffsDropdown: action.payload.loadStaffsDropdown,
          clientsDropdown: action.payload.clientsDropdown,
          payCategory: action.payload.payCategoryDropdown,
          groupRosterDropdown: action.payload.groupRosterDropdown,
          supportTypes: action.payload.supportTypeDropdown,
          broadcastStaffs: action.payload.broadcastStaffs,
          hiddenColumns: action.payload.hiddenColumns,
          loading: false
        }

    case GET_SUPPORT_WORKERS_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }
    
    case ADD_STAFF:
        return {
          ...state,
          staffs: [...state.staffs],
          loading: true
        }
        
    case ADD_STAFF_SUCCESS:
        return {
          ...state,
          staffs: [...state.staffs, action.payload.data],
          loading: false
        }
  
    case ADD_STAFF_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }

    case UPDATE_STAFF:
        return {
          ...state,
          loading: true
        }
        
    case UPDATE_STAFF_SUCCESS:
        return {
            ...state,
            staffs: state.staffs.map(rosters =>
            rosters.id.toString() === action.payload.data.id.toString()
                    ? { rosters, ...action.payload.data }
                    : rosters
            ),
            loading: false
        }

    case UPDATE_STAFF_FAIL:
        return {
            ...state,
            error: action.payload,
            loading: false
        }


    case GET_ARCHIVED_SUPPORT_WORKERS:
        return {
            ...state,
            archivedStaffs: [],
            loading: true
        }

    case GET_ARCHIVED_SUPPORT_WORKERS_SUCCESS:
        return {
            ...state,
            archivedStaffs: action.payload.data,
            loading: false
        }

    case GET_ARCHIVED_SUPPORT_WORKERS_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        }

    //staffs service types
    case GET_STAFFS_SERVICE_TYPES:
        return {
            ...state,
            staffsServiceTypes: [],
            loading: true
        }

    case GET_STAFFS_SERVICE_TYPES_SUCCESS:
        return {
            ...state,
            staffsServiceTypes: action.payload.data,
            loading: false
        }

    case GET_STAFFS_SERVICE_TYPES_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        }

    case ADD_STAFF_SERVICE_TYPES:
        return {
          ...state,
          staffsServiceTypes: [...state.staffsServiceTypes],
          loading: true
        }
        
    case ADD_STAFF_SERVICE_TYPES_SUCCESS:
        return {
          ...state,
          staffsServiceTypes: [...state.staffsServiceTypes, action.payload.data],
          loading: false
        }
  
    case ADD_STAFF_SERVICE_TYPES_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }

    case UPDATE_STAFF_SERVICE_TYPE:
        return {
          ...state,
          loading: true
        }
        
    case UPDATE_STAFF_SERVICE_TYPE_SUCCESS:
        return {
            ...state,
            staffsServiceTypes: state.staffsServiceTypes.map(rosters =>
            rosters.id.toString() === action.payload.data.id.toString()
                    ? { rosters, ...action.payload.data }
                    : rosters
            ),
            loading: false
        }

    case UPDATE_STAFF_SERVICE_TYPE_FAIL:
        return {
            ...state,
            error: action.payload,
            loading: false
        }

    //staffs languages
    case GET_STAFF_LANGUAGES:
        return {
            ...state,
            staffsLanguages: [],
            loading: true
        }

    case GET_STAFF_LANGUAGES_SUCCESS:
        return {
            ...state,
            staffsLanguages: action.payload.data,
            loading: false
        }

    case GET_STAFF_LANGUAGES_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        }

    case ADD_STAFF_LANGUAGES:
        return {
          ...state,
          staffsLanguages: [...state.staffsLanguages],
          loading: true
        }
        
    case ADD_STAFF_LANGUAGES_SUCCESS:
        return {
          ...state,
          staffsLanguages: [...state.staffsLanguages, action.payload.data],
          loading: false
        }
  
    case ADD_STAFF_LANGUAGES_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }

    case UPDATE_STAFF_LANGUAGE:
        return {
          ...state,
          loading: true
        }
        
    case UPDATE_STAFF_LANGUAGE_SUCCESS:
        return {
            ...state,
            staffsLanguages: state.staffsLanguages.map(rosters =>
            rosters.id.toString() === action.payload.data.id.toString()
                    ? { rosters, ...action.payload.data }
                    : rosters
            ),
            loading: false
        }

    case UPDATE_STAFF_LANGUAGE_FAIL:
        return {
            ...state,
            error: action.payload,
            loading: false
        }

    //csm staffs
    case GET_CSM_STAFFS:
        return {
            ...state,
            csmStaffs: state.csmStaffs,
            staffsProviders:[],
            loading: true
        }

    case GET_CSM_STAFFS_SUCCESS:
        return {
            ...state,
            csmStaffs: action.payload.data,
            staffsProviders: action.payload.staffsProviders,
            loading: false
        }

    case GET_CSM_STAFFS_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        }

    case ADD_CSM_STAFFS:
        return {
          ...state,
          csmStaffs: [],
          staffsProviders: [...state.staffsProviders],
          loading: true
        }
        
    case ADD_CSM_STAFFS_SUCCESS:
        return {
          ...state,
          csmStaffs: [...state.csmStaffs, action.payload.data],
          loading: false
        }
  
    case ADD_CSM_STAFFS_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }

    case UPDATE_CSM_STAFF:
        return {
          ...state,
          loading: true
        }
        
    case UPDATE_CSM_STAFF_SUCCESS:
        return {
            ...state,
            csmStaffs: state.csmStaffs.map(rosters =>
            rosters.user_id.toString() === action.payload.data.user_id.toString()
                    ? { rosters, ...action.payload.data }
                    : rosters
            ),
            loading: false
        }

    case UPDATE_CSM_STAFF_FAIL:
        return {
            ...state,
            error: action.payload,
            loading: false
        }

    //support staffs
    case GET_SUPPORT_STAFFS:
        return {
            ...state,
            supportStaffs: state.supportStaffs,
            staffsProviders:[],
            loading: true
        }

    case GET_SUPPORT_STAFFS_SUCCESS:
        return {
            ...state,
            supportStaffs: action.payload.data,
            // staffsProviders: action.payload.staffsProviders,
            loading: false
        }

    case GET_SUPPORT_STAFFS_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        }

    case ADD_SUPPORT_STAFF:
        return {
        ...state,
        supportStaffs: [...state.supportStaffs],
        loading: true
        }
        
    case ADD_SUPPORT_STAFF_SUCCESS:
        return {
        ...state,
        supportStaffs: [...state.supportStaffs, action.payload.data],
        loading: false
        }

    case ADD_SUPPORT_STAFF_FAIL:
        return {
        ...state,
        error: action.payload.error,
        loading: false
        }

    case UPDATE_SUPPORT_STAFF:
        return {
        ...state,
        loading: true
        }
        
    case UPDATE_SUPPORT_STAFF_SUCCESS:
        return {
            ...state,
            supportStaffs: state.supportStaffs.map(rosters =>
            rosters.user_id.toString() === action.payload.data.user_id.toString()
                    ? { rosters, ...action.payload.data }
                    : rosters
            ),
            loading: false
        }

    case UPDATE_SUPPORT_STAFF_FAIL:
        return {
            ...state,
            error: action.payload,
            loading: false
        }
    
    //Admin Staffs
    case GET_ADMIN_STAFFS:
        return {
            ...state,
            adminStaffs: state.adminStaffs,
            loading: true
        }

    case GET_ADMIN_STAFFS_SUCCESS:
        return {
            ...state,
            adminStaffs: action.payload.data,
            // staffsProviders: action.payload.staffsProviders,
            loading: false
        }

    case GET_ADMIN_STAFFS_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        }

    case ADD_ADMIN_STAFF:
        return {
        ...state,
        adminStaffs: [],
        // staffsProviders: [...state.staffsProviders],
        loading: true
        }
        
    case ADD_ADMIN_STAFF_SUCCESS:
        return {
        ...state,
        adminStaffs: [...state.adminStaffs, action.payload.data],
        loading: false
        }

    case ADD_ADMIN_STAFF_FAIL:
        return {
        ...state,
        error: action.payload.error,
        loading: false
        }

    case UPDATE_ADMIN_STAFF:
        return {
        ...state,
        loading: true
        }
        
    case UPDATE_ADMIN_STAFF_SUCCESS:
        return {
            ...state,
            adminStaffs: state.adminStaffs.map(rosters =>
            rosters.user_id.toString() === action.payload.data.user_id.toString()
                    ? { rosters, ...action.payload.data }
                    : rosters
            ),
            loading: false
        }

    case UPDATE_ADMIN_STAFF_FAIL:
        return {
            ...state,
            error: action.payload,
            loading: false
        }

    //Hr Managers
    case GET_HR_MANAGERS:
        return {
            ...state,
            hrManagers: [],
            // staffsProviders:[],
            loading: true
        }

    case GET_HR_MANAGERS_SUCCESS:
        return {
            ...state,
            hrManagers: action.payload.data,
            // staffsProviders: action.payload.staffsProviders,
            loading: false
        }

    case GET_HR_MANAGERS_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        }

    case ADD_HR_MANAGER:
        return {
        ...state,
        hrManagers: [],
        // staffsProviders: [...state.staffsProviders],
        loading: true
        }
        
    case ADD_HR_MANAGER_SUCCESS:
        return {
        ...state,
        hrManagers: [...state.hrManagers, action.payload.data],
        loading: false
        }

    case ADD_HR_MANAGER_FAIL:
        return {
        ...state,
        error: action.payload.error,
        loading: false
        }

    case UPDATE_HR_MANAGER:
        return {
        ...state,
        loading: true
        }
        
    case UPDATE_HR_MANAGER_SUCCESS:
        return {
            ...state,
            hrManagers: state.hrManagers.map(rosters =>
            rosters.user_id.toString() === action.payload.data.user_id.toString()
                    ? { rosters, ...action.payload.data }
                    : rosters
            ),
            loading: false
        }

    case UPDATE_HR_MANAGER_FAIL:
        return {
            ...state,
            error: action.payload,
            loading: false
        }

    case GET_LOAD_STAFFS:
        return {
            ...state,
            loadStaffsDropdown: state.loadStaffsDropdown,
            loading: true
        }

    case GET_LOAD_STAFFS_SUCCESS:
        return {
            ...state,
            allStaffsDropdown: action.payload.allStaffsDropdown,
            loading: false
        }

    case GET_LOAD_STAFFS_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        }

    default:
        return state

    }
}

export default StaffsReducer